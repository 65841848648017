import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useAuth } from "../../utils/Auth";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../pages/CartContext";

export default function Logout({ open, handleClose }) {
  const navigate = useNavigate();
  const { setIsLogin, logout } = useAuth();
  const { cartTotal, setCartTotal, setCartCountRefresh } = useCart();

  const handleLogout = () => {
    localStorage.clear();
    setCartCountRefresh(prev => prev + 1)
    navigate("/home");
    window.location.reload()
    setIsLogin(false);
    logout();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
      className=""
      PaperProps={{
        sx: {
          width: "80%",
          maxWidth: "400px",
          padding: "16px",
          borderRadius: "8px",
          overflow: "hidden",
          backgroundImage: "unset",
          "@media (max-width: 600px)": {
            width: "95%",
          },
        },
      }}
      sx={{
        "& .MuiDialog-paperScrollPaper::before": {
          backgroundImage: "unset",
        },
      }}
    >
      <DialogTitle
        id="logout-dialog-title"
        sx={{
          fontSize: "25.12px",
          fontWeight: 500,
          lineHeight: "27.79px",
          textAlign: "left",
          fontFamily: "Objectivity",
          color: "#2C2C2C",
        }}
      >
        {"Log out?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="logout-dialog-description"
          sx={{ fontSize: "18px", color: "#2C2C2C", lineHeight: "20.25px" }}
        >
          Are you sure you want to log out?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            backgroundColor: "#E8E8E8",
            color: "#525252",
            "&:hover": {
              backgroundColor: "#E8E8E8",
              borderColor: "#E8E8E8",
            },
            marginRight: "8px",
            borderColor: "#E8E8E8",
            borderRadius: "5px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleLogout}
          variant="contained"
          sx={{
            backgroundColor: "#ff5b00",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#ff5b00",
              borderColor: "#ff5b00",
            },
            borderColor: "#ff5b00",
            borderRadius: "5px",
          }}
        >
          Log out
        </Button>
      </DialogActions>
    </Dialog>
  );
}

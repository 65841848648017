import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Logout from "./Logout";

function AccordionProfileMob({ toggleProfileVisibility, onLogoutClick }) {
  const profileSections = [
    { name: "My Profile", path: "/user/profile" },
    { name: "My Family", path: "/user/myfamily" },
    { name: "Karta Details", path: "/user/kartadeatils" },
    { name: "Ancestor Details", path: "/user/ancestordeatils" },
    { name: "Address", path: "/user/address" },
    { name: "Booked Seva", path: "/user/bookedseva" },
  ];

  const [openLogoutModal, setIsOpenLogoutModal] = useState(false);

  const handleLogoutClose = () => {
    setIsOpenLogoutModal(false);
  };

  return (
    <>
      <div className="accordion_profile_mob">
        {profileSections.map((profilePath) => (
          <Link
            to={profilePath?.path}
            key={profilePath?.name}
            onClick={toggleProfileVisibility}
          >
            <Box
              className="accordion_box"
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "16px",
                backgroundColor: "#FFF2EC",
                margin: "8px 0",
                borderRadius: "4px",
              }}
            >
              <Typography
                sx={{
                  flexGrow: 1,
                  color: "#0F0F0F",
                  lineHeight: "22px",
                  letterSpacing: "1px",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                {profilePath?.name}
              </Typography>
              <ArrowForwardIosIcon
                sx={{ color: "#676767", marginRight: "8px" }}
              />
            </Box>
          </Link>
        ))}
        <Box
          className="accordion_box"
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "16px",
            backgroundColor: "#FFF2EC",
            margin: "8px 0",
            borderRadius: "4px",
          }}
          onClick={onLogoutClick}
        >
          <Typography
            sx={{
              flexGrow: 1,
              color: "#0F0F0F",
              lineHeight: "22px",
              letterSpacing: "1px",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Logout
          </Typography>
          <ArrowForwardIosIcon sx={{ color: "#676767", marginRight: "8px" }} />
        </Box>
      </div>
      <Logout open={openLogoutModal} handleClose={handleLogoutClose} />
    </>
  );
}

export default AccordionProfileMob;

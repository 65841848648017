import React from "react";
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Button,
  useTheme,
  useMediaQuery,
  Container,
} from "@mui/material";
import Header from "./Header";
import Footer from "../../pages/Footer";
import { BorderBottom, BorderLeft } from "@mui/icons-material";

const Invoice = ({ invoiceData, profileData, setProfileData, bookedData }) => {
  const ns = "app-page-home";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const CustomTableCell = styled(TableCell)(({ theme }) => ({
    "&.MuiTableCell-head": {
      backgroundColor: "#FF5F19",
      color: "#FFFFFF",
      fontFamily: "Objectivity",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "16px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px"
      }
    },
    "&:not(:first-of-type).MuiTableCell-head": {
      borderLeft: "0.5px solid #d3d3d3",
    },
    "&.MuiTableCell-body": {
      color: "#2C2C2C",
      fontFamily: "Objectivity",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "16px",
      textAlign: "center",
      backgroundColor: "#FEE6E1",
      borderTop: "0.5px solid #ffab86",
      BorderBottom: "unset",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px"
      },  
    },
    "&:not(:first-of-type).MuiTableCell-body": {
      borderLeft: "0.5px solid #d3d3d3",
    },
    "&.MuiTableCell-root": {
      borderBottom: "none !important",
    },
  }));

  const CustomTableContainer = styled(TableContainer)`
    margin-top: 16px;
  `;

  const CustomTableRow = styled(TableRow)`
    &:not(:first-of-type) ${CustomTableCell} {
      border-top: 0.5px solid #ffab86;
    }
  `;

  const CustomTypography = styled(Typography)`
    font-family: Objectivity;
    font-size: 22px;
    font-weight: 500;
    line-height: 30.99px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #383838;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        font-size: 15px; 
        line-height: 25px; 
    }
  `;

  const CustomFromTypography = styled(CustomTypography)`
    font-size: 20px;
    font-weight: 400;
    color: #505050;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        font-size: 14px; 
        line-height: 25px; 
    }
  `;

  const CustomAddr1Typography = styled(CustomTypography)`
    font-size: 17px;
    font-weight: 400;
    color: #505050;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        font-size: 12px; 
        line-height: 25px; 
    }
  `;

  const CustomAddr2Typography = styled(CustomAddr1Typography)`
    font-size: 14px;
  `;

  const FlexContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `;

  const CustomButton = styled(Button)`
        padding: 14px 36px;
        gap: 11.05px;
        border-radius: 11.97px;
        opacity: 1;
        background-color: #FF5B00;
        font-family: Objectivity;
        font-size: 15px;
        font-weight: 700;
        line-height: 17.83px;
        letter-spacing: 0.04em;

        ${({theme}) => theme.breakpoints.down("sm")}{
            padding: 10px 24px;
            font-size: 12px;
            font-weight: 500;
        }
    `;


  return (
    <div className={`${ns}`}>
      <div className={`${ns}__header`}>
        <Header />
      </div>
      <Container>
        <Box
          sx={{
            
            margin: {
              xs: "0 20px",
              sm: "0 auto",
            },
            paddingTop: {
              xs: "110px",
              sm: "150px",
            },
            paddingBottom: "50px",
          }}
          className="invoice-box"
        >
          <Box
            sx={{
              background: "white",
              padding: {
                xs: "20px",
                sm: "24px",
              },
            }}
            className="invoice-box-child"
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={4}
                sm={8}
                sx={{
                  paddingLeft: {
                    xs: "unset!important",
                    sm: "16px!important",
                  },
                }}
                className="cust-grid-img"
              >
                <div className="cust-invoice-image">
                  <img
                    src="/images/devasmriti-logo.svg"
                    alt="Logo"
                    style={{ marginRight: 8 }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={8}
                sm={4}
                sx={{
                  textAlign: "right",
                  paddingLeft: {
                    xs: "unset!important",
                    sm: "16px!important",
                  },
                }}
                className="cust-grid-invoice"
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: "Objectivity",
                    fontSize: {
                      xs: "19px",
                      sm: "26px",
                    },
                    fontWeight: "700",
                    lineHeight: "32.96px",
                    letterSpacing: "0.03em",
                    textAlign: "left",
                    margin: {
                      xs: "10px 0 40px",
                      sm: "20px 0 40px",
                    },
                    textAlign: "right",
                  }}
                >
                  Devasmriti Invoice
                </Typography>
                {bookedData &&
                  bookedData.length > 0 &&
                  bookedData.map((item, index) => (
                    <>
                      <FlexContainer>
                        <CustomAddr1Typography variant="body2">
                          Invoice no:
                        </CustomAddr1Typography>
                        <CustomAddr2Typography variant="body2">
                          {" "}
                          {item?.order_sevas[0]?.order_id}
                        </CustomAddr2Typography>
                      </FlexContainer>
                      <FlexContainer>
                        <CustomAddr1Typography variant="body2">
                          Invoice date:
                        </CustomAddr1Typography>
                        <CustomAddr2Typography variant="body2">
                          {item?.order_sevas[0]?.created_at}
                        </CustomAddr2Typography>
                      </FlexContainer>
                    </>
                  ))}
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ marginTop: "40px", marginBottom: "40px" }}
            >
              <Grid
                item
                xs={6}
                sm={6}
                sx={{ textAlign: "left" }}
                className="cust-grid-address1"
              >
                <CustomFromTypography variant="body1">From</CustomFromTypography>
                <CustomTypography variant="body2">Devasmriti</CustomTypography>
                <CustomAddr1Typography variant="body2">
                  5555555555
                </CustomAddr1Typography>
                <CustomAddr1Typography variant="body2">
                  Loree dummy text
                </CustomAddr1Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                sx={{ textAlign: "right" }}
                className="cust-grid-invoice2"
              >
                <Grid container direction="column">
                  <Grid item>
                    <CustomFromTypography
                      sx={{ textAlign: "right" }}
                      variant="body1"
                    >
                      Bill to
                    </CustomFromTypography>
                    <CustomTypography sx={{ textAlign: "right" }} variant="body2">
                      {`${profileData?.fname} ${
                        profileData?.lname ? profileData?.lname : ""
                      }`}
                    </CustomTypography>
                    <CustomAddr1Typography
                      sx={{ textAlign: "right" }}
                      variant="body2"
                    >
                      5555555555
                    </CustomAddr1Typography>
                    <CustomAddr1Typography
                      sx={{ textAlign: "right" }}
                      variant="body2"
                    >
                      Loree dummy text
                    </CustomAddr1Typography>
                  </Grid>
                  <Grid item sx={{ marginTop: 2 }}>
                    <CustomFromTypography
                      sx={{ textAlign: "right" }}
                      variant="body1"
                    >
                      Ship to
                    </CustomFromTypography>
                    <CustomAddr1Typography
                      sx={{ textAlign: "right" }}
                      variant="body2"
                    >
                      Loree dummy text
                    </CustomAddr1Typography>
                    <CustomAddr1Typography
                      sx={{ textAlign: "right" }}
                      variant="body2"
                    >
                      Track #: R0587456
                    </CustomAddr1Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <CustomTableContainer
              sx={{ marginTop: "30px" }}
              className="cust-grid-invoice-table"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>Sevas</CustomTableCell>
                    <CustomTableCell>Qty</CustomTableCell>
                    <CustomTableCell>Rate</CustomTableCell>
                    <CustomTableCell>Tax</CustomTableCell>
                    <CustomTableCell>Discount</CustomTableCell>
                    <CustomTableCell>Subtotal</CustomTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {bookedData && bookedData.length > 0 ? (
                    bookedData.map((item, index) => (
                      <TableRow key={index}>
                        <CustomTableCell>
                          {item?.order_sevas[0]?.seva_price?.seva?.title ||
                            ` ${
                              JSON.parse(
                                item?.order_sevas[0]?.seva_price_information
                              )?.title
                            }`}
                        </CustomTableCell>
                        <CustomTableCell>
                          {item?.order_sevas[0]?.qty}
                        </CustomTableCell>
                        <CustomTableCell>{item.rate}</CustomTableCell>
                        <CustomTableCell>{item.tax}</CustomTableCell>
                        <CustomTableCell>{item.discount}</CustomTableCell>
                        <CustomTableCell>{item.subtotal}</CustomTableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <CustomTableCell colspan={6} align="center">
                        No Data Available
                      </CustomTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </CustomTableContainer>

            <Grid
              container
              spacing={2}
              sx={{ marginTop: 2 }}
              className="cust-grid-payment-invoice"
            >
              <Grid item xs={6} sm={8}>
                <CustomTypography variant="body2">Payment Mode</CustomTypography>
                <CustomAddr1Typography variant="body2">UPI</CustomAddr1Typography>
              </Grid>
              <Grid item xs={6} sm={4} sx={{ textAlign: "right" }}>
                <FlexContainer>
                  <CustomAddr1Typography
                    sx={{ textAlign: "right" }}
                    variant="body2"
                  >
                    Subtotal:
                  </CustomAddr1Typography>
                  <CustomAddr1Typography
                    sx={{ textAlign: "right" }}
                    variant="body2"
                  >
                    1,730/-
                  </CustomAddr1Typography>
                </FlexContainer>
                <FlexContainer>
                  <CustomAddr1Typography
                    sx={{ textAlign: "right" }}
                    variant="body2"
                  >
                    Discount (10%):
                  </CustomAddr1Typography>
                  <CustomAddr1Typography
                    sx={{ textAlign: "right" }}
                    variant="body2"
                  >
                    50/-
                  </CustomAddr1Typography>
                </FlexContainer>
                <FlexContainer>
                  <CustomAddr1Typography
                    sx={{ textAlign: "right" }}
                    variant="body2"
                  >
                    Shipping:
                  </CustomAddr1Typography>
                  <CustomAddr1Typography
                    sx={{ textAlign: "right" }}
                    variant="body2"
                  >
                    80/-
                  </CustomAddr1Typography>
                </FlexContainer>
                <FlexContainer>
                  <CustomTypography
                    sx={{ textAlign: "right", fontWeight: "700" }}
                    variant="body2"
                  >
                    Total:
                  </CustomTypography>
                  <CustomTypography
                    sx={{ textAlign: "right", fontWeight: "700" }}
                    variant="body2"
                  >
                    1,810/-
                  </CustomTypography>
                </FlexContainer>
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                    },
                    display: {
                      xs: "none",
                      sm: "block"
                    },
                    height: "0.5px",
                    backgroundColor: "#FF5B00",
                    margin: "20px 0",
                    position: "relative",
                    right: "-23px",
                  }}
                />
              </Grid>
              <Box
                  sx={{
                    width: {
                      xs: "50%",
                      sm: "70%",
                    },
                    height: "0.5px",
                    backgroundColor: "#FF5B00",
                    position: "relative",
                    right: {
                      xs: "unset",
                      sm: "-23px",
                    },
                    left: {
                      xs: "0px",
                      sm: "unset",
                    },
                    marginTop: "20px",
                    marginLeft: {
                      xs: "unset",
                      sm: "auto",
                    },
                    display: {
                      xs: "none",
                      sm: "block",
                    }
                  }}
                />
                <Box
                  sx={{
                    width: {
                      xs: "50%",
                      sm: "70%",
                    },
                    height: "0.5px",
                    backgroundColor: "#FF5B00",
                    position: "relative",
                    left: "0px",
                    marginTop: "20px",
                    display: {
                      xs: "block",
                      sm: "none",
                    }
                  }}
                />
              <Box
                  sx={{
                    width: {
                      xs: "70%",
                      sm: "70%",
                    },
                    height: "0.5px",
                    backgroundColor: "#FF5B00",
                    position: "relative",
                    right: {
                      xs: "unset",
                      sm: "-23px",
                    },
                    left: {
                      xs: "0px",
                      sm: "unset",
                    },
                    marginTop: "20px",
                    marginLeft: {
                      xs: "unset",
                      sm: "auto",
                    },
                    display: {
                      xs: "block",
                      sm: "none",
                    }
                  }}
                />
            </Grid>
          </Box>

          <Box sx={{ textAlign: "center", marginTop: 4 }}>
            <CustomButton variant="contained">Save</CustomButton>
          </Box>
        </Box>
      </Container>

      <Footer />
    </div>
  );
};

export default Invoice;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './utils/Auth';
import ScrollToTop from './ScrollToTop';
import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-KFN6JL5FDH';
ReactGA.initialize(TRACKING_ID);

ReactGA.send({
   hitType: "pageview", 
   page: window.location.pathname, 
   });



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <BrowserRouter>
      <App />
      <ScrollToTop />
    </BrowserRouter>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import OtpInput from "react-otp-input";
import resend from "../../src/images/resend.svg";
import { noauthinstance } from "../utils/Api";
import { useState } from "react";
import { auth, firebase } from "../config/firebase";
import { useEffect } from "react";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import IndiaFlag from "../images/banner/india_1.svg";
import downArrow from "../images/banner/down-ar.svg";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countryData from "../utils/countrycode.json";
import axios from "axios";
import { useAuth } from "../utils/Auth";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const PhoneInput = ({ phoneNumber, setPhoneNumber }) => {
  const [phonecode, setPhonecode] = useState("+91");
  const [selectedCountry, setSelectedCountry] = useState({
    name: "India",
    code: "IN",
    emoji: "🇮🇳",
    unicode: "U+1F1EE U+1F1F3",
    image: "IN.svg",
    dial_code: "+91",
  });

  const handlePhonecodeChange = (e) => {
    const selectedDialCode = e.target.value;
    setPhonecode(selectedDialCode);
    const country = countryData.find(
      (country) => country.dial_code === selectedDialCode
    );
    setSelectedCountry(country);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  return (
    <div className="phone-inputs">
      <div className="input-group">
        <div style={{ fontSize: "20px" }}>{selectedCountry.emoji}</div>
        <img
          src={downArrow}
          alt="Down arrow"
          className="flag-icon"
          id="arrowIcon"
          style={{ margin: "0px" }}
        />
        <select
          className="phone-code-select"
          value={phonecode}
          onChange={handlePhonecodeChange}
          id="phoneCodeSelect"
        >
          {countryData.map((country) => (
            <option key={country.code} value={country.dial_code}>
              {country.dial_code}
            </option>
          ))}
        </select>
        <div className="vertical-line"></div>
        <input
          className="input-number"
          placeholder="Enter Number"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          onBlur={() => window.fbq("track", "InputData")}
        />
      </div>
    </div>
  );
};

export default function CustomizedDialogsEvent({ setTriggerRefresh, open, setOpen, handleClose }) {
  const navigate = useNavigate();
  const [logindata, setLogindata] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const { isLogin, setIsLogin, login, logout } = useAuth();
  // const [otp, setOtp] = useState('');
  const [otpOpen, setOtpOpen] = useState(false);
  const [otpData, setOtpData] = useState({ otp: "", user_id: null });
  const [phonecode, setPhonecode] = useState("+91");

  const [formattedPhone, setFormattedPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState("");
  const [justPlaying, setJustPlaying] = useState(false);
  const [playingAgain, setPlayingAgain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyloading, setVerifyLoading] = useState(false);
  const [rawPhoneNumber, setRawPhoneNumber] = useState("");
  const [loginEmail, setLoginEmail] = useState("");

  const isMobile = useMediaQuery("(max-width: 414px)");

  function setuprecaptcha() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: function (response) {
            // Your callback logic
            this.onSignInSubmit();
          },
        }
      );
    }
  }

  useEffect(() => {
    setuprecaptcha();
  }, []);

  // useEffect(() => {
  //   setFormattedPhone(`${phonecode}${phoneNumber}`)
  // }, [phoneNumber, phonecode])

  useEffect(() => {
    setFormattedPhone(`${phonecode}${phoneNumber}`);
    setRawPhoneNumber(phoneNumber);
  }, [phoneNumber, phonecode]);

  // Sent OTP
  const signin = () => {
    console.log(formattedPhone, "ljewkjwegdlkjwd");
    if (formattedPhone === "" || formattedPhone.length < 13) {
      alert("Phone number too short");
      return;
    }
    // if (rawPhoneNumber === "" || rawPhoneNumber.length < 10) {
    //   alert("Please enter a valid phone number");
    //   return;
    // }

    setJustPlaying(true);
    setOtp("");
    setLoading("sending-otp");

    var verify = window.recaptchaVerifier;

    auth
      .signInWithPhoneNumber(formattedPhone, verify)
      .then((result) => {
        setLoading(false);
        setJustPlaying(false);
        setfinal(result);
        setshow(true);
        setResendCountdown(60);
      })
      .catch((err) => {
        alert(err);
        window.location.reload();
      });
  };

  const [userId, setUserId] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const signin1 = () => {
    axios
      .post(`https://api-backend.devasmriti.com/api/user/login`, {
        mobile_number: phoneNumber,
        email: loginEmail,
      })
      // .post(
      //   `https://805e-13-202-121-36.ngrok-free.app/api/user/login_with_otp`,
      //   {
      //     mobile_number: "8655235422",
      //     user_id: 152,
      //     otp: 5954,
      //     orderId: "Otp_27F3C5193BEE41D5883D679192505E5F",
      //   }
      // )
      .then(({ data }) => {
        if (data?.success) {
          setshow(true);
          setUserId(data?.data?.user_id);
          const tempOrderId = JSON.parse(data?.otpResponse?.data)?.orderId;
          console.log(tempOrderId, "kjwbkjdgbwkjegkwe");
          setOrderId(tempOrderId);
        }
      });
  };

  const sendAuthToBackend = async (result) => {
    // const authPhoneNum = rawPhoneNumber.slice(2);
    // console.log('authPhoneNum:',authPhoneNum)
    // const response = await noauthinstance.post("api/user/login", {
    //   mobile_number: rawPhoneNumber,
    // });

    // let userid;

    // if (response.data.success === 1) {
    //   userid = response.data.data.user_id;
    // } else {
    //   return;
    // }

    const { data, status } = await noauthinstance.post(
      "api/user/login_with_otp",
      {
        mobile_number: phoneNumber,
        user_id: userId,
        otp: otp,
        orderId: orderId,
      }
    );

    // console.log({ data })

    setPlayingAgain(false);

    if (data.success === 1) {
      setIsLogin(true);
      setOtpOpen(false);
      const token = data.data.token.access_token;
      localStorage.setItem("token", token);
      const user_id = data.data.user.id;
      localStorage.setItem("user_id", user_id);
      // console.log(token)
      setTriggerRefresh((prev) => prev + 1);
      setshow(false)
      setOpen(false)
      const route = localStorage.getItem('savedSevaSelection')
      navigate(route)
      localStorage.removeItem('savedSevaSelection')
      // window.location.reload()
    }
  };

  // Validate OTP
  const ValidateOtp = () => {
    setPlayingAgain(true);
    if (otp === null || final === null) return;
    setVerifyLoading(true);
    final
      .confirm(otp)
      .then((result) => {
        // success
        sendAuthToBackend(result);
      })
      .catch((err) => {
        alert("Wrong code");
        setPlayingAgain(false);
      })
      .finally(() => {
        setVerifyLoading(false); // Reset loading state after OTP verification is done
      });
  };

  const ValidateOtp1 = () => {
    setPlayingAgain(true);
    if (otp === null || final === null) return;
    setVerifyLoading(true);
    sendAuthToBackend("result");
    // final
    //   .confirm(otp)
    //   .then((result) => {
    //     // success
    //     sendAuthToBackend(result);
    //   })
    //   .catch((err) => {
    //     alert("Wrong code");
    //     setPlayingAgain(false);
    //   })
    //   .finally(() => {
    //     setVerifyLoading(false); // Reset loading state after OTP verification is done
    //   });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const isLoggedIn = localStorage.getItem("token") !== null;

  // Check if the name is null
  // const userName = localStorage.getItem('name');
  // const isNameNull = userName === null;

  // if (isLoggedIn && isNameNull) {
  //   // User is logged in but the name is null, navigate to the profile or another route
  //   navigate('/profile'); // Change '/profile' to your desired route
  // }
  // const handleClose = () => {
  //   setOpen(false)
  // }
  // const handleotpClose = () => {
  //   setOtpOpen(false)
  // }

  // const handleSendOTP = async () => {
  //   try {
  //     const response = await noauthinstance.post('api/user/login', { mobile_number: phoneNumber })
  //     if (response.data.success === 1) {

  //       setOtpData({
  //         otp: response.data.data.otp,
  //         user_id: response.data.data.user_id,
  //       })
  //       setOpen(false)
  //       setOtpOpen(true)
  //     } else {
  //       console.error('Server response indicates an error.')
  //     }
  //   } catch (error) {
  //     console.error('Error sending OTP:', error)
  //   }
  // }

  // const handleCloseOTP = () => {
  //   setOtpOpen(false)
  // }

  // const handleVerifyOTP = async () => {
  // try {
  // const response = await noauthinstance.post('api/user/login_with_otp', {
  //   user_id: otpData.user_id,
  //   otp: otp,
  // })

  // if (response.data.success === 1) {
  //   setIsLogin(true)
  //   console.log('Login successful')
  //   setOtpOpen(false)
  //   const token = response.data.data.token.access_token
  //   localStorage.setItem('token', token)
  //   const user_id = response.data.data.user.id
  //   localStorage.setItem('user_id', user_id)
  //   console.log(token)
  // } else {
  //   console.error('OTP verification failed.')
  // }
  // } catch (error) {
  //   console.error('Error verifying OTP:', error)
  // }
  // }

  const [resendCountdown, setResendCountdown] = useState(60); // 60 seconds countdown
  const [isCountdownRunning, setIsCountdownRunning] = useState(false);

  const startResendCountdown = () => {
    if (!isCountdownRunning) {
      setResendCountdown(60); // Reset the countdown to its initial value
      setIsCountdownRunning(true);

      const countdownInterval = setInterval(() => {
        setResendCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(countdownInterval); // Stop the countdown when it reaches 0
            setIsCountdownRunning(false);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000); // Update the countdown every second (1000 ms)
    }
  };

  useEffect(() => {
    if (show) {
      startResendCountdown();
    }
  }, [show]);
  const handleWrongNumber = () => {
    setOtp("");
    setshow(false);
    setPhoneNumber("");
  };

  const handleMobileNum = () => {
    setPhoneNumber("");
    setOpen(false);
  };

  const formatPhoneNumber = (value) => {
    const cleanedValue = value.replace(/\D+/g, "").substring(0, 10);
    let formattedValue = cleanedValue.replace(/(\d{5})(\d{5})/, "$1 $2");
    return { cleanedValue, formattedValue };
  };

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    const { cleanedValue, formattedValue } = formatPhoneNumber(value);
    setRawPhoneNumber(cleanedValue);
    setPhoneNumber(formattedValue);
  };

  return (
    <div className="log-in">
      <div id="recaptcha-container"></div>
      {/* <Button
        className="login-btn"
        style={{
          padding: isMobile ? "5px 5px" : "5px 15px",
        }}
        variant="outlined"
        onClick={handleClickOpen}
      >
        Log In
      </Button> */}
      <BootstrapDialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-paperScrollPaper": {
            borderRadius: "8px!important",
            overflowY: "unset",
          },
          padding: "0 10px",
        }}
      >
        <div className="custom-login-modal"></div>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        ></DialogTitle>
        {/* <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 9999,
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <DialogContent sx={{ overflowY: "unset" }}>
          <div className="welcome-back welcome-back-2">
            <div
              className="custom-back-arrow"
              style={{ cursor: "pointer" }}
              onClick={() => handleMobileNum()}
            ></div>
            <div className="welcome-back-content">
              {/* <h2>The way to Sanatan Dharma</h2> */}
              <h2>Welcome back!</h2>
              <p>Start your Journey!</p>
            </div>
            <div className="mobile-number">
              <label className="">Mobile No</label>
              {/* <div className='phone-inputs'>
                <input value={phonecode} onChange={e => setPhonecode(e.target.value)} className='input-1' placeholder='+91'  style={{ textAlign: 'center' }} />
                <input className='input-2' placeholder='Enter Number' onChange={(e) => setPhoneNumber(e.target.value)}
              onBlur={(e) => {
                window.fbq("track", "InputData");
            }}
                 />
              </div> */}
              <PhoneInput
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
              />
              {/* <div className="phone-inputs">
                <div className="input-group">
                  <img
                    src={IndiaFlag}
                    alt="India Flag"
                    className="flag-icon"
                    id="flagIcon"
                  />
                  <img
                    src={downArrow}
                    alt="Down arrow"
                    className="flag-icon"
                    id="arrowIcon"
                  />
                  <select
                    className="phone-code-select"
                    value={phonecode}
                    onChange={(e) => setPhonecode(e.target.value)}
                    id="phoneCodeSelect"
                  >
                    <option value="+91">+91</option>
                  </select>

                  <div className="vertical-line"></div>

                  <input
                    className="input-number"
                    placeholder="Enter Number"
                    value={phoneNumber}
                    onChange={(e) => handlePhoneNumberChange(e)}
                    onBlur={() => window.fbq("track", "InputData")}
                  />
                </div>
              </div> */}

              {/* <PhoneInput
                placeholder="Enter number"
                country={"in"}
                className="custom-phone-number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                style={{
                  fontFamily: 'Objectivity',
                  border: '0.6px solid var(--Text---Secondary-Color, #676767)',
                  borderRadius: '13px',
                  padding: '8px',
                  color: '#333'
                }}
                inputProps={{
                  style: {
                    color: '#686868',
                    fontSize: '16px',
                  },
                }}
              
              /> */}

              <p>Enter 10 digit number to login your account</p>
            </div>
            {/* <div className="mobile-number" style={{ marginTop: "0px" }}>
              <label className="">Email</label>
              <input
                style={{
                  border: "0.6px solid #676767",
                  borderRadius: "13px",
                  padding: "8px",
                  height: "45px",
                  width: "308px",
                  fontSize: "16px",
                  color: "#686868",
                }}
                placeholder="example@domain.com"
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
              />
            </div> */}
            <div className="send-otp">
              <Button
                style={{
                  width: "150px",
                  height: "42px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => (justPlaying === false ? signin1() : undefined)}
                variant="contained"
                className="app-btn app-btn-primary has-icon-end"
              >
                {loading === "sending-otp" ? (
                  <CircularProgress size={24} />
                ) : (
                  <span className="app-btn-text">Send OTP</span>
                )}
              </Button>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>

      {/* OTP Dialog */}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={show}
        onEnter={() => startResendCountdown()}
        sx={{
          overflowY: "unset",
          "& .MuiDialog-paperScrollPaper": {
            overflowY: "unset",
          },
          padding: "0 10px",
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        ></DialogTitle>
        {/* <IconButton
          aria-label="close"
          onClick={() => {
            setOpen(false);
            setshow(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <DialogContent
          sx={{
            overflowY: "unset",
            "& .MuiDialog-paperScrollPaper": {
              borderRadius: "8px!important",
              overflowY: "unset",
            },
          }}
        >
          <div className="custom-img-otp"></div>
          <div className="welcome-back welcome-otp-2">
            <div
              className="custom-back-arrow"
              style={{ cursor: "pointer" }}
              onClick={handleWrongNumber}
            ></div>
            <div className="welcome-back-content">
              <h2>Enter OTP</h2>
              <p>
                Sent to <span>{loginEmail}</span>
              </p>
            </div>
            <div className="send-otp-number">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      textAlign: "center",
                      fontSize: "24px",
                      fontWeight: "bold",
                      width: "40px",
                      height: "40px",
                    }}
                    placeholder="-"
                  />
                )}
              />
              <div className="resend">
                <div className="receive-otp">
                  <p>Didn’t receive the OTP?</p>
                </div>
                <div className="resend-btn">
                  <Button
                    onClick={() => {
                      if (isCountdownRunning) {
                        return;
                      }
                      setOtp("");
                      startResendCountdown();
                      signin();
                    }}
                    sx={{
                      paddingRight: "unset",
                    }}
                  >
                    <img src={resend} />
                    <span
                      style={{
                        color: isCountdownRunning ? undefined : "#ff5b00",
                      }}
                    >
                      Resend
                    </span>
                  </Button>
                </div>
              </div>
              <div className="resending-otp">
                <p>
                  Resend OTP in <span>{resendCountdown}</span>&nbsp;sec
                </p>
                {/* <h5 style={{ cursor: "pointer" }} onClick={handleWrongNumber}>
                  Wrong Number ?
                </h5> */}
              </div>
            </div>
            <div className="send-otp verify-otp">
              <Button
                onClick={() => (!playingAgain ? ValidateOtp1() : undefined)}
                variant="contained"
                className="app-btn app-btn-primary has-icon-end"
              >
                {verifyloading ? (
                  <CircularProgress size={24} />
                ) : (
                  <span className="app-btn-text">Verify</span>
                )}
              </Button>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
    // <div style={{ "marginTop": "200px" }}>
    //   <center>
    //     <div style={{ display: !show ? "block" : "none" }}>
    //       <input value={formattedPhone} onChange={(e) => {
    //         setFormattedPhone(e.target.value)
    //       }}
    //         placeholder="phone number" />
    //       <br /><br />
    //       <div id="recaptcha-container"></div>
    //       <button onClick={signin}>Send OTP</button>
    //     </div>
    //     <div style={{ display: show ? "block" : "none" }}>
    //       <input type="text" placeholder={"Enter your OTP"}
    //         onChange={(e) => { setotp(e.target.value) }}></input>
    //       <br /><br />
    //       <button onClick={ValidateOtp}>Verify</button>
    //     </div>
    //   </center>
    // </div>
  );
}

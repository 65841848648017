import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import instance from "../../utils/Api";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import {
  Box,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { json } from "react-router-dom";
import "dayjs/locale/en";
import EditFamilyDetails from "../../pages/EditFamilyDetails";
import { useDateField } from "@mui/x-date-pickers/DateField/useDateField";
import api from "../../config/backend";
import EditIcon from "@mui/icons-material/Edit";
import PencilIcon from "../../images/champaign/pencil_icon.svg";

export default function HorizontalMulAccordion({
  familyData,
  setFamilyData,
  selectedFamily,
  setSelectedFamily,
  isSelectedFamily,
  seva,
  setIsSelectedFamily,
  setFamilyCheck,
  familycheck,
  setRefresh,
  handleCheckout,
  setSelectedCount,
  selectedCount,
  onKartaSelectedList,
  onAncestorSelectedList,
  onSelfSelectedList,
  cartId,
}) {
  dayjs.extend(customParseFormat);
  dayjs.locale("en");

  const [error, setError] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [familyDataCart, setFamilyDatacart] = useState([]);
  const [triggerRefresh, setTriggerRefresh] = useState(0);
  const token = localStorage.getItem("token");
  const [familyEditedRefresh, setFamilyEditedRefresh] = useState(0);
  const [full_name, setFull_name] = useState("");
  const [relationId, setRelationId] = useState("");
  const [gotram, setGotram] = useState("");
  const [nakshatram, setNakshatram] = useState("");
  const [rasiId, setRasiId] = useState("");
  const [notes, setNotes] = useState("");
  const [relationOptions, setRelationOptions] = useState([]);
  const [rasiOptions, setRasiOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dontKnow, setDontKnow] = useState(1);
  const [profiledata, setProfiledata] = useState([]);
  const [profilePictureData, setProfilePictureData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [editFamilyModal, setEditFamilyModal] = useState(false);
  const [familyMemberData, setFamilyMemberData] = useState();
  const isMobile = useMediaQuery("(max-width: 767px)");
  const [familyType, setFamilyType] = useState("self");

  const sevafamilytype = seva.family_type;

  const [uniqueFamilyTypes, setUniqueFamilyTypes] = useState({});

  const getUniqueFamilyTypes = (familyDataCart) => {
    const familyTypes = {};
    familyDataCart.forEach((member) => {
      familyTypes[member.family_type] = true;
    });
    return familyTypes;
  };

  const fetchData = async () => {
    try {
      const profileResponse = await axios.get(`${api}/api/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });

      setProfileData(profileResponse.data.data);

      const familyResponse = await axios.get(`${api}/api/myfamily`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });

      setFamilyData(familyResponse.data.data.data);

      // console.log('family response data: ',familyResponse.data.data.data)

      // console.table(familyResponse.data.data.data)

      const uniqueFamilyObj = getUniqueFamilyTypes(
        familyResponse?.data?.data?.data
      );

      setUniqueFamilyTypes(uniqueFamilyObj);

      setFamilyDatacart(
        familyResponse.data.data.data.map((item) => {
          return {
            ...item,
            cartindex: seva && seva.seva_id,
            selected: false,
          };
        })
      );

      setError(null);
    } catch (err) {
      setError(err);
      console.error(err);
    }
  };

  const handleDateChange = (date) => {
    const selectedDate = dayjs(date);
    const today = dayjs();

    // Check if the selected date is before today
    if (selectedDate.isBefore(today) || selectedDate.isSame(today, "day")) {
      setSelectedDate(selectedDate.format("YYYY-MM-DD"));
    } else {
      alert("Invalid date. Please select a date on or before today.");
      setSelectedDate("");
    }
  };

  const fetchRasiData = async () => {
    try {
      const response = await axios.get(`${api}/api/rasi`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });
      // console.log(response.data.data.data)
      if (response.status === 200) {
        setRasiOptions(response.data.data.data);
      } else {
        console.error("Failed to fetch data from the API.");
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };

  const fetchrelationData = async () => {
    try {
      const response = await axios.get(`${api}/api/relation`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });

      if (response.status === 200) {
        setRelationOptions(response.data.data.data);
      } else {
        console.error("Failed to fetch data from the API.");
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };

  const handleCloseAddAddressModal = () => {
    setDontKnow((prev) => prev + 1);
    setFull_name("");
    setSelectedDate(null);
    setRelationId("");
    setRasiId("");
    setGotram("");
    setNakshatram("");
    setNotes("");
    setIsAddAddressModalOpen(false);
    setFieldErrors({
      full_name: false,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsEditing(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmailError({
      email: false,
    });
  };

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(`${api}/api/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });

      const name = response.data.data.fname;
      localStorage.setItem("name", name || "");
      setProfiledata(response.data.data);
      if (response.data.data.image) {
        setProfilePictureData(response.data.data.image);
      }
    } catch (err) {
      setError(err);
    }
  };
  const [emailError, setEmailError] = useState({
    email: false,
  });

  const validateEmail = () => {
    if (email.trim() !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(email);

      setEmailError({ email: !isValid });

      return isValid;
    }

    setEmailError({ email: false });
    return true;
  };
  const saveProfileData = async () => {
    window.fbq("track", "InputData");
    if (!validateEmail()) {
      return;
    }

    try {
      const updatedProfileData = {
        fname: firstName,
        mobile_number: phone,
        email,
      };
      const response = await axios.put(
        `${api}/api/user/profile`,
        updatedProfileData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setProfiledata(response.data.data);
        setIsEditing(false);
        handleClose();
        setTriggerRefresh((prev) => prev + 1);
      } else {
        console.error("Failed to update profile data");
      }
    } catch (error) {
      console.error("Failed to update profile data:", error);
    }
  };

  const handleChange = (familyMember) => {
    let selected = familyDataCart.map((item) => {
      return {
        ...item,
        selected: familyMember.id === item.id ? !item.selected : item.selected,
      };
    });

    console.log("selected:--", selected);

    if (familyMember?.family_type === "kartha" && familyMember?.selected) {
      selected = selected.map((item) => {
        if (item?.family_type === "kartha" && item?.id !== familyMember?.id) {
          return {
            ...item,
            selected: false,
          };
        }
        return item;
      });
    }

    const selectedKartha = selected.filter(
      (item) => item?.family_type === "kartha" && item?.selected
    );

    console.log("familyMember:", familyMember?.id);

    const selectedAncestor = selected.filter(
      (item) => item?.family_type === "ancestors" && item?.selected
    );

    const selectedSelfMember = selected.filter(
      (item) => item?.family_type === "self" && item?.selected
    );

    let updatedItems;

    // for(let item of selected){

    //     updatedItems = {...item};
    //     console.log('item selected:', item.id)
    //     if(item?.family_type === "ancestors"){
    //         updatedItems.selected = familyMember.id === item.id ? !item.selected : item.selected;
    //     }else if(item?.family_type === "kartha"){
    //         updatedItems.selected = familyMember.id === item.id ? !item.selected : item.selected;
    //     }

    //     return updatedItems;
    // }

    // console.log('selectedKarta:', selectedKartha[familyMember?.id])

    onKartaSelectedList(cartId, selectedKartha.length);
    onAncestorSelectedList(cartId, selectedAncestor.length);
    onSelfSelectedList(cartId, selectedSelfMember.length);

    const ischecked = selected.some((item) => item.selected);

    const famids = selected
      .filter((item) => item.selected)
      .map((item) => item.id);

    setFamilyCheck(famids);

    setIsSelectedFamily((prev) => {
      const copy = JSON.parse(JSON.stringify(prev));

      const fixed = copy.map((item) => {
        const key = Object.keys(item)[0];

        // console.log({ key, test: seva.id })
        if (key === seva.id) {
          const test = {};

          test[key] = ischecked;

          return test;
        }

        return item;
      });

      return fixed;
    });

    console.log("selected array:", selected);

    setFamilyDatacart(selected);

    // console.log('familyDataCart:', familyDataCart)
  };

  // const handleChange = (familyMember) => {
  // 	const selected = familyDataCart.map((item) => ({
  // 		...item,
  // 		selected: familyMember.id === item.id ? !item.selected : item.selected,
  // 	}));

  // 	const famids = selected.filter((item) => item.selected).map((item) => item.id);

  // 	setFamilyCheck(famids);

  // 	setIsSelectedFamily((prev) => {
  // 		const copy = JSON.parse(JSON.stringify(prev));

  // 		const fixed = copy.map((item) => {
  // 			const key = Object.keys(item)[0];

  // 			if (key === seva.id) {
  // 				const test = {};
  // 				test[key] = selected.filter((item) => item.selected).length > 0;
  // 				setSelectedCount(selected.filter((item) => item.selected).length); // Update selected count
  // 				console.log("selectedCount:", selectedCount);
  // 				return test;
  // 			}

  // 			return item;
  // 		});

  // 		return fixed;
  // 	});

  // 	setFamilyDatacart(selected);
  // };

  function editFamilyHandler(familyMember) {
    setFamilyMemberData(familyMember);
    setEditFamilyModal(true);
  }
  function closeEditFamilyModal() {
    setEditFamilyModal(false);
    setFamilyData(undefined);
  }
  const [fieldErrors, setFieldErrors] = useState({
    full_name: false,
  });
  const validateForm = () => {
    const errors = {};

    if (!full_name) {
      errors.full_name = true;
    }

    setFieldErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      return false;
    }

    return true;
  };

  const handleFamilyType = (type) => {
    setFamilyType(type);
    setIsAddAddressModalOpen(true);
  };

  const handleSaveProfile = async () => {
    if (!validateForm()) {
      toast.error("Please fill in all required fields");
      return;
    }

    const familyData = {
      full_name,
      dob: selectedDate,
      relation_id: relationId,
      rasi_id: rasiId,
      gothram: gotram,
      nakshatram,
      description: notes,
      family_type: familyType,
    };

    try {
      const response = await axios.post(`${api}/api/myfamily`, familyData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      window.fbq("track", "InputData");
      if (response.data.success === 1) {
        const newFamilyMember = {
          ...response.data.data,
          selected: true,
        };

        setFamilyDatacart((prevFamilyDatacart) => {
          const copy = JSON.parse(JSON.stringify(prevFamilyDatacart));
          const addedfamily = [...copy, newFamilyMember];
          return addedfamily;
        });

        setFamilyCheck((prev) => {
          const copy = prev;
          copy.push(response.data.data.id);
          return [...copy];
        });

        setDontKnow((prev) => prev + 1);
        setFull_name("");
        setSelectedDate(null);
        setRelationId("");
        setRasiId("");
        setGotram("");
        setNakshatram("");
        setNotes("");
        handleCloseAddAddressModal();
      } else {
        console.error("Error saving family data:", response.data.message);
      }
    } catch (error) {
      console.error("Error saving family data:", error);
    }
  };

  useEffect(() => {
    fetchRasiData();
    fetchrelationData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [familyEditedRefresh]);

  useEffect(() => {
    if (profiledata) {
      setFirstName(profiledata.fname || "");

      setEmail(profiledata.email || "");

      setPhone(profiledata.mobile_number || "");
    }
  }, [profiledata]);

  useEffect(() => {
    fetchProfileData();
  }, [triggerRefresh]);

  return (
    <div className="check-out-panel">
      {uniqueFamilyTypes?.kartha && uniqueFamilyTypes?.ancestors ? (
        <>
          <div className="chech-box-panel-main">
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ color: "#FF5B00", fontSize: "30px" }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="add-family-content">
                  <h5>
                    Add{" "}
                    {uniqueFamilyTypes?.ancestors ? "Ancestor" : sevafamilytype}{" "}
                    Details
                  </h5>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {dontKnow &&
                Array.isArray(familyDataCart) &&
                familyDataCart.length > 0 ? (
                  familyDataCart
                    .filter(
                      (familyMember) => familyMember.family_type === "ancestors"
                    )
                    .map((familyMember) => {
                      const pencilIconClass = familyMember?.selected
                        ? "pencil-icon pencil-icon-selected"
                        : "pencil-icon pencil-icon-default";
                      return (
                        <Typography className="menu-icon" key={familyMember.id}>
                          <div className="chechout-checkbox-details">
                            <div className="checkout-label-details">
                              <Checkbox
                                checked={familyMember.selected}
                                onChange={() => handleChange(familyMember)}
                                sx={{
                                  color: familyMember.selected
                                    ? "#ff5b00"
                                    : "#988E8E!important",
                                  "&.Mui-checked": {
                                    color: "#ff5b00",
                                  },
                                }}
                              />
                              <p>
                                <span>{familyMember.full_name}</span>{" "}
                                <span>{familyMember.family_type}</span>{" "}
                                {familyMember.relation &&
                                  `- ${familyMember.relation.name}`}
                              </p>
                            </div>
                            <div className="chechout-checkbox-details-btn">
                              <div className="edit-btn">
                                {isMobile ? (
                                  <img
                                    src={PencilIcon}
                                    variant="outlined"
                                    className={pencilIconClass}
                                    onClick={() =>
                                      editFamilyHandler(familyMember)
                                    }
                                    sx={{
                                      color: familyMember.selected
                                        ? undefined
                                        : "#ff5b00",
                                      cursor: "pointer",
                                      fill: "none",
                                      stroke: familyMember.selected
                                        ? "#676767"
                                        : "#FF5B00",
                                    }}
                                  />
                                ) : (
                                  <Button
                                    onClick={() =>
                                      editFamilyHandler(familyMember)
                                    }
                                    variant="outlined"
                                    sx={{
                                      color: familyMember.selected
                                        ? "#676767!important"
                                        : "#ff5b00!important",
                                      borderColor: familyMember.selected
                                        ? "#676767!important"
                                        : "#ff5b00!important",
                                      cursor: "pointer",
                                      filter: familyMember.selected
                                        ? "grayscale(100%)"
                                        : "",
                                    }}
                                  >
                                    Edit
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Typography>
                      );
                    })
                ) : (
                  <Typography>No data available</Typography>
                )}
                <div className="add-new-address app-new-address-2">
                  {uniqueFamilyTypes?.ancestors && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        marginTop: "30px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#676767",
                          fontSize: "13px",
                          fontWeight: "500",
                          display: { xs: "none", sm: "block" },
                        }}
                        variant="body2"
                        gutterBottom
                      >
                        Add more family member
                      </Typography>
                      <Button
                        disableRipple
                        disableElevation
                        onClick={() => handleFamilyType("ancestors")}
                        sx={{
                          width: "140px",
                          borderRadius: {
                            xs: "8px!important",
                            sm: "40.87px!important",
                          },
                        }}
                      >
                        <span>
                          <AddIcon />
                        </span>
                        Add {uniqueFamilyTypes?.ancestors && "Ancestor"}
                      </Button>
                    </Box>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="chech-box-panel-main">
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ color: "#FF5B00", fontSize: "30px" }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="add-family-content">
                  <h5>
                    Add {uniqueFamilyTypes?.kartha ? "Kartha" : sevafamilytype}{" "}
                    Details
                  </h5>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {dontKnow &&
                Array.isArray(familyDataCart) &&
                familyDataCart.length > 0 ? (
                  familyDataCart
                    .filter(
                      (familyMember) => familyMember.family_type === "kartha"
                    )
                    .map((familyMember) => {
                      const pencilIconClass = familyMember?.selected
                        ? "pencil-icon pencil-icon-selected"
                        : "pencil-icon pencil-icon-default";
                      return (
                        <Typography className="menu-icon" key={familyMember.id}>
                          <div className="chechout-checkbox-details">
                            <div className="checkout-label-details">
                              <Checkbox
                                checked={familyMember.selected}
                                onChange={() => handleChange(familyMember)}
                                sx={{
                                  color: familyMember.selected
                                    ? "#ff5b00"
                                    : "#988E8E!important",
                                  "&.Mui-checked": {
                                    color: "#ff5b00",
                                  },
                                }}
                              />
                              <p>
                                <span>{familyMember.full_name}</span>{" "}
                                <span>{familyMember.family_type}</span>{" "}
                                {familyMember.relation &&
                                  `- ${familyMember.relation.name}`}
                              </p>
                            </div>
                            <div className="chechout-checkbox-details-btn">
                              <div className="edit-btn">
                                {isMobile ? (
                                  <img
                                    src={PencilIcon}
                                    className={pencilIconClass}
                                    variant="outlined"
                                    onClick={() =>
                                      editFamilyHandler(familyMember)
                                    }
                                    sx={{
                                      color: familyMember.selected
                                        ? undefined
                                        : "#ff5b00",
                                      cursor: "pointer",
                                      fill: "none",
                                      stroke: familyMember.selected
                                        ? "#676767"
                                        : "#FF5B00",
                                    }}
                                  />
                                ) : (
                                  <Button
                                    onClick={() =>
                                      editFamilyHandler(familyMember)
                                    }
                                    variant="outlined"
                                    sx={{
                                      color: familyMember.selected
                                        ? "#676767!important"
                                        : "#ff5b00!important",
                                      borderColor: familyMember.selected
                                        ? "#676767!important"
                                        : "#ff5b00!important",
                                      cursor: "pointer",
                                      filter: familyMember.selected
                                        ? "grayscale(100%)"
                                        : "",
                                    }}
                                  >
                                    Edit
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Typography>
                      );
                    })
                ) : (
                  <Typography>No data available</Typography>
                )}

                <div className="add-new-address app-new-address-2">
                  {uniqueFamilyTypes?.kartha && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        marginTop: "30px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#676767",
                          fontSize: "13px",
                          fontWeight: "500",
                          display: { xs: "none", sm: "block" },
                        }}
                        variant="body2"
                        gutterBottom
                      >
                        Add more family member
                      </Typography>
                      <Button
                        disableRipple
                        disableElevation
                        onClick={() => handleFamilyType("kartha")}
                        sx={{
                          width: "140px",
                          borderRadius: {
                            xs: "8px!important",
                            sm: "40.87px!important",
                          },
                        }}
                      >
                        <span>
                          <AddIcon />
                        </span>
                        Add {uniqueFamilyTypes?.kartha && "Kartha"}
                      </Button>
                    </Box>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      ) : (
        <div className="chech-box-panel-main">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: "#FF5B00", fontSize: "30px" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="add-family-content">
                <h5>
                  Add {uniqueFamilyTypes?.self ? "Family" : sevafamilytype}{" "}
                  Details
                </h5>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {dontKnow &&
              Array.isArray(familyDataCart) &&
              familyDataCart.length > 0 ? (
                familyDataCart
                  .filter((familyMember) => {
                    // if (sevafamilytype === null || sevafamilytype === "") {
                    //   return true;
                    // }
                    // return sevafamilytype == familyMember.family_type;
                    return familyMember.family_type === "self";
                  })
                  .map((familyMember) => {
                    const pencilIconClass = familyMember?.selected
                      ? "pencil-icon pencil-icon-selected"
                      : "pencil-icon pencil-icon-default";
                    return (
                      <Typography className="menu-icon" key={familyMember.id}>
                        <div className="chechout-checkbox-details">
                          <div className="checkout-label-details">
                            <Checkbox
                              checked={familyMember.selected}
                              onChange={() => handleChange(familyMember)}
                              sx={{
                                color: familyMember.selected
                                  ? "#ff5b00"
                                  : "#988E8E!important",
                                "&.Mui-checked": {
                                  color: "#ff5b00",
                                },
                              }}
                            />
                            <p>
                              <span>{familyMember.full_name}</span>{" "}
                              <span>{familyMember.family_type}</span>{" "}
                              {familyMember.relation &&
                                `- ${familyMember.relation.name}`}
                            </p>
                          </div>
                          <div className="chechout-checkbox-details-btn">
                            <div className="edit-btn">
                              {isMobile ? (
                                <img
                                  src={PencilIcon}
                                  className={pencilIconClass}
                                  variant="outlined"
                                  onClick={() =>
                                    editFamilyHandler(familyMember)
                                  }
                                  sx={{
                                    color: familyMember.selected
                                      ? undefined
                                      : "#ff5b00",
                                    cursor: "pointer",
                                    fill: "none",
                                    stroke: familyMember.selected
                                      ? "#676767"
                                      : "#FF5B00",
                                  }}
                                />
                              ) : (
                                <Button
                                  onClick={() =>
                                    editFamilyHandler(familyMember)
                                  }
                                  variant="outlined"
                                  sx={{
                                    color: familyMember.selected
                                      ? "#676767!important"
                                      : "#ff5b00!important",
                                    borderColor: familyMember.selected
                                      ? "#676767!important"
                                      : "#ff5b00!important",
                                    cursor: "pointer",
                                    filter: familyMember.selected
                                      ? "grayscale(100%)"
                                      : "",
                                  }}
                                >
                                  Edit
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </Typography>
                    );
                  })
              ) : (
                <Typography>No data available</Typography>
              )}
              <div className="add-new-address app-new-address-2">
                {uniqueFamilyTypes?.self && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      marginTop: "30px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#676767",
                        fontSize: "13px",
                        fontWeight: "500",
                        display: { xs: "none", sm: "block" },
                      }}
                      variant="body2"
                      gutterBottom
                    >
                      Add more family member
                    </Typography>
                    <Button
                      disableRipple
                      disableElevation
                      onClick={() => handleFamilyType("self")}
                      sx={{
                        width: "140px",
                        borderRadius: {
                          xs: "8px!important",
                          sm: "40.87px!important",
                        },
                      }}
                    >
                      <span>
                        <AddIcon />
                      </span>
                      Add {sevafamilytype == "self" ? "Family" : sevafamilytype}
                    </Button>
                  </Box>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      <EditFamilyDetails
        open={editFamilyModal}
        onClose={closeEditFamilyModal}
        data={familyMemberData}
        setFamilyEditedRefresh={setFamilyEditedRefresh}
      />

      {/* <div className="add-new-address app-new-address-2">
        {sevafamilytype && sevafamilytype !== "kartha_ancestors" && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              sx={{
                color: "#676767",
                fontSize: "13px",
                fontWeight: "500",
                display: { xs: "none", sm: "block" },
              }}
              variant="body2"
              gutterBottom
            >
              Add more family member
            </Typography>
            <Button
              disableRipple
              disableElevation
              onClick={() => setIsAddAddressModalOpen(true)}
              sx={{
                width: "140px",
              }}
            >
              <span>
                <AddIcon />
              </span>
              Add {sevafamilytype == "self" ? "Family" : sevafamilytype}
            </Button>
          </Box>
        )}
      </div> */}

      {/* <div className="chech-box-panel-main">
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#FF5B00", fontSize: "30px" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="add-family-content">
              <h5>
                Add {uniqueFamilyTypes?.ancestors ? "Ancestor" : sevafamilytype}{" "}
                Details
              </h5>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {dontKnow &&
            Array.isArray(familyDataCart) &&
            familyDataCart.length > 0 ? (
              familyDataCart
                .filter(
                  (familyMember) => familyMember.family_type === "ancestors"
                )
                .map((familyMember) => (
                  <Typography className="menu-icon" key={familyMember.id}>
                    <div className="chechout-checkbox-details">
                      <div className="checkout-label-details">
                        <Checkbox
                          checked={familyMember.selected}
                          onChange={() => handleChange(familyMember)}
                          sx={{
                            color:
                                familyMember.selected
                                ? "#ff5b00"
                                : "#988E8E!important",
                            "&.Mui-checked": {
                              color: "#ff5b00",
                            },
                          }}
                        />
                        <p>
                          <span>{familyMember.full_name}</span>{" "}
                          <span>{familyMember.family_type}</span>{" "}
                          {familyMember.relation &&
                            `- ${familyMember.relation.name}`}
                        </p>
                      </div>
                      <div className="chechout-checkbox-details-btn">
                        <div className="edit-btn">
                          {isMobile ? (
                            <EditIcon
                              variant="outlined"
                              onClick={() => editFamilyHandler(familyMember)}
                              sx={{
                                color: familyMember.selected
                                  ? undefined
                                  : "#ff5b00",
                                cursor: "pointer",
                                fill: "none",
                                stroke: familyMember.selected
                                  ? "#676767"
                                  : "#FF5B00",
                              }}
                            />
                          ) : (
                            <Button
                              onClick={() => editFamilyHandler(familyMember)}
                              variant="outlined"
                              sx={{
                                color: familyMember.selected
                                  ? "#676767!important"
                                  : "#ff5b00!important",
                                borderColor: familyMember.selected
                                  ? "#676767!important"
                                  : "#ff5b00!important",
                                cursor: "pointer",
                                filter: familyMember.selected
                                  ? "grayscale(100%)"
                                  : "",
                              }}
                            >
                              Edit
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Typography>
                ))
            ) : (
              <Typography>No data available</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </div> */}

      <Dialog
        open={isAddAddressModalOpen}
        onClose={handleCloseAddAddressModal}
        fullWidth
        maxWidth="md"
      >
        <DialogContent style={{ padding: "20px" }}>
          <div>
            <div>
              {/* <Typography variant="h6">Family Details</Typography> */}
              <div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
                      <div className="myfamily-forms-1">
                        <FormLabel>
                          Full Name<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <TextField
                          id="fullName"
                          name="full_name"
                          value={full_name}
                          required
                          onChange={(e) => setFull_name(e.target.value)}
                          error={fieldErrors.full_name}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="myfamily-forms-1">
                        <FormLabel>DOB</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            maxDate={dayjs()}
                            type="date"
                            id="datepicker"
                            value={selectedDate}
                            onChange={handleDateChange}
                            label=""
                            // maxDate={maxDate}
                            // // You can also provide a custom error message when the date is beyond the maxDate
                            // maxDateMessage="Date cannot be in the future"
                            renderInput={(params) => (
                              <TextField {...params} type="date" I />
                            )}
                            autoFocus={true}
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="myfamily-forms-2">
                        <FormLabel>Relation</FormLabel>
                        <Select
                          id="nakshatram"
                          name="Relation"
                          value={relationId}
                          onChange={(e) => setRelationId(e.target.value)}
                        >
                          <MenuItem disabled value="">
                            Select your Relation
                          </MenuItem>
                          {Array.isArray(relationOptions) &&
                            relationOptions.map((option, index) => {
                              return (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="myfamily-forms-1">
                        <FormLabel>Gotram</FormLabel>
                        <TextField
                          id="gotram"
                          name="gotram"
                          value={gotram}
                          onChange={(e) => setGotram(e.target.value)}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="myfamily-forms-2">
                        <FormLabel>Nakshatram</FormLabel>
                        <Select
                          id="nakshatram"
                          name="nakshatram"
                          value={nakshatram}
                          onChange={(e) => setNakshatram(e.target.value)}
                        >
                          <MenuItem disabled value="">
                            Select your Nakshatram
                          </MenuItem>
                          <MenuItem value={"Aswini/Aswathi Nakshatra"}>
                            Aswini/Aswathi Nakshatra
                          </MenuItem>
                          <MenuItem value={"Bharani/Bharani Nakshatra"}>
                            Bharani/Bharani Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Krithika/Karthigai/Kaarthika Nakshatra"}
                          >
                            Krithika/Karthigai/Kaarthika Nakshatra
                          </MenuItem>
                          <MenuItem value={"Rohini Nakshatra"}>
                            Rohini Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={
                              "Mrigashiras/Mrigasheersham/Makeeryam Nakshatra"
                            }
                          >
                            Mrigashiras/Mrigasheersham/Makeeryam Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Aardhra/Arudra/Thiruvaathirai Nakshatra"}
                          >
                            Aardhra/Arudra/Thiruvaathirai Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Punarvasu/Punarpoosam/Punartham Nakshatra"}
                          >
                            Punarvasu/Punarpoosam/Punartham Nakshatra
                          </MenuItem>
                          <MenuItem value={"Pushyami/Poosam/Pooyyam Nakshatra"}>
                            Pushyami/Poosam/Pooyyam Nakshatra
                          </MenuItem>
                          <MenuItem value={"Ashlesha/Aayilyam Nakshatra"}>
                            Ashlesha/Aayilyam Nakshatra
                          </MenuItem>
                          <MenuItem value={"Magha/Makha/Makham Nakshatra"}>
                            Magha/Makha/Makham Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"PoorvaPhalguni/Pubba/Pooram Nakshatra"}
                          >
                            PoorvaPhalguni/Pubba/Pooram Nakshatra
                          </MenuItem>
                          <MenuItem value={"Uthraphalguni/Uttaram Nakshatra"}>
                            Uthraphalguni/Uttaram Nakshatra
                          </MenuItem>
                          <MenuItem value={"Hastha/Hastham/Aastha Nakshatra"}>
                            Hastha/Hastham/Aastha Nakshatra
                          </MenuItem>
                          <MenuItem value={"Chitra/Chithirai Nakshatra"}>
                            Chitra/Chithirai Nakshatra
                          </MenuItem>
                          <MenuItem value={"Swathi Nakshatra"}>
                            Swathi Nakshatra
                          </MenuItem>
                          <MenuItem value={"Vishakha/Visaakam Nakshatra"}>
                            Vishakha/Visaakam Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Anuradha/Anusham/Anizham Nakshatra"}
                          >
                            Anuradha/Anusham/Anizham Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Jyeshta/Kettai/Thrikketta Nakshatra"}
                          >
                            Jyeshta/Kettai/Thrikketta Nakshatra
                          </MenuItem>
                          <MenuItem value={"Moola/Moolam Nakshatra"}>
                            Moola/Moolam Nakshatra
                          </MenuItem>
                          <MenuItem value={"Poorvashaada/Pooraadam Nakshatra"}>
                            Poorvashaada/Pooraadam Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"U.Shada/Uthrashaada/Uthiraadam Nakshatra"}
                          >
                            U.Shada/Uthrashaada/Uthiraadam Nakshatra
                          </MenuItem>
                          <MenuItem value={"Shravan/Thiruvonam Nakshatra"}>
                            Shravan/Thiruvonam Nakshatra
                          </MenuItem>
                          <MenuItem value={"Dhanishta/Avittam Nakshatra"}>
                            Dhanishta/Avittam Nakshatra Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Shathabhisha/Chathayam/Sadayam Nakshatra"}
                          >
                            Shathabhisha/Chathayam/Sadayam Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Poorvabhadra/Poorattadhi Nakshatra"}
                          >
                            Poorvabhadra/Poorattadhi Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Uthrabhadra/Uthirattathi Nakshatra"}
                          >
                            Uthrabhadra/Uthirattathi Nakshatra
                          </MenuItem>
                          <MenuItem value={"Revathi Nakshatra"}>
                            Revathi Nakshatra
                          </MenuItem>
                          <MenuItem value={"Not Sure "}>Not Sure</MenuItem>
                        </Select>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="myfamily-forms-2">
                        <FormLabel>Rasi</FormLabel>
                        <Select
                          id="nakshatram"
                          name="nakshatram"
                          value={rasiId}
                          onChange={(e) => setRasiId(e.target.value)}
                        >
                          <MenuItem disabled value="">
                            Select your Rasi
                          </MenuItem>
                          {Array.isArray(rasiOptions) &&
                            rasiOptions.map((option, index) => {
                              return (
                                <MenuItem key={index} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                      <div className="myfamily-forms-1">
                        <FormLabel>Notes</FormLabel>
                        <TextField
                          id="notes"
                          name="notes"
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                          placeholder="Write something which will reflect in Profile Screen"
                          multiline
                          rows={5}
                          maxRows={5}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="myfamily-forms-btn">
                  {/* <Button>Reset</Button> */}
                  <Button onClick={handleSaveProfile}>Save Profile</Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </div>
  );
}

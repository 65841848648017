import React from "react";
import { Box, Typography, Grid, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import PersonIcon from "../../images/champaign/person.svg";
import VideoIcon from "../../images/champaign/video.svg";
import PrasadamIcon from "../../images/champaign/prasad.svg";
import DeliveryIcon from "../../images/champaign/delivery.svg";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFECE1",
  padding: theme.spacing(2),
  borderRadius: "8px",
  textAlign: "center",
  height: "160px",
  width: "250px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  position: "relative",
  fontFamily: "Objectivity",
  fontSize: "18.33px",
  fontWeight: 400,
  lineHeight: "18.56px",
  letterSpacing: "0.03em",
  color: "#505050",
}));

const DividerHorizontal = styled(Box)(({ theme }) => ({
  height: "2px",
  backgroundColor: "#FF5B004D",
  width: "40px",
  alignSelf: "center",
}));

const DividerVertical = styled(Box)(({ theme }) => ({
  width: "2px",
  backgroundColor: "#FF5B004D",
  height: "18px",
  alignSelf: "center",
  position: "absolute",
  bottom: "-17px",
  left: "50%",
  transform: "translateX(-50%)",
}));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  marginTop: "18.33px",
  opacity: 1,
  [theme.breakpoints.down("md")]: {
    fontSize: "13px",
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    marginTop: "3.33px",
  },
}));

const ImageIcon = styled("img")(({ theme }) => ({
  width: "50px",
  height: "50px",
  [theme.breakpoints.down("md")]: {
    width: "35px",
    height: "35px",
  },
}));

const Process = () => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ margin: "0 auto" }}
      >
        <Grid item xs={5} sm={2} sx={{ paddingLeft: "unset!important" }}>
          <Box display="flex" alignItems="center">
            <Item
              sx={{
                width: {
                  sm: "140px",
                  md: "175px",
                  lg: "250px",
                },
                height: {
                  sm: "160px",
                  md: "215px",
                  lg: "195px",
                },
              }}
            >
              <ImageIcon src={PersonIcon} alt="Give Gotra Names" />
              <ResponsiveTypography variant="body1">
                Give Gotra Names
              </ResponsiveTypography>
            </Item>
          </Box>
        </Grid>
        <DividerHorizontal />
        <Grid item xs={5} sm={2} sx={{ paddingLeft: "unset!important" }}>
          <Box display="flex" alignItems="center" sx={{ position: "relative" }}>
            <Item
              sx={{
                width: {
                  sm: "140px",
                  md: "175px",
                  lg: "250px",
                },
                height: {
                  sm: "160px",
                  md: "215px",
                  lg: "195px",
                },
              }}
            >
              <ImageIcon src={VideoIcon} alt="Give Gotra Names" />
              <ResponsiveTypography variant="body1">
                Live Streaming will be provided
              </ResponsiveTypography>
            </Item>
            <DividerVertical
              sx={{
                display: {
                  xs: "block",
                  sm: "none",
                },
              }}
            />
          </Box>
        </Grid>
        <DividerHorizontal
          sx={{ display: { xs: "none", sm: "block", md: "block" } }}
        />
        {!isMobile && (
          <Grid item xs={5} sm={2} sx={{ paddingLeft: "unset!important" }}>
            <Box display="flex" alignItems="center">
              <Item
                sx={{
                  width: {
                    sm: "140px",
                    md: "175px",
                    lg: "250px",
                  },
                  height: {
                    sm: "160px",
                    md: "215px",
                    lg: "195px",
                  },
                }}
              >
                <ImageIcon src={DeliveryIcon} alt="Give Gotra Names" />
                <ResponsiveTypography variant="body1">
                  Purohit will perform the puja as per Vedic rituals and
                  Traditions
                </ResponsiveTypography>
              </Item>
            </Box>
          </Grid>
        )}

        {isMobile && (
          <Grid item xs={5} sm={2} sx={{ paddingLeft: "unset!important" }}>
            <Box display="flex" alignItems="center">
              <Item
                sx={{
                  width: {
                    sm: "140px",
                    md: "175px",
                    lg: "250px",
                  },
                  height: {
                    sm: "160px",
                    md: "215px",
                    lg: "195px",
                  },
                }}
              >
                <ImageIcon src={PrasadamIcon} alt="Give Gotra Names" />
                <ResponsiveTypography variant="body1">
                  After Puja Prasad will be packed and shipped to your address
                </ResponsiveTypography>
              </Item>
            </Box>
          </Grid>
        )}

        <DividerHorizontal sx={{ display: { xs: "block", md: "block" } }} />

        {!isMobile && (
          <Grid item xs={5} sm={2} sx={{ paddingLeft: "unset!important" }}>
            <Box display="flex" alignItems="center">
              <Item
                sx={{
                  width: {
                    sm: "140px",
                    md: "175px",
                    lg: "250px",
                  },
                  height: {
                    sm: "160px",
                    md: "215px",
                    lg: "195px",
                  },
                }}
              >
                <ImageIcon src={PrasadamIcon} alt="Give Gotra Names" />
                <ResponsiveTypography variant="body1">
                  After Puja Prasad will be packed and shipped to your address
                </ResponsiveTypography>
              </Item>
            </Box>
          </Grid>
        )}

        {isMobile && (
          <Grid item xs={5} sm={2} sx={{ paddingLeft: "unset!important" }}>
            <Box display="flex" alignItems="center">
              <Item
                sx={{
                  width: {
                    sm: "140px",
                    md: "175px",
                    lg: "250px",
                  },
                  height: {
                    sm: "160px",
                    md: "215px",
                    lg: "195px",
                  },
                }}
              >
                <ImageIcon src={DeliveryIcon} alt="Give Gotra Names" />
                <ResponsiveTypography variant="body1">
                  Purohit will perform the puja as per Vedic rituals and
                  Traditions
                </ResponsiveTypography>
              </Item>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Process;

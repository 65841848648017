
import React from 'react'
import BlogSection from '../components/common/BlogSection'
import Header from '../components/common/Header';
import Footer from './Footer';

const BlogPage = () => {
    const ns = "app-page-home";

  return (
    <>
        <div className={`${ns}`}>
          <div className={`${ns}__header`}>
            <Header />
          </div>
          <div style={{ marginTop: "200px" }}>
            <BlogSection />
          </div>
            <Footer />
        </div>
    </>
  )
}

export default BlogPage
import { CircularProgress } from "@mui/material";
import Header from "../components/common/Header";
import PaymentCancelled from "../images/PaymentCancelled.jpg";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import instance from "../utils/Api";
import api from "../config/backend";
import axios from "axios";
import ganesh from "../images/confirmation/lord-ganesh.svg";
import { Button, Box } from "@mui/material";

const BookingSuccessful = () => {
  const [cartItems, setCartItems] = useState(undefined);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { order_id } = useParams();

  const fetchCart = async () => {
    const response = await axios.get(`${api}/api/cart`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        paginate: "0",
      },
    });
    setCartItems(response.data.data);
  };

  const deleteCartItem = async (id) => {
    await axios.delete(`${api}/api/cart/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };

  useEffect(() => {
    fetchCart();
  }, []);

  useEffect(() => {
    if (cartItems && cartItems.data) {
      for (const item of cartItems.data) {
        deleteCartItem(item.id);
      }
    }

    // setTimeout(() => {
    //     navigate("/")
    // }, 300000)
  }, [cartItems]);

  return (
    <div className="_6kln">
      <Header />
      <div
        className="amylase-mil"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fef9f3",
        }}
      >
        <div className="welcome-back-content" style={{ marginTop: "200px" }}>
          <img src={ganesh} />
          <h4>Booking is Successful</h4>
          <h5>We have successfully received your details</h5>
          <p>
            You will get a confirmation call or WhatsApp within 12 hrs of
            booking
          </p>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px 0",
              width: "100%",
            }}
          >
            <Button
              onClick={() => navigate("/")}
              variant="contained"
              disableRipple
              disableElevation
              sx={{
                borderRadius: "8px",
                background: "#ff5b00",
                color: "#fff",
                fontFamily: "Objectivity",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                padding: "15px 20px",
                textTransform: "capitalize",
                width: {
                  xs: "92%",
                  sm: "344px",
                },
                "&:hover": {
                  background: "#ff5b00",
                },
              }}
            >
              <span>Return to Home</span>
            </Button>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingBottom: "20px",
            }}
          >
            <Link to={`/invoice/${order_id}`}>
              <Button
                onClick={() => navigate("/")}
                variant="contained"
                disableRipple
                disableElevation
                sx={{
                  borderRadius: "8px",
                  background: "#ff5b00",
                  color: "#fff",
                  fontFamily: "Objectivity",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                  padding: "15px 20px",
                  textTransform: "capitalize",
                  width: {
                    xs: "92%",
                    sm: "344px",
                  },
                  "&:hover": {
                    background: "#ff5b00",
                  },
                }}
              >
                <span>View Invoice Copy</span>
              </Button>
            </Link>
          </Box> */}
        </div>
        {/* <CircularProgress />  {/*
        {/* <div style={{ marginTop: "50px", fontSize: "12px", color: "grey" }}>
            Redirecting to home
          </div> */}
      </div>
    </div>
  );
};

export default BookingSuccessful;

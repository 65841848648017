import React, { useEffect, useState } from 'react'
import Invoice from "../components/common/Invoice";
import axios from 'axios';
import api from '../config/backend';


const InvoicePage = () => {
    const token = localStorage.getItem("token");
    const [bookedData, setBookedData] = useState([])
    const [error, setError] = useState("")
    const [profileData, setProfileData] = useState([])


    const invoiceData = {
        items: [
          { name: 'Anandanam', qty: 1, rate: 599, tax: 0, discount: '5%', subtotal: 550 },
          { name: 'Anandanam', qty: 2, rate: 1198, tax: 0, discount: '5%', subtotal: 1180 },
        ],
    };

    useEffect(() => {
        fetchProfileData();
        fetchBookedData()
    }, [])


    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`${api}/api/user/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const name = response?.data?.data?.fname;
        localStorage.setItem("name", name);
        console.log('response profile in invoice:', response?.data?.data)
        setProfileData(response?.data?.data);
      } catch (err) {
        setError(err);

        console.log(err);
      }
    };

    const fetchBookedData = async () => {
      try {
        const response = await axios.get(`${api}/api/bookings`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        console.log("response in booking:", response?.data?.data?.data);
        const name = response.data.data.fname;
        localStorage.setItem("name", name);

        const allBookings = response.data.data.data;

        const successfulBookings = allBookings.filter(
          (booking) => booking.payment_status === "Success"
        );

        const orderSevas = successfulBookings.filter(order => {
            return order?.order_sevas?.[0];
        })

        setBookedData(orderSevas);
        console.log(successfulBookings);
      } catch (err) {
        setError(err);

        console.log(err);
      }
    };

  return (
    <>
        <Invoice invoiceData={invoiceData} profileData={profileData} setProfileData={setProfileData} bookedData={bookedData} />
    </>
  )
}

export default InvoicePage
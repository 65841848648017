import React from "react";
import { Box, Typography } from "@mui/material";
import starImage from "../../images/banner/star1.svg";
import starEmptyImage from "../../images/banner/star_empty.svg";
import starHalfImage from "../../images/banner/star_half.svg"; // Add an image for half stars

const StarBackground = ({ numberOfStars, rating }) => {
  const stars = Array.from({ length: numberOfStars }, (_, index) => {
    if (index < rating - 0.5) return "full";
    if (index < rating) return "half";
    return "empty";
  });

  return (
    <Box
      sx={{
        overflow: "hidden",
        margin: "12px 0",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: {
            xs: "6px",
            sm: "10px",
            md: "12px",
          },
          marginRight: "10px",
          alignItems: "center",
        }}
      >
        {stars.map((starType, index) => (
          <Box
            key={index}
            sx={{
              backgroundImage: `url(${
                starType === "full"
                  ? starImage
                  : starType === "half"
                    ? starHalfImage
                    : starEmptyImage
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              width: "20px",
              height: "20px",
            }}
          />
        ))}
      </Box>
      <Typography
        sx={{
          fontFamily: "Objectivity",
          fontSize: {
            xs: "14px",
            sm: "15px",
          },
          fontWeight: "400",
          textAlign: "left",
          lineHeight: "26px",
          display: "flex",
          alignItems: "center",
        }}
      >
        4.7
      </Typography>
    </Box>
  );
};

export default StarBackground;

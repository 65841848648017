import React from 'react';
import { Grid, Box, Typography, Button, styled, Container } from '@mui/material';
import BannerImage from '../../images/discover-more/mandala.svg';

const BlogImage = styled('img')({
  width: '100%',
  height: 'auto',
  borderRadius: '10px',
  objectFit: 'cover',
});

const BlogTitle = styled(Typography)`
  font-family: 'Objectivity';
  font-size: 21px;
  font-weight: 700;
  line-height: 30.84px;
  text-align: left;
  color: #222222;
  margin-bottom: 8px;

  @media (max-width: 600px) {
    font-size: 17px;
    line-height: 23px;
  }
`;


const BlogText = styled(Typography)`
  font-family: 'Objectivity';
  font-size: 17px;
  font-weight: 400;
  line-height: 30.99px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #505050;
  margin-bottom: 16px;

  @media (max-width: 600px) {
    font-size: 13px;
    line-height: 23px;
  }
`;

const SectionTitle = styled(Typography)`
  display: flex;
  align-items: center;
  font-family: 'Objectivity';
  font-size: 27px;
  font-weight: 700;
  line-height: 32.96px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #2C2C2C; 
  padding-bottom: 40px;
  border-radius: 8px; 

  &:after {
    content: '';
    flex-grow: 1;
    margin-left: 80px; 
    border-bottom: 2px solid #FF5B00;
  }


    @media (max-width: 600px) {
      &:after {
        margin-left: 40px;
      }
      font-size: 23px;
      
    }


`;




const blogData = [
  {
    title: 'Perform Thila Homam to Get Rid of Pitru Doshas...',
    content: 'Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences.'
  },
  {
    title: 'How to Prepare for the Sacred Thila Homam Ceremony',
    content: 'Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences.'
  },
  {
    title: 'Benefits of Performing Thila Homam',
    content: 'Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences.'
  },
  {
    title: 'Benefits of Performing Thila Homam...',
    content: 'Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences.'
  },
  {
    title: 'Benefits of Performing Thila Homam',
    content: 'Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences. Lorem Ipsum is a dummy text to fill the sentences.'
  }
];

const BlogSection = () => {
  return (
    <Container>
      <SectionTitle variant="h3">Our Blogs</SectionTitle>
      <Grid container spacing={3}>
      {blogData.map((blog, index) => (
        <Grid item xs={12} sm={6} md={6} key={index}>
          <Box sx={{ overflow: 'hidden', paddingBottom: '30px'  }}>
            <Grid container spacing={3}>
              <Grid item xs={5} sm={4} md={4}>
                <BlogImage src={BannerImage} alt="Blog Post" />
              </Grid>
              <Grid item xs={7} sm={8} md={8}>
                <BlogTitle variant="h6">{blog.title}</BlogTitle>
                <BlogText variant="body2">{blog.content.slice(0,300) + "..."}</BlogText>
                <Button
                  variant="contained"
                  disableRipple
                  disableElevation
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '10px',
                    padding: '10px 20px',
                    color: 'white',
                    fontFamily: 'Objectivity',
                    fontSize: '15px',
                    fontWeight: 500,
                    textTransform: 'unset',
                    backgroundColor: '#ff5b00',
                    whiteSpace: 'nowrap',
                    '&:hover': {
                      backgroundColor: '#ff7b33',
                    },
                  }}
                >
                  <span>Explore All</span>
                  <div style={{ width: '20px', height: '20px' }}>
                    <img
                      className="app-btn-img"
                      src="/images/icons/arrow-right.svg"
                      alt="arrow"
                      style={{
                        width: '100%',
                        height: '100%!important',
                      }}
                    />
                  </div>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
    </Container>
  );
};

export default BlogSection;

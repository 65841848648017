import { useEffect, React, useState } from "react";
import instance from "../utils/Api";
import {
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  useMediaQuery,
  Box,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Button, Container, Typography, FormLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import cartimage from "../images/cart/cart-image.svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import TextField from "@mui/material/TextField";
import { Link, useNavigate, useParams } from "react-router-dom";
import HorizontalMulAccordion from "../components/common/HorizontalMulAccordion";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import CustomizedDialogs3 from "./CustomizedDialogs3";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import leaf from "../images/confirmation/flower.svg";
import ganesh from "../images/confirmation/lord-ganesh.svg";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useCart } from "../pages/CartContext";
import api from "../config/backend";
import axios from "axios";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import PencilIcon from "../images/champaign/pencil_icon.svg";

function NonLinearHorizontalStepper({ triggerRefresh, setTriggerRefresh }) {
  const token = localStorage.getItem("token");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhoneNo] = useState("");
  const [whatsup_no, setWhatsupNo] = useState("");
  const [country_id, setCountryId] = useState(1);
  const [state_id, setStateId] = useState("1");
  const [city_id, setCityId] = useState("1");
  const [address_1, setAddress1] = useState("");
  const [address_2, setAddress2] = useState("");
  const [pincode, setPincode] = useState("");
  const [address_name, setAddressName] = useState("");
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [stupidity, setStupidity] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [selectedFamily, setSelectedFamily] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);

  const [isSelectedFamily, setIsSelectedFamily] = useState(undefined);
  const isMobile = useMediaQuery("(max-width: 767px)");
  const [isMulKartaSelected, setIsMulKartaSelected] = useState(false);
  const [isMulAncestorSelected, setIsMulAncestorSelected] = useState(false);
  const [isKartaSelectedList, setIsKartaSelectedList] = useState({});
  const [isAncestorSelectedList, setIsAncestorSelectedList] = useState({});
  const [isSelfSelectedList, setIsSelfSelectedList] = useState({});
  const [popUp, setPopUp] = useState(false);
  const [familycheck, setFamilyCheck] = useState([]);

  const { decrementCartTotal } = useCart();
  const [cartData, setCartData] = useState([]);
  const [error, setError] = useState(null);
  const [familyData, setFamilyData] = useState([]);

  const [profiledata, setProfiledata] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [profileData, setProfileData] = useState({});
  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const [emailError, setEmailError] = useState({
    email: false,
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({
    fname: false,

    email: false,
    phone_no: false,
    whatsup_no: false,
    state_id: false,
    city_id: false,
    address_1: false,
    pincode: false,
    address_name: false,
  });
  const [otherAddressName, setOtherAddressName] = useState("");
  const [addressNameOptions, setAddressNameOptions] = useState([
    { id: "home", name: "Home" },
    { id: "office", name: "Office" },
    { id: "other", name: "Other" },
  ]);
  const [addresses, setAddresses] = useState([]);
  const navigate = useNavigate();

  const handleSelectAddress = (addressId) => {
    setSelectedAddressId(addressId);
  };

  const handleClickOpen = () => {
    // setOpen(false)
    setEditProfileOpen(true);
    setIsEditing(true);
  };

  const [otp, setOtp] = useState("");

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const validateForm = () => {
    const errors = {};
    const nameRegex = /^[A-Za-z]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[6-9]\d{9}$/;

    if (!fname || !nameRegex.test(fname)) errors.fname = true;
    if (!email || !emailRegex.test(email)) errors.email = true;
    if (!phone_no || !phoneRegex.test(phone_no)) errors.phone_no = true;
    if (!whatsup_no || !phoneRegex.test(whatsup_no)) errors.whatsup_no = true;
    if (!selectedState) errors.selectedState = true;
    if (!selectedCity) errors.selectedCity = true;
    if (!address_1) errors.address_1 = true;
    if (!pincode) errors.pincode = true;
    if (address_name === "other" && !otherAddressName)
      errors.otherAddressName = true;

    setFieldErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      return false;
    }

    return true;
  };

  const handleSaveAddress = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const postData = {
        fname,
        lname,
        email,
        phone_no,
        whatsup_no,
        country_id: 1,
        state_id: selectedState,
        city_id: selectedCity,
        address_1,
        address_2,
        pincode,
        address_name:
          address_name === "other" ? otherAddressName : address_name,
        // address_name,
      };

      if (isEditMode && currentAddress) {
        const updatedFields = {};
        Object.keys(postData).forEach((key) => {
          if (postData[key] !== currentAddress[key]) {
            updatedFields[key] = postData[key];
          }
        });

        Object.keys(updatedFields).forEach((key) => {
          if (updatedFields[key] === undefined) {
            delete updatedFields[key];
          }
        });

        console.log("updated fields:", updatedFields);

        if (Object.keys(updatedFields).length === 0) {
          alert("Please atleast update 1 field");
          return;
        }

        const response = await axios.put(
          `${api}/api/address/${currentAddress?.id}`,
          updatedFields,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setAddresses((prevAddresses) =>
          prevAddresses.map((addr) =>
            addr?.id === currentAddress?.id ? response?.data?.data : addr
          )
        );
      } else {
        const response = await axios.post(`${api}/api/address`, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setAddresses((prevAddresses) => [...prevAddresses, response.data.data]);
      }

      setFname("");
      setLname("");
      setEmail("");
      setPhoneNo("");
      setWhatsupNo("");
      setCountryId("");
      setStateId("");
      setCityId("");
      setAddress1("");
      setAddress2("");
      setPincode("");
      setAddressName("");
      // fetchData();
      setTriggerRefresh((prev) => prev + 1);
      // window.location.reload();
      handleCloseAddAddressModal();
    } catch (error) {
      console.error("Error saving address:", error);
    }
  };

  const { productId } = useParams();
  const [activeStep, setActiveStep] = useState(0);

  const steps = ["Information", "Complete "];
  const { cartTotal, setCartTotal, setCartCountRefresh } = useCart();

  const handleStepClick = (step) => () => {
    if (familyData && familyData.length) {
      setActiveStep(step);
    }
    if (address_1 && address_1.length >= 2) {
      console.log("karta should be one");
    }
  };

  const hasKarta = familyData.some(
    (familyMember) => familyMember.family_type === "kartha"
  );
  const hasAncestor = familyData.some(
    (familyMember) => familyMember.family_type === "ancestors"
  );

  const isKartaAndAncestor = hasKarta && hasAncestor;

  console.log(isKartaAndAncestor, "isKartaAndAncestor");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api}/api/cart`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            paginate: "0",
          },
        });

        // console.log('response inside horizontal stepper cart:', response?.data)
        setCartData(response.data.data);
        setError(null);
      } catch (err) {
        setError(err);
        // console.error(err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (cartData && cartData.data) {
      // console.log('updated in check', selectedFamily);
      let selectedFamilyIds = [];
      let selectedKeys = [];

      if (selectedFamily && selectedFamily.length > 0) {
        selectedFamilyIds = selectedFamily.map((member) => member.id);
        // console.log('Selected Family IDs:', selectedFamilyIds);
      }

      if (Array.isArray(isSelectedFamily)) {
        selectedKeys = isSelectedFamily.map((obj) => Object.keys(obj)[0]);
        // console.log(selectedKeys);
      }

      const updatedStupidity = cartData.data.map((item) => {
        return {
          seva_id: item.seva_id,
          seva_price_id: item.seva_price_id,
          is_prasadam_available: item.is_prasadam_available,
          user_family_detail_id: selectedKeys,
        };
      });

      setStupidity(updatedStupidity);
    }
  }, [cartData, familyData, selectedFamily, isSelectedFamily]);

  console.log("cartdata--", cartData?.data?.id);

  useEffect(() => {
    if (cartData && cartData.data && isSelectedFamily === undefined) {
      setIsSelectedFamily(
        cartData.data.map((item) => {
          const studified = {};

          studified[`${item.id}`] = false;
          // console.log(item)
          return studified;
        })
      );
    }
  }, [cartData]);
  // console.log(isSelectedFamily)
  // console.log(stupidity)

  const handleCheckout = async () => {
    console.log("isSelectedFamily:", isSelectedFamily);
    if (isSelectedFamily === undefined) {
      alert(
        "Please select at least one family member before proceeding to checkout."
      );
      return;
    }

    let isallchecked = false;

    isSelectedFamily.forEach((item) => {
      isallchecked = false;
      const values = Object.values(item);

      if (values.every((value) => value === true)) {
        isallchecked = true;
      }
    });

    if (isValidSelection === false) {
      console.log("isSelectedFamily isallchecked:", isallchecked);
      console.log("isSelectedFamily false:", isSelectedFamily);
      alert(
        "Please select at least one family member before proceeding to checkout."
      );
      return;
    }
    if (!selectedAddressId) {
      alert("Please select a delivery address before proceeding to checkout.");
      // console.log("No address selected. Exiting handleCheckout.");
    }

    if (stupidity) {
      try {
        const { data, status } = await axios.post(
          `${api}/api/bookings`,
          {
            cart: stupidity,
            shipping_user_address_id: selectedAddressId,
            billing_user_address_id: selectedAddressId,
            original_price: calculateTotal(),
            final_paid_amount: calculateTotal(),
            is_from_cart: 1,
          },

          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (data.success) {
          const checkoutlink = data.data.checkout_url;
          // console.log(data)

          if (checkoutlink) {
            window.location.href = checkoutlink;
          }
        }
      } catch (error) {
        console.error("Error during checkout:", error);
      }
    } else {
      console.error("Cart data (stupidity) is not defined");
    }
  };

  // console.log(cartTotal)
  // console.log(familyData)

  // console.log(stupidity)

  const removeItemFromCart = async (id) => {
    console.log("cart Id", id);
    try {
      await axios.delete(`${api}/api/cart/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setCartData((prevCartData) => {
        decrementCartTotal();
        const updatedCartData = prevCartData.data.filter(
          (item) => item.id !== id
        );
        return { ...prevCartData, data: updatedCartData };
      });

      if (cartData.data.length === 1) {
        navigate("/");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const calculateTotal = () => {
    let total = 0;
    if (Array.isArray(cartData.data)) {
      cartData.data.forEach((item) => {
        total += item.seva_price.selling_price;
      });
    }
    return total;
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get(`${api}/api/address`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            paginate: "0",
          },
        });
        // console.log(response.data.data.data)
        setAddresses(response.data.data.data);
        setTriggerRefresh((prev) => prev + 1);
        setError(null);
      } catch (err) {
        setError(err);
        // console.error(err);
      }
    };

    fetchAddresses();
  }, []);

  const [selectedState, setSelectedState] = useState("");
  const [citiesInSelectedState, setCitiesInSelectedState] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  const fetchCityData = async () => {
    try {
      const response = await axios.get(`${api}/api/city`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.success === 1) {
          const cityData = responseData.data.data;
          setCityData(cityData);
          // console.log('City Data:', cityData);
        } else {
          // console.error('API Error:', responseData.message);
        }
      } else {
        // console.error('API Error: Unexpected status code', response.status);
      }
    } catch (error) {
      // console.error('API Error:', error.message);
    }
  };
  const fetchStateData = async () => {
    try {
      const response = await axios.get(`${api}/api/states`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.success === 1) {
          const StateDataa = responseData.data.data;
          setStateData(StateDataa);
          // console.log('City Data:', StateDataa);
        } else {
          // console.error('API Error:', responseData.message);
        }
      } else {
        // console.error('API Error: Unexpected status code', response.status);
      }
    } catch (error) {
      // console.error('API Error:', error.message);
    }
  };

  useEffect(() => {
    fetchCityData();
    fetchStateData();
  }, []);
  useEffect(() => {
    if (selectedState) {
      const cities = cityData.filter((city) => city.state_id === selectedState);
      setCitiesInSelectedState(cities);
    } else {
      setCitiesInSelectedState([]);
    }
  }, [selectedState, cityData]);

  const handleCitySelection = (cityId) => {
    // Find the selected city data based on cityId and update the selectedCity state
    const city = cityData.find((city) => city.id === cityId);
    setSelectedCity(city);
  };

  // const handlePlaceOrder = async () => {
  //   try {
  //     const response = await instance.post('api/booking', {
  //       cart: cartData.data,
  //       shipping_user_address_id: shipping_user_address_id,
  //       billing_user_address_id: billing_user_address_id,
  //       coupon_code: coupon_code,
  //       original_price: calculateTotal(),
  //       reward_points: reward_points,
  //       extra_charges: extra_charges,
  //       coupon_amount: coupon_amount,
  //       final_paid_amount: calculateTotal() + 80,
  //       is_from_cart: 1
  //     });
  //     console.log("Booking successful:", response.data);
  //   } catch (err) {
  //     console.error("Error during booking:", err);
  //   }
  // };

  const [isAddAddressModalOpen, setAddAddressModalOpen] = useState(false);
  const handleOpenAddAddressModal = () => {
    setAddAddressModalOpen(true);
  };

  const handleCloseAddAddressModal = () => {
    setAddAddressModalOpen(false);
    setAddAddressModalOpen(false);
    setIsEditMode(false);
    setCurrentAddress(null);
    setFname("");
    setLname("");
    setEmail("");
    setPhoneNo("");
    setWhatsupNo("");
    setSelectedState("");
    setSelectedCity("");
    setCountryId("");
    setStateId("");
    setCityId("");
    setAddress1("");
    setAddress2("");
    setPincode("");
    setAddressName("");
    setFieldErrors({
      fname: false,

      email: false,
      phone_no: false,
      whatsup_no: false,
      state_id: false,
      city_id: false,
      address_1: false,

      pincode: false,
      address_name: false,
    });
  };

  const handleCheckboxChange = (e, index) => {
    const updatedCartData = [...cartData.data];
    updatedCartData[index].is_prasadam_available = e.target.checked;
  };

  // Todo
  const handleKartaSelectedList = (index, obj) => {
    setIsKartaSelectedList((prevStates) => {
      const newStates = { ...prevStates, [index]: obj };
      console.log("newStates karta:", newStates[index]);
      return newStates;
    });
  };

  const handleAncestorSelectedList = (index, obj) => {
    setIsAncestorSelectedList((prevStates) => {
      const newStates = { ...prevStates, [index]: obj };
      console.log("newStates Ancestor:", newStates[index]);
      return newStates;
    });
  };

  const handleSelfSelectedList = (index, obj) => {
    setIsSelfSelectedList((prevStates) => {
      const newStates = { ...prevStates, [index]: obj };
      console.log("newStates Self:", newStates[index]);
      return newStates;
    });
  };

  console.log("ancestor list:", isAncestorSelectedList);
  console.log("karta list:", isKartaSelectedList);
  console.log("isSelfSelected list:", isSelfSelectedList);

  const selectedKartaCount = Object.values(isKartaSelectedList).every(
    (num) => num === 1
  );

  const selectedAncestorCount = Object.values(isAncestorSelectedList).every(
    (num) => num > 0
  );

  const selectedSelfCount = Object.values(isSelfSelectedList).every(
    (num) => num > 0
  );

  // console.log("selectedKartaCount:", selectedKartaCount);
  // console.log("selectedAncestorCount:", selectedAncestorCount);
  console.log("selectedSelfCount:", selectedSelfCount);

  // if(cartData.data.length)

  console.log(
    "isAncestorSelectedList:",
    Object.keys(isAncestorSelectedList).length
  );

  let kartaObjLength = Object.keys(isKartaSelectedList)?.length;
  let ancestorObjLength = Object.keys(isAncestorSelectedList)?.length;
  let selfObjLength = Object.keys(isSelfSelectedList)?.length;

  let isValidSelection;
  if (kartaObjLength && ancestorObjLength === cartData.data.length) {
    isValidSelection = selectedKartaCount && selectedAncestorCount;
  }

  let isSelfValidSelection;
  if (selfObjLength === cartData?.data?.length) {
    isSelfValidSelection = selectedSelfCount;
  }

  console.log("isValidSelection", isValidSelection);
  console.log("isSelfValidSelection", isSelfValidSelection);

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(`${api}/api/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });

      const name = response.data.data.fname;
      localStorage.setItem("name", name || "");
      setProfileData(response.data.data);
    } catch (err) {
      console.error("Failed to fetch profile data:", err);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, [triggerRefresh]);

  useEffect(() => {
    if (profileData) {
      setFirstName(profileData.fname || "");
      setEmail(profileData.email || "");
      setPhone(profileData.mobile_number || "");
    }
  }, [profileData]);

  const handleClose = () => {
    // setOpen(false)
    setEditProfileOpen(false);
    setEmailError({
      email: false,
    });
  };

  const validateEmail = () => {
    if (email.trim() !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(email);
      setEmailError({ email: !isValid });
      return isValid;
    }
    setEmailError({ email: false });
    return true;
  };

  const saveProfileData = async () => {
    window.fbq("track", "InputData");
    if (!validateEmail()) {
      return;
    }
    try {
      const updatedProfileData = {
        fname: firstName,
        mobile_number: phone,
        email,
      };
      const response = await axios.put(
        `${api}/api/user/profile`,
        updatedProfileData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setProfileData(response.data.data);
        setIsEditing(false);
        handleClose();
        setTriggerRefresh((prev) => prev + 1);
      } else {
        console.error("Failed to update profile data");
      }
    } catch (error) {
      console.error("Failed to update profile data:", error);
    }
  };

  const handleOpenEditAddressModal = (address) => {
    setIsEditMode(true);
    setCurrentAddress(address);
    setAddAddressModalOpen(true);

    setFname(address?.fname);
    setLname(address?.lname);
    setEmail(address?.email);
    setPhoneNo(address?.phone_no);
    setWhatsupNo(address?.whatsup_no);
    setSelectedState(address.state_id);
    setSelectedCity(address.city_id);
    setCountryId(address?.country_id);
    setStateId(address?.state_id);
    setCityId(address?.city_id);
    setAddress1(address?.address_1);
    setAddress2(address?.address_2);
    setPincode(address?.pincode);
    setAddressName(
      address?.address_name !== "home" && address?.address_name !== "office"
        ? "other"
        : address?.address_name
    );
    // if(otherAddressName === "other"){
    //   setOtherAddressName(address?.address_name);
    // }
  };

  const formatAddress = (addrStr) => {
    return addrStr.filter(Boolean).join(", ");
  };

  const handleAddressNameChange = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "other") {
      // If "Other" is chosen, reset otherAddressName
      setOtherAddressName("");
    }

    setAddressName(selectedValue);
  };

  const handleClosePop = () => {
    setPopUp(false);
  };

  console.log(popUp, "popup");

  return (
    <div>
      {/* Content for each step */}
      <div>
        {steps.map((label, index) => (
          <div
            className="click"
            key={label}
            style={{ display: index === activeStep ? "block" : "none" }}
          >
            {/* Add content for each step */}
            {index === 0 && (
              <Container>
                <div className="add-to-cart add-to-cart-2">
                  <div className="add-to-cart-content">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <div className="add-to-cart-box add-to-cart-box11">
                          <div className="top-stepper">
                            <Stepper activeStep={activeStep} alternativeLabel>
                              {steps.map((label, index) => (
                                <Step key={label}>
                                  <StepLabel onClick={handleStepClick(index)}>
                                    {label}
                                  </StepLabel>
                                </Step>
                              ))}
                            </Stepper>
                          </div>
                          <Typography variant="h5">
                            Your Order <span>({cartTotal} items)</span>
                          </Typography>
                          <div
                            style={{
                              background: "#fff",
                              boxShadow: "0px 4px 15px 0px #0000000D",
                              borderRadius: "15px",
                            }}
                          >
                            {cartData &&
                              cartData.data &&
                              Array.isArray(cartData.data) &&
                              cartData.data.length > 0 ? (
                              cartData.data.map((item, index) => (
                                <>
                                  {/* <div>
                                  <div className="add-to-cart-box-2">
                                    <img src={cartimage} />
                                    <div className="add-to-card-seva">
                                      <div className="add-to-card-seva-content">
                                        <Typography variant="h4">
                                          Seva Name - {item.seva.title}
                                        </Typography>
                                        {item.seva_price.family_type ? <div style={{ backgroundColor: "grey", borderRadius: "8px", padding: "5px 10px" }}>{item.seva_price.family_type}</div> : undefined}

                                        <p>{new Date(item.seva.start_date).toDateString()}</p>
                                        <Typography variant="h5">
                                          Event Type - <span>{productDetails.event}</span>
                                        </Typography>
                                        <Typography>
                                          <input
                                            type="checkbox"
                                            id=""
                                            name=""
                                            value=""
                                            style={{ display: item.is_prasadam_available ? "block" : "none" }}
                                            checked={item.is_prasadam_available}
                                            onChange={(e) => handleCheckboxChange(e, index)}
                                          />
                                          <label htmlFor="">
                                            {item.is_prasadam_available ? "Prasadam" : ""}
                                          </label>
                                        </Typography>
                                      </div>
                                      <div className="add-to-card-seva-content-2">
                                        <h4>₹{item.seva_price.selling_price}</h4>
                                        <div className="remove-btn">
                                          <Button
                                            onClick={() => removeItemFromCart(item.id)}
                                          >
                                            Remove
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div> */}

                                  <div className="add-to-cart-box">
                                    <div className="add-to-cart-box-content">
                                      <div className="add-to-cart-box-main-content">
                                        {/* <div className="cart-image">
                                          <img src={cartimage}></img>
                                          <div
                                            className="delete_dustbin"
                                            onClick={() => removeItemFromCart()}
                                          ></div>
                                        </div> */}
                                        <div className="add-to-cart-box-mini-content app-new-sm-cart-box">
                                          <div>
                                            <h4>{item?.seva_price?.title}</h4>
                                            <p>
                                              {item?.seva?.start_date
                                                ? new Date(
                                                  item?.seva?.start_date
                                                ).toDateString()
                                                : ""}
                                            </p>
                                            <h6>
                                              Puja Variant -&nbsp;
                                              <span
                                                style={{ color: "#FF5B00" }}
                                              >
                                                01 Devotee
                                              </span>
                                            </h6>
                                          </div>
                                          {isMobile ? (
                                            <div className="add-to-cart-price">
                                              <h4>
                                                {true ? "₹" : "$"}
                                                {
                                                  item?.seva_price
                                                    ?.selling_price
                                                }
                                              </h4>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      {!isMobile ? (
                                        <div className="add-cart-price-remove-btn">
                                          <div className="add-to-cart-price">
                                            <h4>
                                              {true ? "₹" : "$"}
                                              {item?.seva_price?.selling_price}
                                            </h4>
                                          </div>
                                          <div className="remove-btn">
                                            <Button
                                              onClick={() =>
                                                removeItemFromCart()
                                              }
                                            >
                                              Remove
                                            </Button>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="">
                                    <HorizontalMulAccordion
                                      familyData={familyData}
                                      setFamilyData={setFamilyData}
                                      selectedFamily={selectedFamily}
                                      setSelectedFamily={setSelectedFamily}
                                      isSelectedFamily={isSelectedFamily}
                                      seva={item}
                                      setIsSelectedFamily={setIsSelectedFamily}
                                      setFamilyCheck={setFamilyCheck}
                                      familycheck={familycheck}
                                      handleCheckout={handleCheckout}
                                      onKartaSelectedList={
                                        handleKartaSelectedList
                                      }
                                      onAncestorSelectedList={
                                        handleAncestorSelectedList
                                      }
                                      onSelfSelectedList={
                                        handleSelfSelectedList
                                      }
                                      cartId={item.id}
                                    />
                                  </div>
                                </>
                              ))
                            ) : (
                              <div className="no-items-in-cart">
                                No items in checkout
                              </div>
                            )}

                            <div
                              className="chech-box-panel-main chech-box-panel-main-2 custom-edit-profile check-out-panel"
                              style={{ borderTop: "unset" }}
                            >
                              <Accordion defaultExpanded>
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon
                                      sx={{
                                        color: "#FF5B00",
                                        fontSize: "30px",
                                      }}
                                    />
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <div className="add-family-content">
                                    <h5>Personal Information</h5>
                                    <div className="user-details">
                                      <span>{profileData.fname || "null"}</span>
                                    </div>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails className="_8dah">
                                  <span>
                                    {profileData.mobile_number || "N/A"}
                                  </span>
                                  <div className="chechout-checkbox-details">
                                    <div className="checkout-label-details checkout-label-details-2">
                                      <Button onClick={handleClickOpen}>
                                        <span>
                                          <AddIcon />
                                        </span>{" "}
                                        Edit Profile
                                      </Button>
                                    </div>
                                    <Dialog
                                      open={editProfileOpen}
                                      onClose={handleClose}
                                      fullWidth
                                      maxWidth="md"
                                    >
                                      <DialogContent sx={{ padding: "20px" }}>
                                        <Typography variant="h6">
                                          Profile Details
                                        </Typography>
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} sm={6}>
                                            <FormLabel>Full Name</FormLabel>
                                            <TextField
                                              type="text"
                                              id="fname"
                                              value={firstName}
                                              onChange={(e) =>
                                                setFirstName(e.target.value)
                                              }
                                              fullWidth
                                              disabled={!isEditing}
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <FormLabel>Phone Number</FormLabel>
                                            <TextField
                                              type="text"
                                              id="phone"
                                              value={phone}
                                              fullWidth
                                              disabled={!isEditing}
                                              onChange={(e) =>
                                                setPhone(e.target.value)
                                              }
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <FormLabel>Email</FormLabel>
                                            <TextField
                                              type="text"
                                              id="email"
                                              value={email}
                                              onChange={(e) =>
                                                setEmail(e.target.value)
                                              }
                                              error={emailError.email}
                                              fullWidth
                                              disabled={!isEditing}
                                            />
                                          </Grid>
                                        </Grid>
                                        <div
                                          className="myfamily-forms-btn"
                                          style={{ marginTop: "20px" }}
                                        >
                                          <Button
                                            onClick={saveProfileData}
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                              backgroundColor: "transparent",
                                              "&:hover": {
                                                backgroundColor: "transparent",
                                              },
                                              "&:focus": {
                                                backgroundColor: "transparent",
                                                outline: "none",
                                              },
                                              "&:active": {
                                                backgroundColor: "transparent",
                                              },
                                            }}
                                          >
                                            Save
                                          </Button>
                                        </div>
                                      </DialogContent>
                                    </Dialog>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </div>

                            {isMobile && (
                              <Box
                                sx={{
                                  width: "100%",
                                  textAlign: "center",
                                  padding: "24px 18px",
                                }}
                              >
                                {familycheck.length > 0 ? (
                                  <Button
                                    className="_0plu1"
                                    variant="outlined"
                                    // Todo
                                    onClick={() => {
                                      if (isKartaAndAncestor) {
                                        if (isValidSelection) {
                                          setActiveStep(1);
                                        } else {
                                          setPopUp(true);
                                        }
                                      } else {
                                        if (
                                          !isSelfValidSelection ||
                                          isSelfValidSelection === undefined
                                        ) {
                                          setPopUp(true);
                                        } else {
                                          setActiveStep(1);
                                        }
                                      }
                                    }}
                                    fullWidth
                                  >
                                    Proceed to Pay
                                    <ChevronRight sx={{ fontSize: "30px" }} />
                                  </Button>
                                ) : (
                                  <Tooltip
                                    title="Please select a family"
                                    placement="bottom"
                                  >
                                    <div>
                                      <Button
                                        className="disabledButton1"
                                        variant="outlined"
                                        disabled
                                        fullWidth
                                      >
                                        Proceed to Pay
                                        <ChevronRight
                                          sx={{ fontSize: "30px" }}
                                        />
                                      </Button>
                                    </div>
                                  </Tooltip>
                                )}
                              </Box>
                            )}

                            {!isMobile && (
                              <>
                                {familycheck.length > 0 ? (
                                  <Button
                                    sx={{ margin: "0 20px!important" }}
                                    className="_0plu"
                                    variant="outlined"
                                    onClick={() => {
                                      if (isKartaAndAncestor) {
                                        if (isValidSelection) {
                                          setActiveStep(1);
                                        } else {
                                          setPopUp(true);
                                        }
                                      } else {
                                        if (
                                          !isSelfValidSelection ||
                                          isSelfValidSelection === undefined
                                        ) {
                                          setPopUp(true);
                                        } else {
                                          setActiveStep(1);
                                        }
                                      }
                                    }}
                                  >
                                    Proceed to Pay
                                    <ChevronRight sx={{ fontSize: "30px" }} />
                                  </Button>
                                ) : (
                                  <Tooltip
                                    title="Please select a family"
                                    placement="bottom"
                                  >
                                    <div>
                                      <Button
                                        className="disabledButton"
                                        variant="outlined"
                                        disabled
                                        sx={{
                                          color: "white!important",
                                          backgroundColor: "lightgray",
                                          margin: "0 20px!important",
                                        }}
                                      >
                                        Proceed to Pay
                                        <ChevronRight
                                          sx={{ fontSize: "30px" }}
                                        />
                                      </Button>
                                    </div>
                                  </Tooltip>
                                )}
                              </>
                            )}

                            {popUp && (
                              <Dialog open={popUp} onClose={handleClosePop}>
                                <DialogContent>
                                  <DialogContentText>
                                    Kartha is the eligible male person in your
                                    family to perform this ritual. You can't
                                    select multiple kartha options!!😓
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={handleClosePop}
                                    className="_0plu"
                                    autoFocus
                                  >
                                    OK
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            )}
                          </div>
                        </div>
                        {/* <div className="add-family-details-btn">
                          <Button disableRipple disableElevationn onClick={handleCheckout} >
                            <VerifiedUserIcon />
                            Place Order ₹{calculateTotal() + 80}
                            <KeyboardArrowRightIcon />
                          </Button>
                        </div> */}
                      </Grid>

                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className="order-summary">
                          {/* <div className="coupons">
                            <div className="coupons-content">
                              <div className="coupons-content-title">
                                <h4>Coupons</h4>
                              </div>
                              <div className="coupons-btn">
                                <Button>
                                  View all
                                  <span>
                                    <img
                                      className="app-btn-img"
                                      src="/images/icons/arrow-right-orange.svg"
                                    />
                                  </span>
                                </Button>
                              </div>
                            </div>
                            <div className="coupons-main-btns">
                              <Button className="coupon-name">
                                {productDetails.sku_code}
                              </Button>
                              <Button>Apply</Button>
                            </div>
                          </div> */}
                          <div className="order-summary-title">
                            <h2>Order Summary</h2>
                          </div>
                          <div className="order-summary-list">
                            <ul>
                              <li className="sub-total">Sub Total</li>
                              <li>₹{calculateTotal()}</li>
                            </ul>
                            {/* <ul>
                              <li className="sub-total">Shipping</li>
                              <li>₹80</li>
                            </ul> */}
                            <ul>
                              <li className="sub-total">Total</li>
                              <li>₹{calculateTotal()}</li>
                            </ul>
                          </div>
                          {/* <div className="proceed-to-pay">
                            <Button
                              variant="contained"
                              className="app-btn app-btn-primary has-icon-end"
                            >
                              <span className="app-btn-text" onClick={handleCheckout}>
                                Proceed To Pay
                              </span>
                              <img
                                className="app-btn-img"
                                src="/images/icons/arrow-right.svg"
                              />
                            </Button>
                          </div> */}

                          {!isMobile && (
                            <>
                              {familycheck.length > 0 ? (
                                <Button
                                  className="_0plu"
                                  variant="outlined"
                                  sx={{
                                    borderRadius: "8px!important",
                                  }}
                                  onClick={() => {
                                    if (isKartaAndAncestor) {
                                      if (isValidSelection) {
                                        setActiveStep(1);
                                      } else {
                                        setPopUp(true);
                                      }
                                    } else {
                                      if (
                                        !isSelfValidSelection ||
                                        isSelfValidSelection === undefined
                                      ) {
                                        setPopUp(true);
                                      } else {
                                        setActiveStep(1);
                                      }
                                    }
                                  }}
                                  fullWidth
                                >
                                  Proceed to Pay
                                  <ChevronRight sx={{ fontSize: "30px" }} />
                                </Button>
                              ) : (
                                <Tooltip
                                  title="Please select a family"
                                  placement="bottom"
                                >
                                  <div>
                                    <Button
                                      className="disabledButton"
                                      variant="outlined"
                                      disabled
                                      fullWidth
                                      sx={{
                                        color: "white!important",
                                        backgroundColor: "lightgray",
                                        borderRadius: "8px!important",
                                      }}
                                    >
                                      Proceed to Pay
                                      <ChevronRight sx={{ fontSize: "30px" }} />
                                    </Button>
                                  </div>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Container>
            )}
            {index === 1 && (
              <Container>
                <div className="add-to-cart add-to-cart-2">
                  <div className="add-to-cart-content">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <div className="add-to-cart-box add-to-cart-box11">
                          <div className="top-stepper">
                            <Button
                              className="_9hyx"
                              variant="outlined"
                              onClick={() => setActiveStep(0)}
                            >
                              <IoMdArrowRoundBack />
                            </Button>
                            <Stepper activeStep={activeStep} alternativeLabel>
                              {steps.map((label, index) => (
                                <Step key={label}>
                                  <StepLabel onClick={handleStepClick(index)}>
                                    {label}
                                  </StepLabel>
                                </Step>
                              ))}
                            </Stepper>
                          </div>
                          <div
                            style={{
                              background: "#fff",
                              padding: "16px",
                              boxShadow: "0px 4px 15px 0px #0000000D",
                              borderRadius: "15px",
                            }}
                          >
                            <Typography variant="h5">
                              Your Order <span>({cartTotal} items)</span>
                            </Typography>

                            <Box
                              sx={{
                                marginBottom: "34px",
                                // paddingBottom: "20px",
                              }}
                              key="uniqueKeyForAllSevas"
                            >
                              <Box
                                sx={{
                                  marginTop: "10px",
                                  border: "2px solid rgba(255, 91, 0, 0.4)",
                                  marginBottom: "20px",
                                  borderRadius: "10px",
                                }}
                              >
                                <Accordion
                                  defaultExpanded
                                  sx={{
                                    boxShadow: "none",
                                    padding: "10px",
                                    background: "transparent",
                                  }}
                                  className="all-seva-accordion"
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <ExpandMoreIcon
                                        sx={{
                                          color: "#FF5B00",
                                          fontSize: "30px",
                                        }}
                                      />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                      paddingLeft: "0 !important",
                                    }}
                                  >
                                    <Box sx={{ width: "100%" }}>
                                      <Typography
                                        component="h3"
                                        sx={{
                                          marginLeft: "10px",
                                          textTransform: "capitalize",
                                          fontFamily: "Objectivity",
                                          fontWeight: {
                                            xs: 700,
                                            sm: 700,
                                          },
                                          lineHeight: {
                                            xs: "16px",
                                            sm: "22.4px",
                                          },
                                          textAlign: "left",
                                          color: {
                                            xs: "#2C2C2C",
                                            sm: "#4F4F4F",
                                          },
                                        }}
                                      >
                                        All Sevas
                                      </Typography>
                                      <Typography
                                        sx={{
                                          marginLeft: "10px",
                                          marginTop: "5px",
                                          fontFamily: "Objectivity",
                                          fontSize: {
                                            xs: "11px",
                                            sm: "13px",
                                          },
                                          fontWeight: {
                                            xs: 500,
                                            sm: 500,
                                          },
                                          lineHeight: {
                                            xs: "16px",
                                            sm: "12.3px",
                                          },
                                          textAlign: "left",
                                          color: {
                                            xs: "#747474",
                                            sm: "#505050",
                                          },
                                        }}
                                      >
                                        You can check your selected sevas
                                      </Typography>
                                    </Box>
                                  </AccordionSummary>

                                  <AccordionDetails
                                    sx={{
                                      borderTop: "1px solid rgba(0, 0, 0, 0.2)",
                                      paddingTop: "16px",
                                    }}
                                  >
                                    {cartData &&
                                      cartData.data &&
                                      cartData.data.length > 0
                                      ? cartData.data.map((item, index) => (
                                        <Box
                                          key={index}
                                          sx={{
                                            display: "flex",
                                            height: isMobile
                                              ? "140px"
                                              : "unset",
                                            position: "relative",
                                            marginBottom:
                                              cartData?.data?.length - 1 ===
                                                index
                                                ? "unset"
                                                : "10px",
                                          }}
                                        >
                                          <div className="cart-image">
                                            {/* <img
                                                src={cartimage}
                                                alt="Seva Image"
                                              /> */}
                                            <div
                                              className="delete_dustbin"
                                              onClick={() =>
                                                removeItemFromCart(item?.id)
                                              }
                                            ></div>
                                          </div>
                                          <div className="add-to-cart-box-mini-content app-new-sm-cart-box">
                                            <div>
                                              <h4>
                                                {item?.seva_price?.title}
                                              </h4>
                                              <p>
                                                {item?.seva?.start_date
                                                  ? new Date(
                                                    item?.seva?.start_date
                                                  ).toDateString()
                                                  : ""}
                                              </p>
                                              <h6>
                                                Puja Variant -&nbsp;
                                                <span
                                                  style={{ color: "#FF5B00" }}
                                                >
                                                  01 Devotee
                                                </span>
                                              </h6>
                                            </div>
                                            {isMobile ? (
                                              <div className="add-to-cart-price">
                                                <h4>
                                                  {/* To do: need to fix it */}
                                                  {true ? "₹" : "$"}
                                                  {
                                                    item?.seva_price
                                                      ?.selling_price
                                                  }
                                                </h4>
                                              </div>
                                            ) : null}
                                          </div>
                                          {!isMobile && (
                                            <div className="add-cart-price-remove-btn">
                                              <div className="add-to-cart-price">
                                                <h4>
                                                  ₹{" "}
                                                  {
                                                    item?.seva_price
                                                      ?.selling_price
                                                  }
                                                </h4>
                                              </div>
                                              <div className="remove-btn">
                                                <Button
                                                  onClick={() =>
                                                    removeItemFromCart(
                                                      item?.id
                                                    )
                                                  }
                                                >
                                                  Remove
                                                </Button>
                                              </div>
                                            </div>
                                          )}
                                        </Box>
                                      ))
                                      : null}
                                  </AccordionDetails>
                                </Accordion>
                              </Box>
                            </Box>

                            <div className="">
                              {/* <BasicAccordion3 familyData={familyData} setFamilyData={setFamilyData} handleCheckout={handleCheckout} /> */}
                            </div>
                            <div className="select-delivery-address">
                              <div className="deliver-address-content">
                                <div className="deliver-address-title">
                                  <h3>Select Delivery Address</h3>
                                </div>
                                <div className="view-all">
                                  <Button disableRipple disableElevation>
                                    View All
                                    <span>
                                      <img
                                        className="app-btn-img"
                                        src="/images/icons/arrow-right-orange.svg"
                                      />
                                    </span>
                                  </Button>
                                </div>
                              </div>
                            </div>

                            {addresses.map((address, index) => (
                              // <div
                              //   className="select-address-home-2"
                              //   key={address.id}
                              // >
                              //   <div className="select-address-home">
                              //     <div className="select-address-box">
                              //       <div className="address">
                              //         <div className="home">
                              //           <Checkbox
                              //             checked={
                              //               selectedAddressId === address.id
                              //             }
                              //             onChange={() =>
                              //               handleSelectAddress(address.id)
                              //             }
                              //           />
                              //           <h3>{address.address_name}</h3>
                              //         </div>
                              //         <p>
                              //           {address.address_1},{address.city.name},
                              //           {address.state.name},
                              //           {address.country.name}
                              //         </p>
                              //         <p></p>
                              //       </div>
                              //     </div>
                              //   </div>
                              // </div>

                              <Box
                                sx={{
                                  marginBottom:
                                    index === addresses.length - 1
                                      ? "20px"
                                      : "10px",
                                  paddingBottom:
                                    index === addresses?.length - 1
                                      ? "20px"
                                      : "unset",
                                  borderBottom:
                                    index === addresses?.length - 1
                                      ? "1px solid rgba(0, 0, 0, 0.2)"
                                      : "none",
                                }}
                                key={address.id}
                              >
                                <Box
                                  sx={{
                                    marginTop: "30px",
                                    border: "2px solid rgba(255, 91, 0, 0.4)",
                                    marginBottom: "20px",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                  }}
                                >
                                  <Accordion
                                    defaultExpanded
                                    sx={{
                                      boxShadow: "none",
                                      padding: "10px",
                                      background: "transparent",
                                    }}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        <ExpandMoreIcon
                                          sx={{
                                            color: "#FF5B00",
                                            fontSize: "30px",
                                          }}
                                        />
                                      }
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Checkbox
                                          checked={
                                            selectedAddressId === address.id
                                          }
                                          onChange={() =>
                                            setSelectedAddressId(address.id)
                                          }
                                          sx={{
                                            padding: 0,
                                            color:
                                              selectedAddressId === address?.id
                                                ? "#ff5b00"
                                                : "#988E8E!important",
                                            "&.Mui-checked": {
                                              color: "#ff5b00",
                                            },
                                          }}
                                        />
                                        <Typography
                                          component="h3"
                                          sx={{
                                            marginLeft: "10px",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {address.address_name}
                                        </Typography>
                                      </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Grid
                                        container
                                        alignItems="center"
                                        spacing={1}
                                        sx={{
                                          padding: isMobile
                                            ? "unset"
                                            : "0 34px",
                                        }}
                                      >
                                        {isMobile ? (
                                          <Grid item>
                                            <img
                                              src={PencilIcon}
                                              width="30"
                                              height="30"
                                              variant="outlined"
                                              onClick={() =>
                                                handleOpenEditAddressModal(
                                                  address
                                                )
                                              }
                                              sx={{
                                                color: "#ff5b00",
                                                cursor: "pointer",
                                                fill: "none",
                                                stroke: "#FF5B00",
                                              }}
                                            />
                                          </Grid>
                                        ) : null}
                                        <Grid item xs>
                                          <Typography
                                            sx={{
                                              whiteSpace: "normal",
                                            }}
                                          >
                                            {formatAddress([
                                              address?.fname
                                                ? address.fname
                                                : "",
                                              address?.lname
                                                ? address.lname
                                                : "",
                                              address?.address_1
                                                ? address.address_1
                                                : "",
                                              address?.address_2
                                                ? address.address_2
                                                : "",
                                              address?.city
                                                ? address.city.name
                                                : "",
                                              address?.state
                                                ? address.state.name
                                                : "",
                                              address?.country
                                                ? address.country.name
                                                : "",
                                            ])}
                                          </Typography>
                                          {!isMobile ? (
                                            <div className="edit-btn">
                                              <Button
                                                onClick={() =>
                                                  handleOpenEditAddressModal(
                                                    address
                                                  )
                                                }
                                                variant="outlined"
                                                sx={{
                                                  marginTop: "30px",
                                                  marginLeft: "unset!important",
                                                  "&:hover": {
                                                    borderColor: "unset",
                                                  },
                                                }}
                                              >
                                                Edit
                                              </Button>
                                            </div>
                                          ) : null}
                                        </Grid>
                                      </Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                </Box>
                              </Box>
                            ))}

                            <div className="add-new-address">
                              <Button
                                disableRipple
                                disableElevation
                                onClick={handleOpenAddAddressModal}
                              >
                                <span>
                                  <AddIcon />
                                </span>
                                Add New Address
                              </Button>
                            </div>

                            <Typography
                              sx={{
                                fontFamily: "Objectivity",
                                fontSize: "12px",
                                fontWeight: 500,
                                lineHeight: "20px",
                                textAlign: "left",
                                color: "#24292E",
                                margin: "20px 0",
                                textAlign: "justify",
                              }}
                            >
                              Your personal data will be used to process your
                              order, support your experience throughout this
                              website, and for other purposes described in our{" "}
                              <Link
                                to="/Privacy"
                                style={{ display: "inline", color: "#ff5b00" }}
                              >
                                Privacy Policy
                              </Link>
                            </Typography>

                            <Dialog
                              open={isAddAddressModalOpen}
                              onClose={handleCloseAddAddressModal}
                              fullWidth
                              maxWidth="md"
                            >
                              <DialogContent style={{ padding: "20px" }}>
                                <div>
                                  <div>
                                    <Typography variant="h6">
                                      {isEditMode
                                        ? "Edit Address"
                                        : "Address Details"}
                                    </Typography>

                                    <div className="billing-forms">
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              First Name
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              id="name"
                                              // placeholder="Akash"
                                              value={fname}
                                              onChange={(e) =>
                                                setFname(e.target.value)
                                              }
                                              error={fieldErrors.fname}
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>Last Name</FormLabel>
                                            <TextField
                                              id="name"
                                              value={lname}
                                              onChange={(e) =>
                                                setLname(e.target.value)
                                              }
                                            // placeholder="Gupta"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Phone No
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              value={phone_no}
                                              onChange={(e) =>
                                                setPhoneNo(e.target.value)
                                              }
                                              id="outlined-number"
                                              type="tel"
                                              // placeholder="+91 82107 16196"
                                              error={fieldErrors.phone_no}
                                              InputLabelProps={{ shrink: true }}
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Email ID
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              value={email}
                                              error={fieldErrors.email}
                                              onChange={(e) =>
                                                setEmail(e.target.value)
                                              }
                                              id="email"
                                            // placeholder="akashgupta@gmail.com"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={12}
                                          xl={12}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Address Line 1
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              value={address_1}
                                              error={fieldErrors.address_1}
                                              onChange={(e) =>
                                                setAddress1(e.target.value)
                                              }
                                              id="demo-helper-text-aligned"
                                            // placeholder="KPHB Phase 1, Kukatpally, Hyderabad"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={12}
                                          xl={12}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Address Line 2
                                            </FormLabel>
                                            <TextField
                                              value={address_2}
                                              onChange={(e) =>
                                                setAddress2(e.target.value)
                                              }
                                              id="demo-helper-text-aligned"
                                            //  placeholder="KPHB Phase 1, Kukatpally, Hyderabad"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-2">
                                            <FormLabel>
                                              State
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <Select
                                              id="state"
                                              value={selectedState}
                                              error={fieldErrors.selectedState}
                                              onChange={(e) =>
                                                setSelectedState(e.target.value)
                                              }
                                            >
                                              <MenuItem disabled value="">
                                                Select ur State
                                              </MenuItem>
                                              {stateData.map((state) => (
                                                <MenuItem
                                                  key={state.id}
                                                  value={state.id}
                                                >
                                                  {state.name}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-2">
                                            <FormLabel>
                                              City
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            {/* <Select
                                              id="city"
                                              value={selectedCity}
                                              onChange={(e) =>
                                                setSelectedCity(e.target.value)
                                              }
                                              error={fieldErrors.city_id}
                                            >
                                              <MenuItem disabled value="">
                                                Select ur City
                                              </MenuItem>
                                              {cityData.map((city) => (
                                                <MenuItem
                                                  key={city.id}
                                                  value={city.id}
                                                >
                                                  {city.name}
                                                </MenuItem>
                                              ))}
                                            </Select> */}

                                            <TextField
                                              id="city"
                                              value={selectedCity}
                                              error={fieldErrors.selectedCity}
                                              onChange={(e) =>
                                                setSelectedCity(e.target.value)
                                              }
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Pin Code
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              value={pincode}
                                              error={fieldErrors.pincode}
                                              onChange={(e) =>
                                                setPincode(e.target.value)
                                              }
                                              id="number"
                                            // placeholder="500072"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Whatsapp No
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              value={whatsup_no}
                                              error={fieldErrors.whatsup_no}
                                              onChange={(e) =>
                                                setWhatsupNo(e.target.value)
                                              }
                                              id="number"
                                            // placeholder="+91 82107 16196"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Address Name{" "}
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            {/* Dropdown for known address names */}
                                            {isEditMode ? (
                                              <>
                                                <Select
                                                  id="addressName"
                                                  value={address_name}
                                                  error={
                                                    fieldErrors.address_name
                                                  }
                                                  onChange={
                                                    handleAddressNameChange
                                                  }
                                                  sx={{
                                                    marginBottom: "20px",
                                                  }}
                                                >
                                                  <MenuItem disabled value="">
                                                    Select an Address Name
                                                  </MenuItem>
                                                  {addressNameOptions.map(
                                                    (option) => (
                                                      <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                      >
                                                        {option.name}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </Select>

                                                {address_name === "other" && (
                                                  <TextField
                                                    value={otherAddressName}
                                                    onChange={(e) =>
                                                      setOtherAddressName(
                                                        e.target.value
                                                      )
                                                    }
                                                    id="otherAddressName"
                                                    placeholder="Enter Address Name"
                                                    error={
                                                      fieldErrors.otherAddressName
                                                    }
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <Select
                                                  id="addressName"
                                                  value={address_name}
                                                  error={
                                                    fieldErrors.address_name
                                                  }
                                                  onChange={
                                                    handleAddressNameChange
                                                  }
                                                  sx={{
                                                    marginBottom: "20px",
                                                  }}
                                                >
                                                  <MenuItem disabled value="">
                                                    Select an Address Name
                                                  </MenuItem>
                                                  {addressNameOptions.map(
                                                    (option) => (
                                                      <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                      >
                                                        {option.name}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </Select>

                                                {address_name === "other" && (
                                                  <TextField
                                                    value={otherAddressName}
                                                    onChange={(e) =>
                                                      setOtherAddressName(
                                                        e.target.value
                                                      )
                                                    }
                                                    id="otherAddressName"
                                                    placeholder="Enter Address Name"
                                                    error={
                                                      fieldErrors.otherAddressName
                                                    }
                                                  />
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                    <div className="myfamily-forms-btn">
                                      <Button onClick={handleSaveAddress}>
                                        {isEditMode
                                          ? "Update Address"
                                          : "Save Address"}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </DialogContent>
                            </Dialog>
                          </div>
                          {!isMobile && (
                            <div className="add-family-details-btn">
                              <Button disableRipple disableElevation>
                                <VerifiedUserIcon />
                                <div className="log-in confirmation-btn">
                                  <Button
                                    className="login-btn"
                                    variant="outlined"
                                    onClick={handleCheckout}
                                  >
                                    Place Order ₹{calculateTotal()}
                                  </Button>
                                  <BootstrapDialog
                                    onClose={handleClose}
                                    aria-labelledby="customized-dialog-title"
                                    open={open}
                                  >
                                    <DialogTitle
                                      sx={{ m: 0, p: 2 }}
                                      id="customized-dialog-title"
                                    ></DialogTitle>
                                    <IconButton
                                      aria-label="close"
                                      onClick={handleClose}
                                      sx={{
                                        position: "absolute",
                                        right: 8,
                                        top: 8,
                                        color: (theme) =>
                                          theme.palette.grey[500],
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                    <DialogContent>
                                      <div className="welcome-back">
                                        <div className="ganesh-leaf">
                                          <img src={leaf} />
                                        </div>
                                        <div className="welcome-back-content">
                                          <img src={ganesh} />
                                          <h4>Booking is Successful</h4>
                                          <h5>
                                            We have successfully received your
                                            details
                                          </h5>
                                          <p>
                                            You will get a confirmation call or
                                            WhatsApp within 12 hrs of booking
                                          </p>
                                        </div>

                                        <div className="send-otp verify-otp">
                                          <Link to="/home">
                                            <Button
                                              disableRipple
                                              disableElevation
                                              variant="contained"
                                              className="app-btn app-btn-primary has-icon-end"
                                            >
                                              <span className="app-btn-text">
                                                Return to Homepage
                                              </span>
                                            </Button>
                                          </Link>
                                        </div>
                                      </div>
                                    </DialogContent>
                                  </BootstrapDialog>
                                </div>

                                <KeyboardArrowRightIcon />
                              </Button>
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className="order-summary">
                          <div className="coupons">
                            <div className="coupons-content">
                              <div className="coupons-content-title">
                                <h4>Coupons</h4>
                              </div>
                              <div className="coupons-btn">
                                <Button>
                                  View all
                                  <span>
                                    <img
                                      className="app-btn-img"
                                      src="/images/icons/arrow-right-orange.svg"
                                    />
                                  </span>
                                </Button>
                              </div>
                            </div>
                            {/* <div className="coupons-main-btns">
                              <Button className="coupon-name">
                                AJDNSAJNNJ
                              </Button>
                              <Button>Apply</Button>
                            </div> */}
                          </div>
                          <div className="order-summary-title">
                            <h2>Order Summary</h2>
                          </div>
                          <div className="order-summary-list">
                            <ul>
                              <li className="sub-total">Sub Total</li>
                              <li>₹{calculateTotal()}</li>
                            </ul>
                            {/* <ul>
                              <li className="sub-total">Shipping</li>
                              <li>₹80</li>
                            </ul> */}
                            <ul>
                              <li className="sub-total">Total</li>
                              <li>₹{calculateTotal()}</li>
                            </ul>
                            {/* {isMobile && ( */}
                            <div className="log-in confirmation-btn">
                              <Button
                                onClick={handleCheckout}
                                className="_0plu1"
                                variant="outlined"
                                fullWidth
                                disableRipple
                                disableElevation
                                sx={{
                                  padding: {
                                    xs: "6px 40px!important",
                                    sm: "10px 40px!important",
                                  },
                                  borderRadius: {
                                    xs: "15px!important",
                                    sm: "8px!important",
                                  },
                                }}
                              >
                                Proceed to Pay
                                <ChevronRight sx={{ fontSize: "30px" }} />
                              </Button>
                              <BootstrapDialog
                                onClose={handleClose}
                                aria-labelledby="customized-dialog-title"
                                open={open}
                              >
                                <DialogTitle
                                  sx={{ m: 0, p: 2 }}
                                  id="customized-dialog-title"
                                ></DialogTitle>
                                <IconButton
                                  aria-label="close"
                                  onClick={handleClose}
                                  sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                                <DialogContent>
                                  <div className="welcome-back">
                                    <div className="ganesh-leaf">
                                      <img src={leaf} />
                                    </div>
                                    <div className="welcome-back-content">
                                      <img src={ganesh} />
                                      <h4>Booking is Successful</h4>
                                      <h5>
                                        We have successfully received your
                                        details
                                      </h5>
                                      <p>
                                        You will get a confirmation call or
                                        WhatsApp within 12 hrs of booking
                                      </p>
                                    </div>

                                    <div className="send-otp verify-otp">
                                      <Link to="/home">
                                        <Button
                                          disableRipple
                                          disableElevation
                                          variant="contained"
                                          className="app-btn app-btn-primary has-icon-end"
                                        >
                                          <span className="app-btn-text">
                                            Return to Homepage
                                          </span>
                                        </Button>
                                      </Link>
                                    </div>
                                  </div>
                                </DialogContent>
                              </BootstrapDialog>
                            </div>
                            {/* )} */}
                          </div>

                          {/* <div className="proceed-to-pay">
                            <Button
                              variant="contained"
                              className="app-btn app-btn-primary has-icon-end"
                            >
                              <span className="app-btn-text" onClick={handleCheckout}>
                                Proceed To Pay
                              </span>
                              <img
                                className="app-btn-img"
                                src="/images/icons/arrow-right.svg"
                              />
                            </Button>
                          </div> */}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Container>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default NonLinearHorizontalStepper;

import { Button, useMediaQuery } from "@mui/material";
import BasicAccordion2 from "./common/Accordion2";
import AccordionProfileMob from './common/AccordionProfileMob'
import { useLocation } from "react-router-dom";
import { useState } from "react";
import Logout from "./common/Logout";

export default function ProfileSidebar ({profiledata, toggleProfileVisibility, isProfileVisible}){
    const isMobile = useMediaQuery('(max-width: 767px)');
    const location = useLocation();
    const [openLogoutModal, setIsOpenLogoutModal] = useState(false);

    const shouldHideAccordion = [
        "/user/myfamily",
        "/user/kartadeatils",
        "/user/ancestordeatils",
        "/user/kartaancestordeatils",
        "/user/address",
        "/user/bookedseva"
      ].includes(location.pathname);


    const ProfileDetails = () => (
        <>
            <h4>{profiledata.fname}</h4>
            <h5>{profiledata.email}</h5>
            <p>+91 {profiledata.mobile_number}</p>
        </>
    )

    const Profiles = () => (
        profiledata.image ? (
            <img
                src={`${profiledata.image.domain}${profiledata.image.url}`}
                alt="Profile"
                style={{border:"solid #686868 0.5px", objectFit:'cover', height:"100px"}}
            />
        ) : (
            <img src="https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=" alt="Profile" />
        )
    )

    const handleLogoutClick = () => {
        setIsOpenLogoutModal(true);
    }

    const handleLogoutClose = () => {
        setIsOpenLogoutModal(false);
    }



    return (
      <div
        className="profile-menu"
        style={{
          display:
            (isMobile && shouldHideAccordion) || (isMobile && isProfileVisible)
              ? "none"
              : "flex",
        }}
      >
        <div className="profile-menu-content">
          {/* {profiledata.image ? (
                    <img
                        src={`${profiledata.image.domain}${profiledata.image.url}`}
                        alt="Profile"
                        style={{border:"solid #686868 0.5px", objectFit:'cover', height:"100px"}}
                    />
                ) : (
                    <img src="https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=" alt="Profile" />
                )} */}
          {/* <h4>{profiledata.fname}</h4>

                <h5>{profiledata.email}</h5>

                <p>+91 {profiledata.mobile_number}</p> */}

          <div style={{ textAlign: isMobile ? "center" : "" }}>
            <Profiles />
          </div>
          <div style={{ textAlign: isMobile ? "center" : "" }}>
            <ProfileDetails />
          </div>

          <div className="my-profile-info">
            {isMobile ? (
              shouldHideAccordion ? null : (
                <AccordionProfileMob
                  toggleProfileVisibility={toggleProfileVisibility}
                  onLogoutClick={handleLogoutClick}
                />
              )
            ) : (
              <BasicAccordion2 />
            )}
          </div>

          <div className="profile-log-out-btn">

                    <Button onClick={() => handleLogoutClick()}>Logout</Button>

                </div>
        </div>
        <Logout open={openLogoutModal} handleClose={handleLogoutClose} />
      </div>
    );
}
// import React, { useEffect } from "react";
// import {
//   Button,
//   Container,
//   FormLabel,
//   Typography,
//   useMediaQuery,
// } from "@mui/material";
// import Header from "../components/common/Header";
// import { styled } from "@mui/material/styles";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";
// import footerlogo from "../images/devasmriti-logo.svg";
// import profile from "../images/profile/profile.svg";
// import footerbg from "../images/footer-bg.svg";
// import eclipse from "../images/profile/god.svg";
// import AddIcon from "@mui/icons-material/Add";
// import TextField from "@mui/material/TextField";
// import { Link } from "react-router-dom";
// import BasicAccordion2 from "../components/common/Accordion2";
// import image from "../images/address/image.png";
// import arrowright from "../images/address/arrow-right.svg";
// import search from "../images/address/search.svg";
// import Footer from "./Footer";
// import { useState } from "react";
// import instance from "../utils/Api";
// import api from "../config/backend";
// import axios from "axios";
// function BookedSeva() {
//   const ns = "app-page-home";
//   const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   }));

//   const token = localStorage.getItem("token");
//   const [profiledata, setProfiledata] = useState([]);

//   const [error, setError] = useState("");

//   const isMobile = useMediaQuery("(max-width: 767px)");

//   // console.log(profiledata)

//   const fetchProfileData = async () => {
//     try {
//       const response = await axios.get(`${api}/api/user/profile`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       });
//       const name = response.data.data.fname;
//       localStorage.setItem("name", name);
//       // console.log("7777", response.data.data)

//       setProfiledata(response.data.data);
//     } catch (err) {
//       setError(err);

//       console.log(err);
//     }
//   };

//   const [bookeddata, setBookeddata] = useState([]);

//   // console.log(bookeddata)

//   const fetchBookedData = async () => {
//     try {
//       const response = await axios.get(`${api}/api/bookings`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       });

//       console.log("response in booking:", response?.data?.data?.data);
//       const name = response.data.data.fname;
//       localStorage.setItem("name", name);

//       const allBookings = response.data.data.data;

//       const successfulBookings = allBookings.filter(
//         (booking) => booking.payment_status === "Success"
//       );
//       setBookeddata(successfulBookings);
//       console.log(successfulBookings);
//     } catch (err) {
//       setError(err);

//       console.log(err);
//     }
//   };
//   useEffect(() => {
//     fetchProfileData();
//     fetchBookedData();
//   }, []);

//   const [searchQuery, setSearchQuery] = useState("");
//   const [filteredData, setFilteredData] = useState([]);
//   const [displayedData, setDisplayedData] = useState([]);

//   const handleSearch = () => {
//     const lowerCaseQuery = searchQuery.toLowerCase();

//     console.log(bookeddata);

//     const filteredResults = bookeddata.filter((order) => {
//       const firstSeva = order.order_sevas?.[0];
//       console.log(firstSeva);

//       if (firstSeva) {
//         if (firstSeva.seva_price && firstSeva.seva_price.seva) {
//           const title = firstSeva.seva_price.seva.title?.toLowerCase();
//           return title && title.includes(lowerCaseQuery);
//         } else if (firstSeva.seva_price_information) {
//           const title = JSON.parse(
//             firstSeva.seva_price_information
//           )?.title?.toLowerCase();
//           return title && title.includes(lowerCaseQuery);
//         }
//       }

//       return false;
//     });
//     const orderedResults = [...filteredResults].reverse();
//     setDisplayedData(orderedResults);
//   };

//   useEffect(() => {
//     handleSearch();
//   }, [searchQuery, bookeddata]);

//   const handleDownloadInvoice = (orderId) => {
//     const invoiceUrl = `${api}/invoice/${orderId}`;
//     console.log(invoiceUrl, "invoiceUrl");
//     const link = document.createElement("a");
//     link.href = invoiceUrl;
//     link.download = `invoice_${orderId}.pdf`;

//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };
//   return (
//     <>
//       <div className={`${ns}`}>
//         <div className={`${ns}__header`}>
//           <Header />
//         </div>
//         <div className="profile">
//           <div className="profile-container">
//             <div className="profile-content">
//               <div
//                 className="profile-menu"
//                 style={{ display: isMobile ? "none" : "flex" }}
//               >
//                 <div className="profile-menu-content">
//                   <img src="https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=" />
//                   <h4>{profiledata.fname}</h4>

//                   <h5>{profiledata.email}</h5>

//                   <p>+91 {profiledata.mobile_number}</p>
//                   <div className="my-profile-info">
//                     <BasicAccordion2 />
//                   </div>
//                   {/* <div className="profile-log-out-btn">
//                                         <Button>Logout</Button>
//                                     </div> */}
//                 </div>
//               </div>
//               <div className="profile-information">
//                 <div className="profile-information-box">
//                   <div className="search-bar">
//                     <div className="search-btn-box">
//                       <img src={search} />
//                     </div>
//                     <input
//                       className=""
//                       placeholder="Search your order here"
//                       value={searchQuery}
//                       onChange={(e) => {
//                         setSearchQuery(e.target.value);
//                         handleSearch();
//                       }}
//                     />
//                     <div className="search-order">
//                       <Button className="search-btn" onClick={handleSearch}>
//                         Search Order
//                       </Button>
//                     </div>
//                   </div>
//                   <div className="search-box-title">
//                     {displayedData.length === 1 ? (
//                       <h3>
//                         Your Order <span>({displayedData.length} item)</span>
//                       </h3>
//                     ) : displayedData.length === 0 ? (
//                       <h3>No orders placed</h3>
//                     ) : (
//                       <h3>
//                         Your Order <span>({displayedData.length} items)</span>
//                       </h3>
//                     )}
//                   </div>
//                   {Array.isArray(displayedData) &&
//                     displayedData.map((order, index) => (
//                       <div className="search-border-box" key={index}>
//                         <div className="search-box">
//                           <div className="search-box-main">
//                             <div className="search-box-mini">
//                               <div className="search-box-mini-content">
//                                 {order.order_sevas[0]?.seva_price?.seva
//                                   ?.feature_image_id ? (
//                                   <Link
//                                     to={`/seva/${order.order_sevas[0]?.seva_price?.seva?.slug}`}
//                                   >
//                                     <img
//                                       src={`${order.order_sevas[0]?.seva_price?.seva?.feature_image_id.domain}${order.order_sevas[0]?.seva_price?.seva?.feature_image_id.url}`}
//                                       alt={`Order ${index + 1}`}
//                                     />
//                                   </Link>
//                                 ) : (
//                                   <img src={image} alt={`Order ${index + 1}`} />
//                                 )}
//                               </div>

//                               <div className="deliver-date">
//                                 <h4>
//                                   {order.order_sevas[0]?.seva_price?.seva
//                                     ?.title ||
//                                     ` ${JSON.parse(order.order_sevas[0]?.seva_price_information)?.title}`}
//                                 </h4>
//                                 <p>
//                                   {order.is_india === 1 ? "₹" : "$"}{" "}
//                                   {order.final_paid_amount}
//                                 </p>
//                                 <p>
//                                   Booked on{" "}
//                                   {new Date(
//                                     order.order_sevas[0].created_at
//                                   ).toDateString()}
//                                 </p>
//                               </div>
//                             </div>
//                             <div className="right-down">
//                               <button
//                                 className="download-inv"
//                                 onClick={() =>
//                                   handleDownloadInvoice(
//                                     order.order_sevas[0]?.order_id
//                                   )
//                                 }
//                               >
//                                 Download Invoice
//                               </button>
//                               {/* <Link to={`/seva/${order.order_sevas[0]?.seva_price.seva?.slug}`}>
//                                                                 <div className="arrow">
//                                                                     <img src={arrowright} alt="Arrow Right" />
//                                                                 </div>
//                                                             </Link> */}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//           <Footer />
//         </div>
//       </div>
//     </>
//   );
// }

// export default BookedSeva;

// Working code

// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   Container,
//   Typography,
//   useMediaQuery,
//   Chip,
// } from "@mui/material";
// import Header from "../components/common/Header";
// import { styled } from "@mui/material/styles";
// import Paper from "@mui/material/Paper";
// import { Link } from "react-router-dom";
// import BasicAccordion2 from "../components/common/Accordion2";
// import search from "../images/address/search.svg";
// import Footer from "./Footer";
// import api from "../config/backend";
// import axios from "axios";

// function BookedSeva() {
//   const ns = "app-page-home";
//   const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   }));

//   const token = localStorage.getItem("token");
//   const [profiledata, setProfiledata] = useState([]);
//   const [bookeddata, setBookeddata] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [displayedData, setDisplayedData] = useState([]);
//   const [error, setError] = useState("");
//   const isMobile = useMediaQuery("(max-width: 767px)");

//   const parseJson = (data) => {
//     try {
//       return JSON.parse(data);
//     } catch (error) {
//       console.error("Error parsing JSON:", error);
//       return {};
//     }
//   };

//   const fetchProfileData = async () => {
//     try {
//       const response = await axios.get(`${api}/api/user/profile`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       });
//       const name = response.data.data.fname;
//       localStorage.setItem("name", name);
//       setProfiledata(response.data.data);
//     } catch (err) {
//       setError(err);
//       console.log(err);
//     }
//   };

//   const fetchBookedData = async () => {
//     try {
//       const response = await axios.get(`${api}/api/bookings`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       });

//       const allBookings = response.data.data.data.map((booking) => ({
//         ...booking,
//         shipping_address: parseJson(booking.shipping_address),
//         billing_address: parseJson(booking.billing_address),
//         seva_price_information: parseJson(booking.seva_price_information),
//       }));

//       setBookeddata(allBookings);
//     } catch (err) {
//       setError(err);
//       console.log(err);
//     }
//   };

//   useEffect(() => {
//     fetchProfileData();
//     fetchBookedData();
//   }, []);

//   const handleSearch = () => {
//     const lowerCaseQuery = searchQuery.toLowerCase();

//     const filteredResults = bookeddata.filter((order) => {
//       const title = order.seva_price_information?.title?.toLowerCase();
//       return title && title.includes(lowerCaseQuery);
//     });

//     const orderedResults = [...filteredResults].reverse();
//     setDisplayedData(orderedResults);
//   };

//   useEffect(() => {
//     handleSearch();
//   }, [searchQuery, bookeddata]);

//   const handleDownloadInvoice = (orderId) => {
//     const invoiceUrl = `${api}/invoice/${orderId}`;
//     const link = document.createElement("a");
//     link.href = invoiceUrl;
//     link.download = `invoice_${orderId}.pdf`;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   return (
//     <div className={`${ns}`}>
//       <div className={`${ns}__header`}>
//         <Header />
//       </div>
//       <div className="profile">
//         <div className="profile-container">
//           <div className="profile-content">
//             <div
//               className="profile-menu"
//               style={{ display: isMobile ? "none" : "flex" }}
//             >
//               <div className="profile-menu-content">
//                 <img src="https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=" />
//                 <h4>{profiledata.fname}</h4>
//                 <h5>{profiledata.email}</h5>
//                 <p>+91 {profiledata.mobile_number}</p>
//                 <div className="my-profile-info">
//                   <BasicAccordion2 />
//                 </div>
//               </div>
//             </div>
//             <div className="profile-information">
//               <div className="profile-information-box">
//                 <div className="search-bar">
//                   <div className="search-btn-box">
//                     <img src={search} />
//                   </div>
//                   <input
//                     placeholder="Search your order here"
//                     value={searchQuery}
//                     onChange={(e) => setSearchQuery(e.target.value)}
//                   />
//                   {/* <Button className="search-btn" onClick={handleSearch}>
//                     Search Order
//                   </Button> */}
//                 </div>
//                 <div className="search-box-title">
//                   {displayedData.length === 1 ? (
//                     <h3>
//                       Your Order <span>({displayedData.length} item)</span>
//                     </h3>
//                   ) : displayedData.length === 0 ? (
//                     <h3>No orders placed</h3>
//                   ) : (
//                     <h3>
//                       Your Order <span>({displayedData.length} items)</span>
//                     </h3>
//                   )}
//                 </div>
//                 <div
//                   className="orders-container"
//                   style={{ overflowY: "auto", maxHeight: "600px" }}
//                 >
//                   {displayedData.map((order, index) => (
//                     <div className="search-border-box" key={index}>
//                       <div className="search-box">
//                         <div className="search-box-main">
//                           <div className="deliver-date">
//                             <h4>{order.seva_price_information?.title}</h4>
//                             <Chip
//                               label={order.payment_status}
//                               color={
//                                 order.payment_status === "Success"
//                                   ? "success"
//                                   : "error"
//                               }
//                               style={{ marginBottom: "10px" }}
//                             />
//                             <p>
//                               {order.is_india === 1 ? "₹" : "$"}{" "}
//                               {order.final_paid_amount}
//                             </p>
//                             <p>
//                               Booked on{" "}
//                               {new Date(order.created_at).toDateString()}
//                             </p>
//                           </div>
//                           <button
//                             className="download-inv"
//                             onClick={() =>
//                               handleDownloadInvoice(order.order_id)
//                             }
//                           >
//                             Download Invoice
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <Footer />
//       </div>
//     </div>
//   );
// }

// export default BookedSeva;

import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Typography,
  useMediaQuery,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TextField,
} from "@mui/material";
import Header from "../components/common/Header";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import BasicAccordion2 from "../components/common/Accordion2";
import searchIcon from "../images/address/search.svg";
import search from "../images/address/search.svg";
import Footer from "./Footer";
import api from "../config/backend";
import axios from "axios";

const SearchInput = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: "#f1f3f5",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

function BookedSeva() {
  const ns = "app-page-home";
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const token = localStorage.getItem("token");
  const [profiledata, setProfiledata] = useState([]);
  const [bookeddata, setBookeddata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [displayedData, setDisplayedData] = useState([]);
  const [paymentFilter, setPaymentFilter] = useState("");
  const [error, setError] = useState("");
  const isMobile = useMediaQuery("(max-width: 767px)");

  const parseJson = (data) => {
    try {
      return JSON.parse(data);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
    }
  };

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(`${api}/api/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const name = response.data.data.fname;
      localStorage.setItem("name", name);
      setProfiledata(response.data.data);
    } catch (err) {
      setError(err);
      console.log(err);
    }
  };

  const fetchBookedData = async () => {
    try {
      const response = await axios.get(`${api}/api/bookings`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const allBookings = response.data.data.data.map((booking) => ({
        ...booking,
        shipping_address: parseJson(booking.shipping_address),
        billing_address: parseJson(booking.billing_address),
        seva_price_information: parseJson(booking.seva_price_information),
      }));

      setBookeddata(allBookings);
    } catch (err) {
      setError(err);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchProfileData();
    fetchBookedData();
  }, []);

  const handleSearch = () => {
    const lowerCaseQuery = searchQuery.toLowerCase();

    const filteredResults = bookeddata.filter((order) => {
      const title = order.seva_price_information?.title?.toLowerCase();
      const matchesTitle = title && title.includes(lowerCaseQuery);
      const matchesStatus = paymentFilter
        ? order.payment_status === paymentFilter
        : true;

      return matchesTitle && matchesStatus;
    });

    const orderedResults = [...filteredResults].reverse();
    setDisplayedData(orderedResults);
  };

  useEffect(() => {
    handleSearch();
  }, [searchQuery, paymentFilter, bookeddata]);

  const handleDownloadInvoice = (orderId) => {
    const invoiceUrl = `${api}/invoice/${orderId}`;
    const link = document.createElement("a");
    link.href = invoiceUrl;
    link.download = `invoice_${orderId}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={`${ns}`}>
      <div className={`${ns}__header`}>
        <Header />
      </div>
      <div className="profile">
        <div className="profile-container">
          <div className="profile-content">
            <div
              className="profile-menu"
              style={{ display: isMobile ? "none" : "flex" }}
            >
              <div className="profile-menu-content">
                <img src="https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=" />
                <h4>{profiledata.fname}</h4>
                <h5>{profiledata.email}</h5>
                <p>+91 {profiledata.mobile_number}</p>
                <div className="my-profile-info">
                  <BasicAccordion2 />
                </div>
              </div>
            </div>
            <div className="profile-information">
              <div className="profile-information-box">
                {/* <div className="search-bar">
                  <div className="search-btn-box">
                    <img src={search} />
                  </div>
                  <input
                    placeholder="Search your order here"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <Button className="search-btn" onClick={handleSearch}>
                    Search Order
                  </Button>
                </div> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 3,
                  }}
                >
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    Your Order{" "}
                    <span style={{ color: "#888" }}>
                      ({displayedData.length} items)
                    </span>
                  </Typography>
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: 150 }}
                  >
                    <InputLabel>Filter by Status</InputLabel>
                    <Select
                      value={paymentFilter}
                      onChange={(e) => setPaymentFilter(e.target.value)}
                      label="Filter by Status"
                    >
                      <MenuItem value="">All</MenuItem>
                      <MenuItem value="Success">Success</MenuItem>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Failed">Failed</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{ display: "flex", gap: 2, mb: 3, alignItems: "center" }}
                >
                  <SearchInput
                    placeholder="Search your order here"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    fullWidth
                  />
                  <img
                    src={searchIcon}
                    alt="Search"
                    style={{ width: 24, height: 24 }}
                  />
                </Box>
                <Box
                  sx={{
                    maxHeight: { xs: "auto", md: 500 },
                    overflowY: { xs: "visible", md: "auto" },
                    p: { xs: 0.5, md: 1 },
                  }}
                >
                  {displayedData.map((order, index) => (
                    <Paper
                      key={index}
                      sx={{
                        p: { xs: 1, md: 2 },
                        mb: { xs: 1, md: 2 },
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent: "space-between",
                        alignItems: { xs: "flex-start", sm: "center" },
                        borderRadius: 4,
                        boxShadow: 2,
                        margin: { xs: "0 0 1rem 0", sm: "0" },
                      }}
                      elevation={1}
                    >
                      <Box sx={{ width: { xs: "100%", sm: "auto" } }}>
                        <Typography
                          variant="h6"
                          sx={{ fontSize: { xs: "1rem", md: "1.25rem" } }}
                        >
                          {order.seva_price_information?.title}
                        </Typography>
                        <Chip
                          label={order.payment_status}
                          color={
                            order.payment_status === "Success"
                              ? "success"
                              : "error"
                          }
                          sx={{ mt: 1, mb: 1 }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}
                        >
                          {order.is_india === 1 ? "₹" : "$"}{" "}
                          {order.final_paid_amount}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontSize: { xs: "0.75rem", md: "0.875rem" } }}
                        >
                          Booked on {new Date(order.created_at).toDateString()}
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        // color="primary"
                        sx={{
                          mt: { xs: 2, sm: 0 },
                          width: {
                            xs: "100%",
                            sm: "auto",
                            borderRadius: 20,
                            color: "white",
                          },
                          backgroundColor: "#ff5b00",
                          "&:hover": {
                            backgroundColor: "#ff7b33",
                          },
                        }}
                        onClick={() => handleDownloadInvoice(order.order_id)}
                      >
                        Download Invoice
                      </Button>
                    </Paper>
                  ))}
                </Box>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default BookedSeva;

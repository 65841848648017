import { Button, Container, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Header from "../components/common/Header";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import footerbg from "../images/footer-bg.svg";
import CustomTabPanel3 from "../components/common/CustomTabPanel3";
import Swipper from "../components/common/Swipper";
import footerlogo from "../images/devasmriti-logo.svg";
import { useEffect, useState } from "react";
import instance from "../utils/Api";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import sevaname from "../images/champaign/image-01.png";
import sevaname1 from "../images/champaign/image-02.png";
import sevaname2 from "../images/champaign/image-03.png";

import React, { useRef } from "react";
import Header2 from "../components/common/Header2";
import api from "../config/backend";
import axios from "axios";
import { ChevronRight } from "@mui/icons-material";
import FacebookImg from "../images/social-icons/facebook.svg";
import InstagramImg from "../images/social-icons/instagram.svg";
import LinkedinImg from "../images/social-icons/linkedin.png";
import YoutubeImg from "../images/social-icons/youtube.png";
import StarBackground from "../components/common/StarBackground";
import ReactGA from "react-ga4";
import { Link, useNavigate } from "react-router-dom";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import CustomTabPanel4 from "../components/common/CustomTabPanel4";

const ProgressBar = ({ amountRaised, goal }) => {
  // const progressPercentage = (progress / goal) * 100;
  const progressPercentage =
    goal > 0 ? (Number(amountRaised) / Number(goal)) * 100 : 0;
  // console.log(progress, goal, progressPercentage, "=====================000");

  return (
    <div className="progress-bar-container">
      <span className="percentageMobile">{progressPercentage.toFixed(0)}% raised</span>
      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <div className="progress-text">
        <span className="percentageDesktop">{progressPercentage.toFixed(0)}% raised</span>
        <span
          style={{ textAlign: "right" }}
        >{`₹${amountRaised} raised out of ₹${goal}`}</span>
      </div>
    </div>
  );
};

function Donation({ triggerRefresh1, setTriggerRefresh }) {
  const { productId } = useParams();
  const navigate = useNavigate();
  // console.log(productId)
  const suggestedAmount = [516, 1116, 2116, 5116]
  const token = localStorage.getItem("token");
  const ns = "app-page-home";
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const [sevadata, setSevaData] = useState([]);
  const [sevaerror, setSevaError] = useState(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [isIndia, setIsIndia] = useState(true);
  // const [triggerRefresh, setTriggerRefresh] = React.useState(0)
  // console.log(productDetails)
  const [sevaDataArray, setSevaDataArray] = useState([]);
  const [numStars, setNumStars] = useState(5);
  const [starRate, setStarRate] = useState(4.5);

  const [showSocialIcons, setShowSocialIcons] = useState(false);

  const shareUrl = window.location.href;
  const title = "Book This Seva on Devasmriti";

  // const [amountRaised, setAmountRaised] = useState(1995058);
  // const [goal, setGoal] = useState(5000000);
  const [donationValue, setDonationValue] = useState(1116);

  // const fetchSevasData = async () => {
  //   try {
  //     const response = await instance.get("api/sevas");
  //     console.log(response.data.data.data)
  //     setSevaData(response.data.data.data);
  //   } catch (err) {
  //     setSevaError(err);
  //     console.log(err)
  //   }
  // };

  const [allSevas, setAllSevas] = useState([]);

  const getAllSevas = (id) => {
    axios
      .get(`https://api-backend.devasmriti.com/api/getSevaFromDonation/${id}`)
      .then(({ data }) => {
        setSevaDataArray(data);
      });
  };

  const handleNativeShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          url: shareUrl,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    }
  };

  const fetchEventData = async () => {
    try {
      const response = await axios.get(`${api}/api/donations/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });

      // console.log("111", response.data.data)
      setIsIndia(response.data.is_india === 1);
      setProductDetails(response.data.data);
      // const sevas = response.data.data.sevas;
      // setSevaDataArray(sevas);
      // console.log(sevas)
      setTriggerRefresh((prev) => prev + 1);
      getAllSevas(response.data.data.id);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchEventData();
    // fetchSevasData()
  }, []);
  const sevasSectionRef = useRef(null);
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const [openModel, setOpenModel] = useState(false);
  const handleBookSeva = (seva, redirect) => {
    ReactGA.event({
      category: "Service Booked",
      action: "Book Seva",
      label: seva.title,
    });
    if (!seva) {
      console.error("Seva object is null or undefined.");
      return;
    }
    console.log(seva, "kjwgebdkjwebkdjwebkwjekwje");
    console.log(redirect);
    // const sevapriceid = selectedPriceList.find(
    //   (item) => item.seva_id === seva?.id
    // ).selected_price.id;
    const sevapriceid = 20;
    localStorage.removeItem("newSelectedPriceId");

    if (!sevapriceid) {
      console.error("Selected price is null or undefined.");
      return;
    }
    if (redirect) {
      navigate(`/checkout/${seva.slug}/${donationValue}?donation=true`);
      return;
    }

    // if (token) {
    navigate(`/checkout/${seva.slug}/${donationValue}?donation=true`);

    //Todo: working on it
    // } else {
    //   console.log(seva);
    //   setSevaForDialog(seva);
    //   openDialog();
    // }
  };

  const handleOpenModal = (seva) => {
    setOpenModel(seva.id);
    console.log("Open Modal", seva.id);
    // console.log(seva, "seva choose")
  };
const [showErrorPopup, setShowErrorPopup] = useState(false)
  const handleButtonClick = (seva) => {
    if(donationValue < 251){
      // toast.error('Your minimum cart value should be atleast ₹251', {
      //   position: "top-center",
      //   autoClose: false,
      //   hideProgressBar: true,
      //   closeOnClick: false,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      //   });
      setShowErrorPopup(true)
    }else{
      window.fbq("track", "AddToCart");
      window.fbq("track", "InitiateCheckout");
      const sevaPrices = seva.seva_prices;
  
      console.log("handleButtonclick:", seva);
  
      if (isMobile) {
        if (!sevaPrices || sevaPrices.length <= 1) {
          handleBookSeva(seva);
        } else {
          handleOpenModal(seva);
        }
      } else {
        handleBookSeva(seva);
      }
    }
  };

  // const sevaDataArray = [
  //   {
  //     id: 1,
  //     name: 'Seva Name - Ananadanam 1',
  //     date: '14th October 2023',
  //     price: 2000,
  //     description: 'Lorem Epson is a dummy text to fill the sentences Lorem Epson is a dummy text to fill the sentences',
  //     image: sevaname
  //   },
  //   {
  //     id: 2,
  //     name: 'Seva Name - Ananadanam 2',
  //     date: '15th October 2023',
  //     price: 2500,
  //     description: 'Another description for the second seva.',
  //     image: sevaname2
  //   },
  //   {
  //     id: 3,
  //     name: 'Seva Name - Ananadanam 3',
  //     date: '16th October 2023',
  //     price: 1800,
  //     description: 'Description for the third seva.',
  //     image: sevaname1
  //   },
  //   {
  //     id: 4,
  //     name: 'Seva Name - Ananadanam 4',
  //     date: '17th October 2023',
  //     price: 2200,
  //     description: 'Description for the fourth seva.',
  //     image: sevaname2
  //   },
  //   // Add more sevas as needed
  // ];



  return (
    <>
      <div className={`${ns}`}>
        <div className={`${ns}__header`}>
          <Header
            triggerRefresh1={triggerRefresh1}
            setTriggerRefresh={setTriggerRefresh}
          />
        </div>
        <div style={{}} className="custom-champaign-parent-box">
          <div className="_3gpv">
            {productDetails?.banner_image_id ? (
              <img
                src={`${productDetails.banner_image_id.domain}${productDetails.banner_image_id.url}`}
                alt={productDetails.banner_image_id.name}
              />
            ) : (
              <div>
                <Skeleton style={{ height: "280px" }} />
              </div>
            )}
          </div>
          <div className="ananta-padmanabu">
            {/* <Container maxWidth="lg"> */}
            <div className="ananta-padmanabu-box">
              <div
                style={{
                  position: "absolute",
                  right: showSocialIcons ? 15 : 10,
                  top: showSocialIcons ? -155 : 10,
                }}
              >
                <div
                  className="campaign-social-icons"
                  // style={{
                  //   backgroundColor: showSocialIcons ? "white" : "red",
                  // }}
                >
                  {showSocialIcons ? (
                    <div
                      class="footer-social-icons"
                      style={{ paddingBottom: "3px" }}
                    >
                      <div>
                        <FacebookShareButton url={shareUrl} quote={title}>
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                      </div>
                      <div>
                        <TwitterShareButton url={shareUrl} title={title}>
                          <TwitterIcon size={32} round />
                        </TwitterShareButton>
                      </div>
                      <div>
                        <WhatsappShareButton
                          url={shareUrl}
                          title={title}
                          separator=":: "
                        >
                          <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                      </div>
                      <div>
                        <EmailShareButton
                          url={shareUrl}
                          subject={title}
                          body="Check out this link: "
                        >
                          <EmailIcon size={32} round />
                        </EmailShareButton>
                      </div>
                      <div
                        onClick={(e) => setShowSocialIcons(false)}
                        style={{
                          background: "#FF5B00",
                          color: "white",
                          fontSize: "16px",
                          fontWeight: 700,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50px",
                          width: "auto",
                          padding: "0px 10px",
                          marginTop: "9px",
                          cursor: "pointer",
                          height: "30px",
                          width: "30px",
                        }}
                      >
                        x
                      </div>
                      {/* </div> */}
                    </div>
                  ) : (
                    <div
                      onClick={(e) => setShowSocialIcons(true)}
                      style={{
                        zIndex: 9999,
                        cursor: "pointer",
                        // background: "red",
                      }}
                    >
                      <img
                        src={require("../images/banner/share.svg").default}
                        style={{
                          width: "50px",
                          height: "50px",
                          // zIndex: 9,
                          cursor: "pointer",
                          boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="ananta-padmanbu-content">
                <h2>
                  {/* {productDetails?.title?.slice(0, 65)}
                  {"..."} */}
                  {productDetails?.title ? (
                    productDetails?.title
                  ) : (
                    <Skeleton width={600} />
                  )}
                </h2>
                <div className="ananta-padmanbu-mini-content">
                  <div className="ananta-mini-content">
                    <h4>
                      {productDetails.expairy_date_time ? (
                        new Date(
                          productDetails.expairy_date_time
                        ).toDateString()
                      ) : (
                        <Skeleton width={150} />
                      )}
                    </h4>
                    {productDetails.event ? (
                      <h5 className="event">
                        <span>Event</span> {productDetails.event}
                      </h5>
                    ) : (
                      <Skeleton width={250} style={{ marginBottom: "10px" }} />
                    )}
                    {productDetails.location ? (
                      <h5 className="venue">
                        <span>Venue</span> {productDetails.location}
                      </h5>
                    ) : (
                      <Skeleton width={240} />
                    )}
                  </div>
                  <div style={{display:'flex', justifyContent:"space-between", alignItems:'center', paddingBottom:'20px'}}>
                    <div className="starContainer">
                      <StarBackground numberOfStars={numStars} rating={starRate} />
                    </div>
                    <div style={{width:'42%',  display:'flex', paddingBottom:'20px'}}>
                    <div className="suggestedAmountWrapper">
                    {suggestedAmount?.map((item) => (
                      <div style={{ position: 'relative' }} key={item}>
                        <button
                          className={
                            item === 1116
                              ? donationValue === item
                                ? 'selectedAmountCard1'
                                : 'suggestedAmountCard1'
                              : donationValue === item
                              ? 'selectedAmountCard'
                              : 'suggestedAmountCard'
                          }
                          onClick={() => setDonationValue(item)}
                        >
                          <span>₹&nbsp;{item}</span>
                        </button>
                        {item === 1116 && <div className="mostDonated">Most Donated</div>}
                      </div>
                    ))}

                    </div>
                    </div>
                  </div>
                  <div
                    className="progress"
                    style={{
                      display: "flex",
                      flexDirection: window.innerWidth > 700 ? "row" : "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: window.innerWidth > 700 ? "80%" : "100%",
                      // background: "red",
                      gap: 50,
                    }}
                  >
                    <ProgressBar
                      amountRaised={productDetails.amount_collected}
                      goal={productDetails.total_amount}
                    />
                    {/* ) : (
                      <Skeleton />
                    )} */}

                    {/* <div className="suggestedAmountWrapper1">
                     {suggestedAmount?.map(item => (
                      <button className={donationValue === item ? "selectedAmountCard": "suggestedAmountCard"} onClick={e => setDonationValue(item)}>
                      <span>₹&nbsp;{item}</span>
                      </button>
                     ))}
                    </div> */}
                    <div className="suggestedAmountWrapper1" style={{paddingBottom:'20px'}}>
                      {suggestedAmount?.map((item) => (
                        <div style={{ position: 'relative' }} key={item}>
                          <button
                            key={item}
                            className={
                              item === 1116
                                ? donationValue === item
                                  ? 'selectedAmountCard1'
                                  : 'suggestedAmountCard1'
                                : donationValue === item
                                ? 'selectedAmountCard'
                                : 'suggestedAmountCard'
                            }
                            onClick={() => setDonationValue(item)}
                          >
                            <span>₹&nbsp;{item}</span>
                          </button>
                          {item === 1116 && <div className="mostDonated">Most Donated</div>}
                        </div>
                      ))}
                    </div>

                    
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: window.innerWidth < 700 && "100%",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "28px",
                          fontWeight: 700,
                          color: "#ff5b00",
                          paddingRight: "10px",
                        }}
                      >
                        ₹{" "}
                      </div>
                      {/* <input
                        value={donationValue}
                        onChange={(e) => setDonationValue(e.target.value)}
                        type="number"
                        placeholder="e-Hundi"
                        className="customInput"
                        style={{
                          width: window.innerWidth > 700 ? "200px" : "100%",
                        }}
                      /> */}
                      <TextField id="outlined-basic" 
                        value={donationValue}
                        onChange={(e) => setDonationValue(e.target.value)}
                        label="Amount" 
                        variant="outlined" 
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '40px', // Adjust the radius value as needed
                          },
                          // Default width
                          width: 'auto', // or any other default width for larger screens
                          // Mobile-specific styles
                          '@media (max-width: 768px)': {
                            width: '100%', // Full width on mobile devices
                          },
                        }}
                        inputProps={{
                          min: 251, // Set the minimum value to 251
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      window.innerWidth > 700
                        ? "ananta-padmanbu-btn showDonate"
                        : "ananta-padmanbu-btn1 showDonate"
                    }
                  >
                    {/* <Link to={`/check_out/${productDetails.id}`}> */}
                    <Button
                      // onClick={() =>
                      //   sevasSectionRef.current.scrollIntoView({
                      //     behavior: "smooth",
                      //   })
                      // }
                      onClick={() =>
                        donationValue ? handleButtonClick(productDetails) : null
                      }
                      variant="contained"
                      disableRipple
                      disableElevation
                      className="app-btn app-btn-primary has-icon-end"
                      sx={{ width: "100%" }}
                      style={{
                        opacity: donationValue ? 1 : 0.5,
                        marginTop: window.innerWidth < 700 ? "100px" : "30p",
                        width: window.innerWidth < 700 && "auto",
                        // bottom: 0,
                        // marginTop: "100px",
                      }}
                    >
                      <span className="app-btn-text">Donate</span>
                      <ChevronRight
                        sx={{ fontSize: "25px", marginLeft: "5px" }}
                      />
                    </Button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
            {/* </Container> */}
          </div>
        </div>
        <div className="champaign-tab">
          <Container ref={sevasSectionRef}>
            {sevaDataArray.length > 1 ? (
              <CustomTabPanel4
                setTriggerRefresh={setTriggerRefresh}
                sevas={sevaDataArray}
                isIndia={isIndia}
                productDetails={productDetails}
                setDonationValue={setDonationValue}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="imageLoader">
                  <img
                    src={require("../images/loader.png")}
                    alt="Logo"
                    className="loaderLogo"
                  />
                </div>
              </div>
            )}
          </Container>
        </div>
        <div className="discover-more app-new-individual-suggested-puja">
          <Container>
            <div className="discover-more-content">
              <div className="discover-more-buttons">
                <div className="">
                  <h2>Suggested Pujas</h2>
                </div>
              </div>
              <Swipper />
            </div>
          </Container>
        </div>
        <Footer />
        {/* <ToastContainer
          position="top-center"
          autoClose={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="colored"
        /> */}
        {showErrorPopup && 
         <>
         {showErrorPopup && (
           <div style={{
             position: 'fixed',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             padding: '20px',
             backgroundColor: '#fff',
             borderRadius: '8px',
             boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
             zIndex: 1000,
             width: '300px',
             textAlign: 'center',
             backgroundColor:'red',
             color:'white',
             fontWeight:700
           }}
           >
             <button
               onClick={e => setShowErrorPopup(false)}
               style={{
                 position: 'absolute',
                 top: '10px',
                 right: '10px',
                 background: 'transparent',
                 border: 'none',
                 fontSize: '16px',
                 cursor: 'pointer',
                 color:'white',
                 fontWeight:700
               }}
             >
               &times;
             </button>
             <p>Your minimum cart value should be at least ₹251</p>
           </div>
         )}
         {showErrorPopup && (
           <div
           onClick={e => setShowErrorPopup(false)}
            style={{
             position: 'fixed',
             top: 0,
             left: 0,
             width: '100%',
             height: '100%',
             backgroundColor: 'rgba(0, 0, 0, 0.5)',
             zIndex: 999,
           
           }} />
         )}
       </>
        }
        

        {isMobile && 
          <div className="donationFooter">
            <div className="donationFooterBtn" onClick={e => handleButtonClick(productDetails)}>
              Donate ₹{donationValue}
            </div>  
          </div>
          }
      </div>
    </>
  );
}

export default Donation;

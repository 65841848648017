export const getMenuProps = (position) => {
    const leftP = position === 'leftP' ? '30px!important' : 'unset!important';
  
    const rightP = position === 'rightP' ? '28px!important' : 'unset!important';



    return {
      PaperProps: {
        style: {
          borderColor: "#C9C9C9",
          boxShadow: "0px 4px 15px 0px #0000000D",
        },
        sx: {
          "&::-webkit-scrollbar": {
            width: '6px',
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: '#EAEAEA',
            margin: '4px 0'
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: '#ff5b00',
            borderRadius: '8px',
            transform: 'translateX(2px)',
            height: "2px",
            margin: '4px 0'
          },
          marginTop: "5px",
          borderRadius: "12px",
          fontWeight: "700",
          "& .MuiMenuItem-root": {
            borderColor: "#C9C9C9",
            "&:hover": {
              backgroundColor: "#FFEADE",
            },
          },
          "& .MuiList-root": {
            padding: "0",
            overflowY: "auto",
          },
          "& .MuiButtonBase-root": {
            backgroundColor: "white",
            color: "unset"
          },
          "@media (min-width: 320px) and (max-width: 767px)": {
            top: "230px",
            left: leftP,
            right: rightP,
            maxHeight: "300px",
            border: "1px solid #676767!important",
            maxWidth: "65%"
          }
    
        }
      }
    }
  }
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import instance from "../../utils/Api";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import {
  Box,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { json } from "react-router-dom";
import "dayjs/locale/en";
import EditFamilyDetails from "../../pages/EditFamilyDetails";
import { useDateField } from "@mui/x-date-pickers/DateField/useDateField";
import api from "../../config/backend";
import EditIcon from "@mui/icons-material/Edit";
import PencilIcon from "../../images/champaign/pencil_icon.svg";
import { noauthinstance } from "../../utils/Api";
import { useAuth } from "../../utils/Auth";
export default function BasicAccordion3({
  familyData,
  setFamilyData,
  handleCheckout,
  seva,
  selectedFamily,
  isSelectedFamily,
  setSelectedFamily,
  setIsSelectedFamily,
  setFamilyCheck,
  familycheck,
  setRefresh,
  setSelectedCount,
  selectedCount,
  onKarthaSelected,
  onAncestorSelected, customPrice, events,individual
}) {
  dayjs.extend(customParseFormat);
  dayjs.locale("en");
  const { isLogin, setIsLogin, login, logout } = useAuth();
  const [error, setError] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [familyDataCart, setFamilyDatacart] = useState([]);
  const [triggerRefresh, setTriggerRefresh] = useState(0);
  const token = localStorage.getItem("token");
  const [familyEditedRefresh, setFamilyEditedRefresh] = useState(0);
  const [full_name, setFull_name] = useState("");
  const [relationId, setRelationId] = useState("");
  const [gotram, setGotram] = useState("");
  const [nakshatram, setNakshatram] = useState("");
  const [rasiId, setRasiId] = useState("");
  const [notes, setNotes] = useState("");
  const [relationOptions, setRelationOptions] = useState([]);
  const [rasiOptions, setRasiOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dontKnow, setDontKnow] = useState(1);
  const [profiledata, setProfiledata] = useState([]);
  const [profilePictureData, setProfilePictureData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [editFamilyModal, setEditFamilyModal] = useState(false);
  const [familyMemberData, setFamilyMemberData] = useState();
  const isMobile = useMediaQuery("(max-width: 767px)");
  const [familyType, setFamilyType] = useState("self");

  const sevafamilytype = seva.family_type;

  console.log(seva, 'seva---')

  // useEffect(() => {
  //   console.log
  // }, [events])

  const [uniqueFamilyTypes, setUniqueFamilyTypes] = useState({});

  // console.log('uniqueFamilyTypes:', uniqueFamilyTypes)
  // console.log('familyDataCart:', familyDataCart)

  const getUniqueFamilyTypes = (familyDataCart) => {
    const familyTypes = {};
    familyDataCart.forEach((member) => {
      familyTypes[member.family_type] = true;
    });
    return familyTypes;
  };

  const fetchData = async () => {
    try {
      const profileResponse = await axios.get(`${api}/api/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });

      setProfileData(profileResponse.data.data);

      const familyResponse = await axios.get(`${api}/api/myfamily`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });

      setFamilyData(familyResponse.data.data.data);

      // console.log('family response data: ',familyResponse.data.data.data)

      // console.table(familyResponse.data.data.data)

      const uniqueFamilyObj = getUniqueFamilyTypes(
        familyResponse?.data?.data?.data
      );

      setUniqueFamilyTypes(uniqueFamilyObj);

      setFamilyDatacart(
        familyResponse.data.data.data.map((item) => {
          return {
            ...item,
            cartindex: seva && seva.seva_id,
            selected: false,
          };
        })
      );

      setError(null);
    } catch (err) {
      setError(err);
      console.error(err);
    }
  };


  const handleDateChange = (date) => {
    const selectedDate = dayjs(date);
    const today = dayjs();

    // Check if the selected date is before today
    if (selectedDate.isBefore(today) || selectedDate.isSame(today, "day")) {
      setSelectedDate(selectedDate.format("YYYY-MM-DD"));
    } else {
      alert("Invalid date. Please select a date on or before today.");
      setSelectedDate("");
    }
  };

  const fetchRasiData = async () => {
    try {
      const response = await axios.get(`${api}/api/rasi`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });
      // console.log(response.data.data.data)
      if (response.status === 200) {
        setRasiOptions(response.data.data.data);
      } else {
        console.error("Failed to fetch data from the API.");
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };

  const fetchrelationData = async () => {
    try {
      const response = await axios.get(`${api}/api/relation`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });

      if (response.status === 200) {
        setRelationOptions(response.data.data.data);
      } else {
        console.error("Failed to fetch data from the API.");
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };

  const handleCloseAddAddressModal = () => {
    setDontKnow((prev) => prev + 1);
    setFull_name("");
    setSelectedDate(null);
    setRelationId("");
    setRasiId("");
    setGotram("");
    setNakshatram("");
    setNotes("");
    setIsAddAddressModalOpen(false);
    setFieldErrors({
      full_name: false,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsEditing(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmailError({
      email: false,
    });
  };

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(`${api}/api/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });

      const name = response.data.data.fname;
      localStorage.setItem("name", name || "");
      setProfiledata(response.data.data);
      if (response.data.data.image) {
        setProfilePictureData(response.data.data.image);
      }
    } catch (err) {
      setError(err);
    }
  };
  const [emailError, setEmailError] = useState({
    email: false,
  });

  const validateEmail = () => {
    if (email.trim() !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(email);

      setEmailError({ email: !isValid });

      return isValid;
    }

    setEmailError({ email: false });
    return true;
  };
  const saveProfileData = async () => {
    window.fbq("track", "InputData");
    if (!validateEmail()) {
      return;
    }

    try {
      const updatedProfileData = {
        fname: firstName,
        mobile_number: phone,
        email,
      };
      const response = await axios.put(
        `${api}/api/user/profile`,
        updatedProfileData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setProfiledata(response.data.data);
        setIsEditing(false);
        handleClose();
        setTriggerRefresh((prev) => prev + 1);
      } else {
        console.error("Failed to update profile data");
      }
    } catch (error) {
      console.error("Failed to update profile data:", error);
    }
  };

  const handleChange = (familyMember) => {
    const selected = familyDataCart.map((item) => {
      return {
        ...item,
        selected: familyMember.id === item.id ? !item.selected : item.selected,
      };
    });

    // console.log('selected:--', selected)

    const selectedKartha = selected.filter(
      (item) => item?.family_type === "kartha" && item?.selected
    );

    const selectedAncestor = selected.filter(
      (item) => item?.family_type === "ancestors" && item?.selected
    );

    // console.log('selectedKartha:', selectedKartha);

    // if(selectedKartha?.length === 1){
    //   onKarthaSelected(true);
    // }else{
    //   onKarthaSelected(false);
    // }

    // if(selectedAncestor?.length > 0){
    //   onAncestorSelected(true);
    // }else{
    //   onAncestorSelected(false);
    // }

    onKarthaSelected(selectedKartha?.length === 1);
    onAncestorSelected(selectedAncestor?.length > 0);

    const ischecked = selected.some((item) => item.selected);

    const famids = selected
      .filter((item) => item.selected)
      .map((item) => item.id);

    setFamilyCheck(famids);

    setIsSelectedFamily((prev) => {
      const copy = JSON.parse(JSON.stringify(prev));

      const fixed = copy.map((item) => {
        const key = Object.keys(item)[0];

        // console.log({ key, test: seva.id })
        if (key === seva.id) {
          const test = {};

          test[key] = ischecked;

          return test;
        }

        return item;
      });

      return fixed;
    });

    console.log("selected array:", selected);

    setFamilyDatacart(selected);

    console.log("familyDataCart:", familyDataCart);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhone(value);
    }
  }

  // const handleChange = (familyMember) => {
  // 	const selected = familyDataCart.map((item) => ({
  // 		...item,
  // 		selected: familyMember.id === item.id ? !item.selected : item.selected,
  // 	}));

  // 	const famids = selected.filter((item) => item.selected).map((item) => item.id);

  // 	setFamilyCheck(famids);

  // 	setIsSelectedFamily((prev) => {
  // 		const copy = JSON.parse(JSON.stringify(prev));

  // 		const fixed = copy.map((item) => {
  // 			const key = Object.keys(item)[0];

  // 			if (key === seva.id) {
  // 				const test = {};
  // 				test[key] = selected.filter((item) => item.selected).length > 0;
  // 				setSelectedCount(selected.filter((item) => item.selected).length); // Update selected count
  // 				console.log("selectedCount:", selectedCount);
  // 				return test;
  // 			}

  // 			return item;
  // 		});

  // 		return fixed;
  // 	});

  // 	setFamilyDatacart(selected);
  // };

  function editFamilyHandler(familyMember) {
    setFamilyMemberData(familyMember);
    if (!events) {
      setEditFamilyModal(true);
    } else {
      console.log(familyMember, "lnelwenflef")
      setFull_name(familyMember?.full_name);
      setGotram(familyMember?.gothram)
      setIsAddAddressModalOpen(true)
    }

  }
  function closeEditFamilyModal() {
    setEditFamilyModal(false);
    setFamilyData(undefined);
  }
  const [fieldErrors, setFieldErrors] = useState({
    full_name: false,
  });
  const validateForm = () => {
    const errors = {};

    if (!full_name) {
      errors.full_name = true;
    }

    setFieldErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      return false;
    }

    return true;
  };

  const handleFamilyType = (type) => {
    setFamilyType(type);
    setIsAddAddressModalOpen(true);
  };
  const handleEditProfile = async () => {
    if (!validateForm()) {
      toast.error("Please fill in all required fields");
      return;
    }

    const familyData = {
      id: familyMemberData?.id,
      full_name,
      gothram: gotram,
      family_type: familyType,
    };

    try {
      const response = await axios.put(`${api}/api/myfamily/${familyMemberData?.id}`, familyData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      window.fbq("track", "InputData");
      if (response.data.success === 1) {
        fetchData()
        // const newFamilyMember = {
        //   ...response.data.data,
        //   selected: true,
        // };

        // setFamilyDatacart((prevFamilyDatacart) => {
        //   const copy = JSON.parse(JSON.stringify(prevFamilyDatacart));
        //   const addedfamily = [...copy, newFamilyMember];
        //   return addedfamily;
        // });

        // setFamilyCheck((prev) => {
        //   const copy = prev;
        //   copy.push(response.data.data.id);
        //   return [...copy];
        // });

        setDontKnow((prev) => prev + 1);
        setFull_name("");
        setSelectedDate(null);
        setRelationId("");
        setRasiId("");
        setGotram("");
        setNakshatram("");
        setNotes("");
        handleCloseAddAddressModal();
      } else {
        console.error("Error saving family data:", response.data.message);
      }
    } catch (error) {
      console.error("Error saving family data:", error);
    }
  };
  const handleSaveProfile = async () => {
    if (!validateForm()) {
      toast.error("Please fill in all required fields");
      return;
    }

    const familyData = {
      full_name,
      dob: selectedDate,
      relation_id: relationId,
      rasi_id: rasiId,
      gothram: gotram,
      nakshatram,
      description: notes,
      family_type: familyType,
    };

    try {
      const response = await axios.post(`${api}/api/myfamily`, familyData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      window.fbq("track", "InputData");
      if (response.data.success === 1) {
        const newFamilyMember = {
          ...response.data.data,
          selected: true,
        };

        setFamilyDatacart((prevFamilyDatacart) => {
          const copy = JSON.parse(JSON.stringify(prevFamilyDatacart));
          const addedfamily = [...copy, newFamilyMember];
          return addedfamily;
        });

        setFamilyCheck((prev) => {
          const copy = prev;
          copy.push(response.data.data.id);
          return [...copy];
        });

        setDontKnow((prev) => prev + 1);
        setFull_name("");
        setSelectedDate(null);
        setRelationId("");
        setRasiId("");
        setGotram("");
        setNakshatram("");
        setNotes("");
        handleCloseAddAddressModal();
      } else {
        console.error("Error saving family data:", response.data.message);
      }
    } catch (error) {
      console.error("Error saving family data:", error);
    }
  };

  useEffect(() => {
    fetchRasiData();
    fetchrelationData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [familyEditedRefresh]);

  useEffect(() => {
    if (profiledata) {
      setFirstName(profiledata.fname || "");

      setEmail(profiledata.email || "");

      setPhone(profiledata.mobile_number || "");
    }
  }, [profiledata]);

  useEffect(() => {
    fetchProfileData();
  }, [triggerRefresh]);

  const [userId, setUserId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState(null);
  const signin1 = () => {
    axios
      .post(`https://api-backend.devasmriti.com/api/user/login`, {
        mobile_number: phone,
      })

      .then(({ data }) => {
        if (data?.success) {
          setShow(true);
          setUserId(data?.data?.user_id);
          const tempOrderId = JSON.parse(data?.otpResponse?.data)?.orderId;
          console.log(tempOrderId, "kjwbkjdgbwkjegkwe");
          setOrderId(tempOrderId);
        }
      });
  };

  const verifyOtp = async () => {
    const { data, status } = await noauthinstance.post(
      "api/user/login_with_otp",
      {
        mobile_number: phone,
        user_id: userId,
        otp: otp,
        orderId: orderId,
      }
    );

    if (data.success === 1) {
      setIsLogin(true);
      // setOtpOpen(false);
      const token = data.data.token.access_token;
      localStorage.setItem("token", token);
      const user_id = data.data.user.id;
      localStorage.setItem("user_id", user_id);
      // console.log(token)
      setTriggerRefresh((prev) => prev + 1);
      setFamilyEditedRefresh(1);
    }
  };

  return (
    <div className="check-out-panel">
      <div
        className="chech-box-panel-main chech-box-panel-main-2"
        style={{ borderTop: "unset" }}
      >
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#FF5B00", fontSize: "30px" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="add-family-content">
              <h5>User Details</h5>
              <div className="user-details">
                <span>{profiledata ? profiledata.fname : ""}</span>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="_8dah">
            <span>{profileData ? profileData.mobile_number : ""}</span>
            <div className="chechout-checkbox-details">
              <div className="checkout-label-details checkout-label-details-2">
                <Button onClick={handleClickOpen}>
                  <span>
                    <AddIcon />
                  </span>{" "}
                  {token ? "Edit Profile" : "Verify Phone Number"}
                </Button>
              </div>
              <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogContent style={{ padding: "20px" }}>
                  <div>
                    <div>
                      <Typography variant="h6">Profile Details</Typography>
                      {token ? (
                        <div>
                          <div>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="myfamily-forms-1">
                                  <FormLabel>Phone Number</FormLabel>
                                  <TextField
                                    type="text"
                                    id="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  // disabled={!isEditing}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="myfamily-forms-1">
                                  <FormLabel>Full Name</FormLabel>
                                  {isEditing ? (
                                    <TextField
                                      type="text"
                                      id="fname"
                                      value={firstName}
                                      onChange={(e) =>
                                        setFirstName(e.target.value)
                                      }
                                    />
                                  ) : (
                                    <TextField
                                      type="text"
                                      id="fname"
                                      value={firstName}
                                    />
                                  )}
                                </div>
                              </Grid>

                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="myfamily-forms-1">
                                  <FormLabel>Email</FormLabel>
                                  {isEditing ? (
                                    <TextField
                                      type="text"
                                      id="email"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      error={emailError.email}
                                    />
                                  ) : (
                                    <TextField
                                      type="text"
                                      id="lname"
                                      value={email}
                                    />
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className="myfamily-forms-btn">
                            <Button onClick={saveProfileData}>Save</Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="myfamily-forms-1">
                            <FormLabel>Phone Number</FormLabel>
                            <TextField
                              type="text"
                              id="phone"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              disabled={show}
                            />
                            {show && (
                              <FormLabel style={{ paddingTop: "10px" }}>
                                Enter OTP
                              </FormLabel>
                            )}
                            {show && (
                              <TextField
                                type="text"
                                id="otp"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                              // disabled={show}
                              />
                            )}
                            {!show ? (
                              <div
                                className="myfamily-forms-btn"
                                style={{ marginTop: "5px" }}
                              >
                                <Button onClick={signin1}>
                                  Verify Phone Number
                                </Button>
                              </div>
                            ) : (
                              <div
                                className="myfamily-forms-btn"
                                style={{ marginTop: "5px" }}
                              >
                                <Button onClick={verifyOtp}>Verify OTP</Button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </AccordionDetails>
        </Accordion>
        <Typography className="menu-icon"></Typography>
      </div>
      {/* {token && !customPrice && */}
      {!customPrice && !individual && 
        <div>

          {uniqueFamilyTypes?.kartha && uniqueFamilyTypes?.ancestors ? (
            <>
              <div className="chech-box-panel-main">
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon sx={{ color: "#FF5B00", fontSize: "30px" }} />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="add-family-content">
                      {/* <h5>
                    Add{" "}
                    {uniqueFamilyTypes?.ancestors ? "Ancestor" : sevafamilytype}{" "}
                    Details
                  </h5> */}
                      ewfwe
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {dontKnow &&
                      Array.isArray(familyDataCart) &&
                      familyDataCart.length > 0 ? (
                      familyDataCart
                        .filter(
                          (familyMember) => familyMember.family_type === "ancestors"
                        )
                        .map((familyMember) => {
                          const pencilIconClass = familyMember?.selected
                            ? "pencil-icon pencil-icon-selected"
                            : "pencil-icon pencil-icon-default";
                          return (
                            <Typography className="menu-icon" key={familyMember.id}>
                              <div className="chechout-checkbox-details">
                                <div className="checkout-label-details">
                                  <Checkbox
                                    checked={familyMember.selected}
                                    onChange={() => handleChange(familyMember)}
                                    sx={{
                                      color: familyMember.selected
                                        ? "#ff5b00"
                                        : "#988E8E!important",
                                      "&.Mui-checked": {
                                        color: "#ff5b00",
                                      },
                                    }}
                                  />
                                  <p>
                                    <span>{familyMember.full_name}</span>{" "}
                                    <span>{familyMember.family_type}</span>{" "}
                                    {familyMember.relation &&
                                      `- ${familyMember.relation.name}`}
                                  </p>
                                </div>
                                <div className="chechout-checkbox-details-btn">
                                  <div className="edit-btn">
                                    {isMobile ? (
                                      <img
                                        src={PencilIcon}
                                        className={pencilIconClass}
                                        variant="outlined"
                                        onClick={() =>
                                          editFamilyHandler(familyMember)
                                        }
                                        sx={{
                                          color: familyMember.selected
                                            ? undefined
                                            : "#ff5b00",
                                          cursor: "pointer",
                                          fill: "none",
                                          stroke: familyMember.selected
                                            ? "#676767"
                                            : "#FF5B00",
                                        }}
                                      />
                                    ) : (
                                      <Button
                                        onClick={() =>
                                          editFamilyHandler(familyMember)
                                        }
                                        variant="outlined"
                                        sx={{
                                          color: familyMember.selected
                                            ? "#676767!important"
                                            : "#ff5b00!important",
                                          borderColor: familyMember.selected
                                            ? "#676767!important"
                                            : "#ff5b00!important",
                                          cursor: "pointer",
                                          filter: familyMember.selected
                                            ? "grayscale(100%)"
                                            : "",
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          );
                        })
                    ) : (
                      <Typography>No data available</Typography>
                    )}
                    <div className="add-new-address app-new-address-2">
                      {/* {uniqueFamilyTypes?.ancestors && ( */}
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          marginTop: "30px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#676767",
                            fontSize: "13px",
                            fontWeight: "500",
                            display: {
                              // xs: "none", 
                              sm: "block"
                            },
                          }}
                          variant="body2"
                          gutterBottom
                        >
                          Add more family member
                        </Typography>
                        <Button
                          disableRipple
                          disableElevation
                          onClick={() => handleFamilyType("ancestors")}
                          sx={{
                            width: "140px",
                            borderRadius: {
                              xs: "8px!important",
                              sm: "40.87px!important",
                            },
                          }}
                        >
                          <span>
                            <AddIcon />
                          </span>
                          Add {uniqueFamilyTypes?.ancestors && "Ancestor"}
                        </Button>
                      </Box>
                      {/* )} */}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div className="chech-box-panel-main">
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon sx={{ color: "#FF5B00", fontSize: "30px" }} />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="add-family-content">
                      {/* <h5>
                    Add {uniqueFamilyTypes?.kartha ? "Kartha" : sevafamilytype}{" "}
                    Details
                  </h5> */}
                      {/* ewfwefvwev */}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {dontKnow &&
                      Array.isArray(familyDataCart) &&
                      familyDataCart.length > 0 ? (
                      familyDataCart
                        .filter(
                          (familyMember) => familyMember.family_type === "kartha"
                        )
                        .map((familyMember) => {
                          const pencilIconClass = familyMember?.selected
                            ? "pencil-icon pencil-icon-selected"
                            : "pencil-icon pencil-icon-default";
                          return (
                            <Typography className="menu-icon" key={familyMember.id}>
                              <div className="chechout-checkbox-details">
                                <div className="checkout-label-details">
                                  <Checkbox
                                    checked={familyMember.selected}
                                    onChange={() => handleChange(familyMember)}
                                    sx={{
                                      color: familyMember.selected
                                        ? "#ff5b00"
                                        : "#988E8E!important",
                                      "&.Mui-checked": {
                                        color: "#ff5b00",
                                      },
                                    }}
                                  />
                                  <p>
                                    <span>{familyMember.full_name}</span>{" "}
                                    <span>{familyMember.family_type}</span>{" "}
                                    {familyMember.relation &&
                                      `- ${familyMember.relation.name}`}
                                  </p>
                                </div>
                                <div className="chechout-checkbox-details-btn">
                                  <div className="edit-btn">
                                    {isMobile ? (
                                      <img
                                        src={PencilIcon}
                                        className={pencilIconClass}
                                        variant="outlined"
                                        onClick={() =>
                                          editFamilyHandler(familyMember)
                                        }
                                        sx={{
                                          color: familyMember.selected
                                            ? undefined
                                            : "#ff5b00",
                                          cursor: "pointer",
                                          fill: "none",
                                          stroke: familyMember.selected
                                            ? "#676767"
                                            : "#FF5B00",
                                        }}
                                      />
                                    ) : (
                                      <Button
                                        onClick={() =>
                                          editFamilyHandler(familyMember)
                                        }
                                        variant="outlined"
                                        sx={{
                                          color: familyMember.selected
                                            ? "#676767!important"
                                            : "#ff5b00!important",
                                          borderColor: familyMember.selected
                                            ? "#676767!important"
                                            : "#ff5b00!important",
                                          cursor: "pointer",
                                          filter: familyMember.selected
                                            ? "grayscale(100%)"
                                            : "",
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          );
                        })
                    ) : (
                      <Typography>No data available</Typography>
                    )}

                    <div className="add-new-address app-new-address-2">
                      {uniqueFamilyTypes?.kartha && (
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            marginTop: "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#676767",
                              fontSize: "13px",
                              fontWeight: "500",
                              display: {
                                // xs: "none", 
                                sm: "block"
                              },
                            }}
                            variant="body2"
                            gutterBottom
                          >
                            Add more family member
                          </Typography>
                          <Button
                            disableRipple
                            disableElevation
                            onClick={() => handleFamilyType("kartha")}
                            sx={{
                              width: "140px",
                              borderRadius: {
                                xs: "8px!important",
                                sm: "40.87px!important",
                              },
                            }}
                          >
                            <span>
                              <AddIcon />
                            </span>
                            Add {uniqueFamilyTypes?.kartha && "Kartha"}
                          </Button>
                        </Box>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </>
          ) : (
            <div className="chech-box-panel-main">
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon sx={{ color: "#FF5B00", fontSize: "30px" }} />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="add-family-content">
                    <h5>
                      Add {uniqueFamilyTypes?.self ? "Family" : sevafamilytype}{" "}
                      Details
                    </h5>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {/* {dontKnow &&
                    Array.isArray(familyDataCart) &&
                    familyDataCart.length > 0 ? (
                    familyDataCart
                      .filter((familyMember) => {
                        if (sevafamilytype === null || sevafamilytype === "") {
                          return true;
                        }
                        return sevafamilytype == familyMember.family_type;
                      }) */}
                  {familyDataCart.length > 0 ? familyDataCart.map((familyMember) => {
                    const pencilIconClass = familyMember?.selected
                      ? "pencil-icon pencil-icon-selected"
                      : "pencil-icon pencil-icon-default";
                    return (
                      <Typography className="menu-icon" key={familyMember.id}>
                        <div className="chechout-checkbox-details">
                          <div className="checkout-label-details">
                            <Checkbox
                              checked={familyMember.selected}
                              onChange={() => handleChange(familyMember)}
                              sx={{
                                color: familyMember.selected
                                  ? "#ff5b00"
                                  : "#988E8E!important",
                                "&.Mui-checked": {
                                  color: "#ff5b00",
                                },
                              }}
                            />
                            <p>
                              <span>{familyMember.full_name}</span>{" "}
                              <span>{familyMember.family_type}</span>{" "}
                              {familyMember.relation &&
                                `- ${familyMember.relation.name}`}
                            </p>
                          </div>
                          <div className="chechout-checkbox-details-btn">
                            <div className="edit-btn">
                              {isMobile ? (
                                <img
                                  src={PencilIcon}
                                  className={pencilIconClass}
                                  variant="outlined"
                                  onClick={() =>
                                    editFamilyHandler(familyMember)
                                  }
                                  sx={{
                                    color: familyMember.selected
                                      ? undefined
                                      : "#ff5b00",
                                    cursor: "pointer",
                                    fill: "none",
                                    stroke: familyMember.selected
                                      ? "#676767"
                                      : "#FF5B00",
                                  }}
                                />
                              ) : (
                                <Button
                                  onClick={() =>
                                    editFamilyHandler(familyMember)
                                  }
                                  variant="outlined"
                                  sx={{
                                    color: familyMember.selected
                                      ? "#676767!important"
                                      : "#ff5b00!important",
                                    borderColor: familyMember.selected
                                      ? "#676767!important"
                                      : "#ff5b00!important",
                                    cursor: "pointer",
                                    filter: familyMember.selected
                                      ? "grayscale(100%)"
                                      : "",
                                  }}
                                >
                                  Edit
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </Typography>
                    );
                  })
                    : (
                      <Typography>No data available</Typography>
                    )}




                  <div className="add-new-address app-new-address-2">
                    {/* {uniqueFamilyTypes?.self && ( */}
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        marginTop: "30px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#676767",
                          fontSize: "13px",
                          fontWeight: "500",
                          display: {
                            // xs: "none", 
                            sm: "block"
                          },
                        }}
                        variant="body2"
                        gutterBottom
                      >
                        Add more family member
                      </Typography>
                      <Button
                        disableRipple
                        disableElevation
                        onClick={() => handleFamilyType("self")}
                        sx={{
                          width: "140px",
                          borderRadius: {
                            xs: "8px!important",
                            sm: "40.87px!important",
                          },
                        }}
                      >
                        <span>
                          <AddIcon />
                        </span>
                        Add {sevafamilytype == "self" ? "Family" : sevafamilytype}
                      </Button>
                    </Box>
                    {/* )} */}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>}

      <EditFamilyDetails
        open={editFamilyModal}
        onClose={closeEditFamilyModal}
        data={familyMemberData}
        setFamilyEditedRefresh={setFamilyEditedRefresh}
      />

      {/* <div className="add-new-address app-new-address-2">
        {sevafamilytype && sevafamilytype !== "kartha_ancestors" && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              sx={{
                color: "#676767",
                fontSize: "13px",
                fontWeight: "500",
                display: { xs: "none", sm: "block" },
              }}
              variant="body2"
              gutterBottom
            >
              Add more family member
            </Typography>
            <Button
              disableRipple
              disableElevation
              onClick={() => setIsAddAddressModalOpen(true)}
              sx={{
                width: "140px",
              }}
            >
              <span>
                <AddIcon />
              </span>
              Add {sevafamilytype == "self" ? "Family" : sevafamilytype}
            </Button>
          </Box>
        )}
      </div> */}

      {/* <div className="chech-box-panel-main">
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#FF5B00", fontSize: "30px" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="add-family-content">
              <h5>
                Add {uniqueFamilyTypes?.ancestors ? "Ancestor" : sevafamilytype}{" "}
                Details
              </h5>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {dontKnow &&
            Array.isArray(familyDataCart) &&
            familyDataCart.length > 0 ? (
              familyDataCart
                .filter(
                  (familyMember) => familyMember.family_type === "ancestors"
                )
                .map((familyMember) => (
                  <Typography className="menu-icon" key={familyMember.id}>
                    <div className="chechout-checkbox-details">
                      <div className="checkout-label-details">
                        <Checkbox
                          checked={familyMember.selected}
                          onChange={() => handleChange(familyMember)}
                          sx={{
                            color:
                                familyMember.selected
                                ? "#ff5b00"
                                : "#988E8E!important",
                            "&.Mui-checked": {
                              color: "#ff5b00",
                            },
                          }}
                        />
                        <p>
                          <span>{familyMember.full_name}</span>{" "}
                          <span>{familyMember.family_type}</span>{" "}
                          {familyMember.relation &&
                            `- ${familyMember.relation.name}`}
                        </p>
                      </div>
                      <div className="chechout-checkbox-details-btn">
                        <div className="edit-btn">
                          {isMobile ? (
                            <EditIcon
                              variant="outlined"
                              onClick={() => editFamilyHandler(familyMember)}
                              sx={{
                                color: familyMember.selected
                                  ? undefined
                                  : "#ff5b00",
                                cursor: "pointer",
                                fill: "none",
                                stroke: familyMember.selected
                                  ? "#676767"
                                  : "#FF5B00",
                              }}
                            />
                          ) : (
                            <Button
                              onClick={() => editFamilyHandler(familyMember)}
                              variant="outlined"
                              sx={{
                                color: familyMember.selected
                                  ? "#676767!important"
                                  : "#ff5b00!important",
                                borderColor: familyMember.selected
                                  ? "#676767!important"
                                  : "#ff5b00!important",
                                cursor: "pointer",
                                filter: familyMember.selected
                                  ? "grayscale(100%)"
                                  : "",
                              }}
                            >
                              Edit
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Typography>
                ))
            ) : (
              <Typography>No data available</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </div> */}

      <Dialog
        open={isAddAddressModalOpen}
        onClose={handleCloseAddAddressModal}
        fullWidth
        maxWidth="md"
      >
        <DialogContent style={{ padding: "20px" }}>
          <div>
            <div>

              <div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
                      <div className="myfamily-forms-1">
                        <FormLabel>
                          Full Name<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <TextField
                          id="fullName"
                          name="full_name"
                          value={full_name}
                          required
                          onChange={(e) => setFull_name(e.target.value)}
                          error={fieldErrors.full_name}
                        />
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="myfamily-forms-1">
                        <FormLabel>DOB</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            maxDate={dayjs()}
                            type="date"
                            id="datepicker"
                            value={selectedDate}
                            onChange={handleDateChange}
                            label=""
                            // maxDate={maxDate}
                            // // You can also provide a custom error message when the date is beyond the maxDate
                            // maxDateMessage="Date cannot be in the future"
                            renderInput={(params) => (
                              <TextField {...params} type="date" I />
                            )}
                            autoFocus={true}
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="myfamily-forms-2">
                        <FormLabel>Relation</FormLabel>
                        <Select
                          id="nakshatram"
                          name="Relation"
                          value={relationId}
                          onChange={(e) => setRelationId(e.target.value)}
                        >
                          <MenuItem disabled value="">
                            Select your Relation
                          </MenuItem>
                          {Array.isArray(relationOptions) &&
                            relationOptions.map((option, index) => {
                              return (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </div>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="myfamily-forms-1">
                        <FormLabel>Gotram</FormLabel>
                        <TextField
                          disabled={gotram === null}
                          id="gotram"
                          name="gotram"
                          value={gotram}
                          onChange={(e) => setGotram(e.target.value)}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: '40px'
                        }}
                      >
                        <Checkbox
                          // checked={
                          //   selectedAddressId === address.id
                          // }
                          onChange={() => {
                            if (gotram === "") {
                              setGotram(null)
                            } else {
                              setGotram("")
                            }
                          }
                          }
                          sx={{
                            padding: 0,
                            color:
                              true
                                ? "#ff5b00"
                                : "#988E8E!important",
                            "&.Mui-checked": {
                              color: "#ff5b00",
                            },
                          }}
                        />
                        <Typography
                          component="h3"
                          sx={{
                            marginLeft: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          I do not know my Gotra
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="myfamily-forms-2">
                        <FormLabel>Nakshatram</FormLabel>
                        <Select
                          id="nakshatram"
                          name="nakshatram"
                          value={nakshatram}
                          onChange={(e) => setNakshatram(e.target.value)}
                        >
                          <MenuItem disabled value="">
                            Select your Nakshatram
                          </MenuItem>
                          <MenuItem value={"Aswini/Aswathi Nakshatra"}>
                            Aswini/Aswathi Nakshatra
                          </MenuItem>
                          <MenuItem value={"Bharani/Bharani Nakshatra"}>
                            Bharani/Bharani Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Krithika/Karthigai/Kaarthika Nakshatra"}
                          >
                            Krithika/Karthigai/Kaarthika Nakshatra
                          </MenuItem>
                          <MenuItem value={"Rohini Nakshatra"}>
                            Rohini Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={
                              "Mrigashiras/Mrigasheersham/Makeeryam Nakshatra"
                            }
                          >
                            Mrigashiras/Mrigasheersham/Makeeryam Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Aardhra/Arudra/Thiruvaathirai Nakshatra"}
                          >
                            Aardhra/Arudra/Thiruvaathirai Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Punarvasu/Punarpoosam/Punartham Nakshatra"}
                          >
                            Punarvasu/Punarpoosam/Punartham Nakshatra
                          </MenuItem>
                          <MenuItem value={"Pushyami/Poosam/Pooyyam Nakshatra"}>
                            Pushyami/Poosam/Pooyyam Nakshatra
                          </MenuItem>
                          <MenuItem value={"Ashlesha/Aayilyam Nakshatra"}>
                            Ashlesha/Aayilyam Nakshatra
                          </MenuItem>
                          <MenuItem value={"Magha/Makha/Makham Nakshatra"}>
                            Magha/Makha/Makham Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"PoorvaPhalguni/Pubba/Pooram Nakshatra"}
                          >
                            PoorvaPhalguni/Pubba/Pooram Nakshatra
                          </MenuItem>
                          <MenuItem value={"Uthraphalguni/Uttaram Nakshatra"}>
                            Uthraphalguni/Uttaram Nakshatra
                          </MenuItem>
                          <MenuItem value={"Hastha/Hastham/Aastha Nakshatra"}>
                            Hastha/Hastham/Aastha Nakshatra
                          </MenuItem>
                          <MenuItem value={"Chitra/Chithirai Nakshatra"}>
                            Chitra/Chithirai Nakshatra
                          </MenuItem>
                          <MenuItem value={"Swathi Nakshatra"}>
                            Swathi Nakshatra
                          </MenuItem>
                          <MenuItem value={"Vishakha/Visaakam Nakshatra"}>
                            Vishakha/Visaakam Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Anuradha/Anusham/Anizham Nakshatra"}
                          >
                            Anuradha/Anusham/Anizham Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Jyeshta/Kettai/Thrikketta Nakshatra"}
                          >
                            Jyeshta/Kettai/Thrikketta Nakshatra
                          </MenuItem>
                          <MenuItem value={"Moola/Moolam Nakshatra"}>
                            Moola/Moolam Nakshatra
                          </MenuItem>
                          <MenuItem value={"Poorvashaada/Pooraadam Nakshatra"}>
                            Poorvashaada/Pooraadam Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"U.Shada/Uthrashaada/Uthiraadam Nakshatra"}
                          >
                            U.Shada/Uthrashaada/Uthiraadam Nakshatra
                          </MenuItem>
                          <MenuItem value={"Shravan/Thiruvonam Nakshatra"}>
                            Shravan/Thiruvonam Nakshatra
                          </MenuItem>
                          <MenuItem value={"Dhanishta/Avittam Nakshatra"}>
                            Dhanishta/Avittam Nakshatra Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Shathabhisha/Chathayam/Sadayam Nakshatra"}
                          >
                            Shathabhisha/Chathayam/Sadayam Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Poorvabhadra/Poorattadhi Nakshatra"}
                          >
                            Poorvabhadra/Poorattadhi Nakshatra
                          </MenuItem>
                          <MenuItem
                            value={"Uthrabhadra/Uthirattathi Nakshatra"}
                          >
                            Uthrabhadra/Uthirattathi Nakshatra
                          </MenuItem>
                          <MenuItem value={"Revathi Nakshatra"}>
                            Revathi Nakshatra
                          </MenuItem>
                          <MenuItem value={"Not Sure "}>Not Sure</MenuItem>
                        </Select>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="myfamily-forms-2">
                        <FormLabel>Rasi</FormLabel>
                        <Select
                          id="nakshatram"
                          name="nakshatram"
                          value={rasiId}
                          onChange={(e) => setRasiId(e.target.value)}
                        >
                          <MenuItem disabled value="">
                            Select your Rasi
                          </MenuItem>
                          {Array.isArray(rasiOptions) &&
                            rasiOptions.map((option, index) => {
                              return (
                                <MenuItem key={index} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                      <div className="myfamily-forms-1">
                        <FormLabel>Notes</FormLabel>
                        <TextField
                          id="notes"
                          name="notes"
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                          placeholder="Write something which will reflect in Profile Screen"
                          multiline
                          rows={5}
                          maxRows={5}
                        />
                      </div>
                    </Grid> */}
                  </Grid>
                </div>
                <div className="myfamily-forms-btn">

                  <Button onClick={familyMemberData ? handleEditProfile : handleSaveProfile}>Save Profile</Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <ToastContainer />
    </div>
  );
}

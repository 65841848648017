import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState, useEffect } from "react";
import instance from "../../utils/Api";
import { useParams } from "react-router-dom";
import api from "../../config/backend";
import axios from "axios";
import { useCart } from "../../pages/CartContext";
import { IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomExpandMoreIcon from "./CustomExpandMoreIcon";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      borderColor: "#C9C9C9",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      boxShadow: "0px 4px 15px 0px #0000000D",
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#EAEAEA",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#ff5b00",
        borderRadius: "8px",
        transform: "translateX(2px)",
        height: "4px",
      },
    },
    sx: {
      border: "1px solid #C9C9C9",
      marginTop: "5px",
      borderRadius: "12px",
      fontWeight: "700",
      "& .MuiMenuItem-root": {
        borderColor: "#C9C9C9",
        "&:hover": {
          backgroundColor: "#FFEADE",
        },
      },
      "& .MuiList-root": {
        padding: "0",
        marginRight: "3px",
        maxHeight: ITEM_HEIGHT * 4.5,
        overflowY: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          width: "6px",
          marginRight: "5px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#EAEAEA",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#ff5b00",
          borderRadius: "8px",
          height: "4px",
        },
      },
      "& .MuiButtonBase-root": {
        padding: "16px 14px",
        backgroundColor: "white!important",
        color: "unset",
      },
      "& .MuiButtonBase-root:hover": {
        backgroundColor: "#FFEADE!important",
      },
      "@media (min-width: 320px) and (max-width: 767px)": {
        left: "37px!important",
        width: "100px",
      },
    },
  },
};

function getStyles(name, selectedPrice, theme) {
  return {
    fontWeight:
      selectedPrice === name
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
  };
}

export default function MultipleSelectPlaceholder({
  onPriceChange,
  isIndia,
  productDetails,
}) {
  const theme = useTheme();
  const [selectedPriceId, setSelectedPriceId] = useState("");
  const { productId } = useParams();

  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");
  const { setSelectedPriceId: setCartPriceId } = useCart();
  const [selectedPriceTitle, setSelectedPriceTitle] = useState("");

  useEffect(() => {
    let optionId;

    if (productDetails.slug === productId) {
      optionId = productDetails.id;

      const fetchSellingPrice = async () => {
        try {
          const response = await axios.get(
            `${api}/api/seva_options/${optionId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                paginate: "0",
              },
            }
          );

          const responseData = response.data.data.data;

          if (responseData && responseData.length > 0) {
            setData(responseData);

            const defaultPrice = responseData.find((item) => item.is_default);
            if (defaultPrice) {
              setSelectedPriceId(defaultPrice.id);
              setSelectedPriceTitle(
                `${defaultPrice.title} - ${isIndia ? "Rs." : "$"} ${defaultPrice.selling_price}`
              );
            } else {
              console.error(
                "Default price not found in the response:",
                responseData
              );
            }
          } else {
            console.error("Empty response data:", response.data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchSellingPrice();
    } else {
      console.error("Product not found");
    }
  }, [productId, productDetails.slug, productDetails.id]);

  const handleChange = (event) => {
    const newSelectedPriceId = event.target.value;
    setSelectedPriceId(newSelectedPriceId);

    const selectedItem = data.find((item) => item.id === newSelectedPriceId);

    localStorage.setItem("newSelectedPriceId", newSelectedPriceId);
    setCartPriceId(newSelectedPriceId);

    setSelectedPriceTitle(
      selectedItem
        ? `${selectedItem.title} - ${isIndia ? "Rs." : "$"} ${selectedItem.selling_price}`
        : ""
    );

    onPriceChange(selectedItem);
  };

  function formatPriceTitle(title, price) {
    return (
      <span>
        {title.slice(0, 35)}
        {"..."} - <span style={{ color: "#ff5b00" }}>{price}</span>
      </span>
    );
  }

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
        <Select
          displayEmpty
          value={selectedPriceId}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (!selected) {
              return selectedPriceTitle;
            }
            const selectedItem = data.find((item) => item.id === selected);
            return formatPriceTitle(
              selectedItem.title,
              selectedItem.selling_price
            );
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={CustomExpandMoreIcon}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#C9C9C9",
              },
            },
            "& .MuiSelect-select": {
              padding: "16px!important",
              borderColor: "#C9C9C9",
            },
          }}
        >
          {data
            .filter((item) => item.is_active)
            .map((item) => (
              <MenuItem
                key={item.id}
                value={item.id}
                style={{
                  ...getStyles(item.id, selectedPriceId, theme),
                  whiteSpace: "nowrap",
                }}
              >
                <>
                  {`${item.title.slice(0, 40)}... -`}{" "}
                  <span style={{ color: "#ff5b00" }}>
                    {isIndia ? "Rs." : "$"}
                    {item.selling_price}
                  </span>
                </>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}

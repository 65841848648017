import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "@mui/material";
import instance, { noauthinstance } from "../../utils/Api";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function SevasFaq() {
  const [data, setData] = useState([
    {
      title: "What is the replacement or refund process?",
      sub_title:
        "To ensure a smooth replacement process, please submit your request within 48 hours of receiving your delivery by emailing us at shop@devasmriti.com. In your email, kindly include your customer order number, registered mobile number with Devasmriti, the specific product you require a replacement for, and a clear description of the damage. Additionally, please attach photos of both the packaging and the damaged product, ensuring good lighting and close-up shots for better evaluation. Our team will get in touch with you . Replacement or refund is applicable on selective items. ",
    },
    {
      title: "How do i return the product ?",
      sub_title:
        "We handle returns efficiently! Once your return request is approved, we'll arrange a pickup for you using our trusted courier partner. This ensures a smooth and convenient return process.",
    },
    {
      title: "Where do I mail for any query?",
      sub_title:
        "Kindly Mail us as shop@devasmriti.com or reach out to our customer support on whats app +9697972999",
    },
    {
      title: "When is the Product shipped ?",
      sub_title:
        "Once the order is placed then the product will be shipped in 2-4 working days . If any unexpected delays it will be communicated to customer",
    },
  ]);
  const [error, setError] = useState(null);
  const { productId } = useParams();

  // const fetchFAQ = async () => {
  //   try {
  //     const response = await noauthinstance.get(`api/seva/faqs/${productId}`);
  //     setData(response.data.data.data);
  //   } catch (err) {
  //     setError(err);
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   fetchFAQ();
  // }, []);

  return (
    <div className="accordion">
      {data.length === 0 ? (
        <Typography variant="h5" className="main-content">
          There are no FAQs for this seva
        </Typography>
      ) : (
        data.map((item, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography className="main-content">{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="mini-content">{item.sub_title}</Typography>
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </div>
  );
}

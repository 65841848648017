import React from "react";
import { Button, Container, useMediaQuery } from "@mui/material";
import Header from "../components/common/Header";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import individual from "../images/individual/individual-banner.svg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import bookingcart from "../images/individual/add-to-cart.svg";
import CustomTabPanel2 from "../components/common/CustomTabPanel2";
import { Link, useNavigate, useParams } from "react-router-dom";
import footerbg from "../images/footer-bg.svg";
import footerlogo from "../images/devasmriti-logo.svg";
import Swipper from "../components/common/Swipper";
import BasicAccordion from "../components/common/Accordion";
import MultipleSelectPlaceholder from "../components/common/MultipleSelectPlaceholder";
import PlaygroundSpeedDial from "../components/common/PlaygroundSpeedDial";
import { useState } from "react";
import { useEffect } from "react";
import instance from "../utils/Api";
import { useAuth } from "../utils/Auth";
import LoginDailog from "../components/LoginDailog";
import Footer from "./Footer";
import SevasFaq from "../components/common/SevasFaq";
import Header2 from "../components/common/Header2";
import { useCart } from "../pages/CartContext";
import api from "../config/backend";
import axios from "axios";
import StarBackground from "../components/common/StarBackground";
import CustomizedDialogs from "../components/CustomizedDialogs";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import CustomizedDialogsEvent from "../components/CustomizedDialogsEvent";

function Individual() {
  const shareUrl = window.location.href;
  const title = "Book This Seva on Devasmriti";
  const token = localStorage.getItem("token");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const navigate = useNavigate();
  const [showSocialIcons, setShowSocialIcons] = useState(false);
  const { cartTotal, updateCartTotal, selectedPriceId, setSelectedPriceId } =
    useCart();
  console.log("cartTotal: ", cartTotal);
  console.log("updateCartTotal: ", updateCartTotal);
  console.log("selectedPriceId: ", selectedPriceId);
  const { productId } = useParams();
  console.log(productId, "productId---");
  const [age, setAge] = useState("");
  const [price, setPrice] = useState([]);
  const [selectedSellingPrice, setSelectedSellingPrice] = useState(null);
  const [selectedSellingPriceTitle, setSelectedSellingPriceTitle] =
    useState("");
  const [error, setError] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [triggerRefresh, setTriggerRefresh] = React.useState(0);
  const [selectedSevaPrice, setSelectedSevaPrice] = useState(undefined);
  const [isIndia, setIsIndia] = useState(true);
  const [numStars, setNumStars] = useState(5);
  const [starRate, setStarRate] = useState(4.5);
  const isMobile = useMediaQuery("(max-width: 767px)");

  // console.log(price)
  // console.log(productDetails)

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const ns = "app-page-home";
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  // const isLoggedIn = localStorage.getItem("token");
  const isLoggedIn = true;

  const user = useAuth();
  // useEffect(() => {
  //   setIsLoggedIn(user.isLogin);
  // }, []);

  const fetchSevasData = async () => {
    try {
      const response = await axios.get(`${api}/api/sevas/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });
      setIsIndia(response?.data?.is_india === 1);
      setProductDetails(response?.data?.data);
      console.log("setProductDetails individual", response?.data?.data);
      const sevaPrices = response?.data?.data?.seva_prices;

      if (sevaPrices && sevaPrices.length > 0) {
        const defaultPrice = sevaPrices.find((item) => item.is_default);

        // console.log({ defaultPrice })

        setSelectedSevaPrice(defaultPrice);
        setPrice(sevaPrices);
        setSelectedSellingPrice(defaultPrice.selling_price);
        setSelectedSellingPriceTitle(defaultPrice.title);
        // setTriggerRefresh(prev => prev + 1)
        // console.log(selectedSellingPrice)
      } else {
        console.log(
          "No seva prices found or the data structure is unexpected."
        );
      }
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    if (productId) {
      fetchSevasData();
    }
  }, [productId]);

  if (!productId) {
    return <div>...Loading</div>;
  }

  if (!productDetails) {
    return <div>No data available for productId: {productId}</div>;
  }

  const user_id = localStorage.getItem("user_id");

  const handleAddToCart = async () => {
    if (isLoggedIn) {
      const cartData = {
        cart: {
          user_id: user_id,
          seva_id: productDetails.id,
          seva_price_id: selectedPriceId || productDetails.seva_prices[0].id,
          is_prasadam_available: 0,
          qty: 1,
        },
      };
      console.log("cc1:", selectedPriceId);
      console.log("ccccc", cartData);

      try {
        const response = await axios.post(
          `${api}/api/cart`,
          { cart: cartData },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        // console.log({ t: response.data })
        if (response.data.success === 1) {
          setTriggerRefresh((prev) => prev + 1);
          if (response.data.data.length) {
            updateCartTotal(cartTotal + 1);
          }
        } else {
          console.log("errorrrr");
        }
      } catch (error) {
        console.error("Error adding items to the cart:", error);
      }
    }
  };

  const handlePriceChange = (price) => {
    setSelectedSevaPrice(price);
    setSelectedSellingPrice(price.selling_price);
    setSelectedSellingPriceTitle(price.title);
  };
  console.log(productDetails);
  const handleButtonClick = () => {
    window.fbq("track", "AddToCart");
    window.fbq("track", "InitiateCheckout");

    if(token){
      navigate(`/checkout/${productDetails?.slug ? productDetails?.slug : productDetails?.id}/seva/${selectedSevaPrice && selectedSevaPrice?.id}?individual=true`)
    }else{
      localStorage.setItem('savedSevaSelection', `/checkout/${productDetails?.slug ? productDetails?.slug : productDetails?.id}/seva/${selectedSevaPrice && selectedSevaPrice?.id}?individual=true`)
      handleDialogOpen()
    }
    


  };
  const handleDialogOpen = () => {
    window.fbq("track", "AddToCart");
    window.fbq("track", "InitiateCheckout");
    openDialog();
  };
  return (
    <>
      <div className={`${ns}`}>
        <div className={`${ns}__header`}>
          <Header />
        </div>
        <div className={`${ns}__banner`} id="IndividualBanner">
          <Container>
            <div className="thila-homam">
              {/* <h3>Seva Card</h3> */}
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={7}
                  xl={7}
                  sx={{ paddingLeft: "unset" }}
                >
                  <div className="individual-image">
                    {productDetails?.banner_image_id ? (
                      <img
                        src={`${productDetails?.banner_image_id?.domain}${productDetails?.banner_image_id?.url}`}
                        alt={productDetails?.feature_image_id?.name}
                      />
                    ) : (
                      <Skeleton className="individual-image" />
                    )}
                    {/* <img src={`${seva.banner_image_id.domain}${seva.banner_image_id.url}`} /> */}
                    {/* <div className="speeddeal">
                      <PlaygroundSpeedDial />
                    </div> */}
                    {/* <div className="individual-image-small"></div> */}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={5} xl={5}>
                  <div className="individual-details">
                    <div className="individual-content">
                      <h2>
                        {productDetails?.title ? (
                          productDetails?.title
                        ) : (
                          <div>
                            <Skeleton width={400} height={10} />
                            <Skeleton width={400} height={10} />
                            <Skeleton width={200} height={10} />
                          </div>
                        )}
                      </h2>
                      <h5>
                        {productDetails?.expairy_date ? (
                          new Date(productDetails?.expairy_date).toDateString()
                        ) : (
                          <Skeleton width={200} height={10} />
                        )}
                      </h5>
                      <h6>
                        <span className="event">Event</span>{" "}
                        {productDetails?.event ? (
                          productDetails?.event
                        ) : (
                          <Skeleton width={200} height={10} />
                        )}
                      </h6>
                      <h6>
                        <span className="venue">Venue</span>{" "}
                        {productDetails?.location ? (
                          productDetails?.location
                        ) : (
                          <Skeleton width={200} height={10} />
                        )}
                      </h6>
                    </div>
                    {/* <div className="choose-seva-cost">
                    <h2>{isIndia ? '₹' : '$'}{selectedSellingPrice}</h2>
                  </div> */}
                    {productDetails?.seva_prices &&
                      productDetails?.seva_prices.length > 1 && (
                        <div className="choose-seva">
                          <div className="choose-seva-content ">
                            {/* <label>Choose a Seva</label> */}
                            <div className="custom-choose-seva">
                              <MultipleSelectPlaceholder
                                isIndia={isIndia}
                                onPriceChange={handlePriceChange}
                                productDetails={productDetails}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                    <StarBackground
                      numberOfStars={numStars}
                      rating={starRate}
                      sx={{ margin: "22px 0" }}
                    />

                    <div className="choose-seva-cost">
                      <h2>
                        {isIndia ? "₹" : "$"}
                        {selectedSellingPrice ? (
                          selectedSellingPrice
                        ) : (
                          <Skeleton
                            width={100}
                            height={20}
                            style={{ marginLeft: 10 }}
                          />
                        )}
                      </h2>
                    </div>

                    <div className="choose-seva-booking-cost">
                      {/* {isLoggedIn ? (

                      <Button onClick={addToCart} disableRipple disableElevation>
                        <span>
                          <img src={bookingcart} />
                        </span>
                        Add to Cart
                      </Button>

                    ) : (
                      <Button onClick={openDialog} disableRipple disableElevation>
                        <span>
                          <img src={bookingcart} />
                        </span>
                        Add to Cart
                      </Button>
                    )} */}

                      {isLoggedIn ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            // alignItems: "center",
                            gap: "14px",
                            marginTop: "20px",
                          }}
                        >
                          {/* <Button
                            onClick={() => handleAddToCart()}
                            disableRipple
                            disableElevation
                            sx={{
                              width: "50%",
                              borderRadius: "10px",
                              border:
                                // !selectedPriceId || selectedPriceId.length === 0
                                //   ? "1px solid lightgray!important"
                                // :
                                "1px solid #ff5b00",
                              // padding: "10px 20px",
                              color: "#ff5b00",
                              fontFamily: "Objectivity",
                              fontSize: "15px",
                              fontWeight: 500,
                              textTransform: "unset",
                              backgroundColor: "white",
                              whiteSpace: "nowrap",
                              "&:hover": {
                                backgroundColor: "white",
                                cursor: "pointer",
                              },
                              fontSize: {
                                xs: "13px",
                                sm: "14px",
                              },
                              "& span img": {
                                // filter:
                                //   !selectedPriceId ||
                                //   selectedPriceId.length === 0
                                //     ? "grayscale(100%) brightness(80%)"
                                //     : "none",
                              },
                            }}
                            // disabled={
                            //   !selectedPriceId || selectedPriceId.length === 0
                            // }
                          >
                            <span
                              style={{
                                display: "inline-block",
                                marginRight: "10px",
                                width: "20px",
                                height: "20px",
                              }}
                            >
                              <img
                                src={bookingcart}
                                alt="cart"
                                style={{ width: "100%", height: "100%" }}
                              />
                            </span>
                            Add to Cart
                          </Button> */}

                          <div
                            
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                              cursor: productDetails?.is_expaired
                                ? "not-allowed"
                                : "pointer",
                              width: "50%",
                            }}
                          >
                            <Button
                              disableRipple
                              disableElevation
                              onClick={() => handleButtonClick()}
                              className={`bookseva ${
                                productDetails?.is_expaired
                                  ? "disabled"
                                  : "highlight"
                              }`}
                              disabled={productDetails?.is_expaired}
                              sx={{
                                borderRadius: "10px",
                                // padding: "10px 20px",
                                color: productDetails?.is_expaired
                                  ? "white"
                                  : "white",
                                fontFamily: "Objectivity",
                                fontSize: "15px",
                                fontWeight: 500,
                                textTransform: "unset",
                                backgroundColor: "#ff5b00",
                                whiteSpace: "nowrap",
                                fontSize: {
                                  xs: "13px",
                                  sm: "14px",
                                },
                                "&:hover": {
                                  backgroundColor: "#ff7b33",
                                },
                              }}
                            >
                              {productDetails?.is_expaired
                                ? "Seva closed"
                                : "Buy Now"}

                              <div style={{ width: "20px", height: "20px" }}>
                                <img
                                  className="app-btn-img"
                                  src="/images/icons/arrow-right.svg"
                                  alt="arrow"
                                  style={{
                                    width: "100%",
                                    height: "100%!important",
                                  }}
                                />
                              </div>
                            </Button>
                          </div>
                          {/* {showSocialIcons ? (
                            <div
                              class="footer-social-icons"
                              style={{ paddingBottom: "3px" }}
                            >
                              <div>
                                <FacebookShareButton
                                  url={shareUrl}
                                  quote={title}
                                >
                                  <FacebookIcon size={32} round />
                                </FacebookShareButton>
                              </div>
                              <div>
                                <TwitterShareButton
                                  url={shareUrl}
                                  title={title}
                                >
                                  <TwitterIcon size={32} round />
                                </TwitterShareButton>
                              </div>
                              <div>
                                <WhatsappShareButton
                                  url={shareUrl}
                                  title={title}
                                  separator=":: "
                                >
                                  <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                              </div>
                              <div>
                                <EmailShareButton
                                  url={shareUrl}
                                  subject={title}
                                  body="Check out this link: "
                                >
                                  <EmailIcon size={32} round />
                                </EmailShareButton>
                              </div>
                              <div
                                onClick={(e) => setShowSocialIcons(false)}
                                style={{
                                  background: "#FF5B00",
                                  color: "white",
                                  fontSize: "16px",
                                  fontWeight: 700,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50px",
                                  width: "auto",
                                  padding: "0px 10px",
                                  marginTop: "9px",
                                  cursor: "pointer",
                                  height: "30px",
                                  width: "30px",
                                }}
                              >
                                x
                              </div>
                            
                            </div>
                          ) : (
                            <div
                              onClick={(e) => setShowSocialIcons(true)}
                              style={{
                                zIndex: 9999,
                                cursor: "pointer",
                                // background: "red",
                              }}
                            >
                              <img
                                src={
                                  require("../images/banner/share.svg").default
                                }
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  // zIndex: 9,
                                  cursor: "pointer",
                                  boxShadow:
                                    "0px 4px 30px 0px rgba(0, 0, 0, 0.1)",
                                }}
                              />
                            </div>
                          )} */}
                        </div>
                      ) : (
                        <Button
                          onClick={handleDialogOpen}
                          disableRipple
                          disableElevation
                          className="bookseva"
                          disabled={productDetails?.is_expaired}
                          style={{
                            color: productDetails?.is_expaired
                              ? "white"
                              : "white",
                          }}
                        >
                          <span style={{ marginRight: "8px" }}>
                            {" "}
                            {productDetails?.is_expaired
                              ? "Seva closed"
                              : "Book Now"}
                          </span>
                        </Button>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        {/* <CustomizedDialogs
          setTriggerRefresh={setTriggerRefresh}
          open={isDialogOpen}
          handleClose={closeDialog}
          productDetails={productDetails}
          selectedSevaPrice={selectedSevaPrice}
        /> */}
        <div className="champaign-tab">
          <Container>
            <CustomTabPanel2 productDetails={productDetails} />
          </Container>
        </div>
        {/* <div className="description-tabs-2">
          <Container>
            <div className="descripition-tabs-2-content">
              <h4>Description</h4>
              <div className="description-content">
                <p>
                 {productDetails.description}
                </p>
                <p>
                  The last day of Mahalayapakshas, Amavasya tithi is considered
                  even more Special to perform pitru karmas and attain
                  Punya.Thila homam means the homam which is done using Sesame
                  seeds or Til Seeds, it is performed to satisfy your ancestors
                  and get their blessings on you and your family.{" "}
                </p>
                <p>
                  Thila homam can be performed on every Amavasya. Performing
                  Thila Homam on Mahalayapaksha Amavasya can show the same
                  results of performing the homa on all amavasya through the
                  year. Performing Thila Homam on Mahalaya Amavasya will help
                  you to come out of Pitru Dosha and benefit you with positive
                  results and remove obstacles. Take part in Thila homam in
                  Satguru Charitable Trust through Devesmriti in Agni Linga
                  Kshetra Tiruvannamalai duringon October 14th (Amavasya).Note :{" "}
                </p>
                <p>
                  After completion of seva you will receive a Video recording
                  update.
                </p>
                <p>
                  <strong>
                    As it is related to Pitru doshas and Pitru karma prasad will
                    not be available.
                  </strong>
                </p>
              </div>
            </div>
            <div className="faq faq-2">
              <div className="faq-content">
                <h2>Frequently asked questions</h2>
              </div>
              <div className="faq-main-content">
                <SevasFaq />
              </div>
            </div>
            <div className="descripition-tabs-2-content">
              <h4>Additiobal Information</h4>
              <div className="description-content">
              <p>
                 {productDetails.description}
                </p>
                <p>
                  MahalayaPakshas falls in the month of Bhadrapada and lasts for
                  15 days. All 15 days are considered the most special days to
                  show your gratitude and appease your ancestors.
                </p>
                <p>
                  The last day of Mahalayapakshas, Amavasya tithi is considered
                  even more Special to perform pitru karmas and attain
                  Punya.Thila homam means the homam which is done using Sesame
                  seeds or Til Seeds, it is performed to satisfy your ancestors
                  and get their blessings on you and your family.{" "}
                </p>
                <p>
                  Thila homam can be performed on every Amavasya. Performing
                  Thila Homam on Mahalayapaksha Amavasya can show the same
                  results of performing the homa on all amavasya through the
                  year. Performing Thila Homam on Mahalaya Amavasya will help
                  you to come out of Pitru Dosha and benefit you with positive
                  results and remove obstacles. Take part in Thila homam in
                  Satguru Charitable Trust through Devesmriti in Agni Linga
                  Kshetra Tiruvannamalai duringon October 14th (Amavasya).Note :{" "}
                </p>
                <p>
                  After completion of seva you will receive a Video recording
                  update.
                </p>
                <p>
                  <strong>
                    As it is related to Pitru doshas and Pitru karma prasad will
                    not be available.
                  </strong>
                </p>
              </div>
            </div>
          </Container>
        </div> */}
        <div className="discover-more app-new-individual-suggested-puja app-new-individual-suggested-puja-2">
          <Container>
            <div className="discover-more-content">
              <div className="discover-more-buttons">
                <div className="">
                  <h2>Suggested Pujas</h2>
                </div>
              </div>
              <Swipper />
            </div>
          </Container>
        </div>
        <Footer />
      </div>

      <CustomizedDialogsEvent
            setTriggerRefresh={setTriggerRefresh}
            open={isDialogOpen}
            setOpen={setIsDialogOpen}
            handleClose={closeDialog}
            // seva={sevaForDialog}
            handleBookSeva={handleButtonClick}
            // setIsLogin={setIsLoggedIn}
          />
    </>
  );
}

export default Individual;
